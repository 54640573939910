import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sysCurrency',
})
export class SysCurrencyPipe implements PipeTransform {
  transform(value: number | string | null): string {
    return value ? `£ ${value}` : `£ 0`;
  }
}
