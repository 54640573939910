import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Config } from '../constants/constants';

export function descriptionValidator(
  maxLength: number = Config.maxLength2000,
  alongWithRequired: boolean = true,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value?.trim() || '';
    const allowedCharactersRegex = new RegExp(Config.allowedCharactersDescriptionRegex);
    const checkDescriptionLength = () => {
      return alongWithRequired ? value.length > 0 && value.length <= maxLength : value.length <= maxLength;
    };
    if (checkDescriptionLength() && allowedCharactersRegex.test(value)) {
      return null;
    } else if (!checkDescriptionLength()) {
      return !value.length ? { descriptionRequired: true } : { limitExceeds: true };
    } else if (!allowedCharactersRegex.test(value)) {
      return { invalidDescription: true };
    }
    return null;
  };
}
