<button type="button" class="close" (click)="closeModal.emit()" aria-label="Close">
  <svg-icon name="modal-close-icon" class="icon"></svg-icon>
</button>
<div class="modal-body">
  <div class="day-log">
    <p>{{ headerDate }}</p>
    <p>{{ currentTimeString }}</p>
  </div>
  <div class="log-title-box">
    <div class="search-input">
      <button class="search-btn">
        <svg-icon name="search-icon-big" class="icon"></svg-icon>
      </button>
      <input
        type="text"
        (ngModelChange)="this.searchFieldUpdate.next($event)"
        [(ngModel)]="automaticTimesheetSearch"
        placeholder="What are you working on?"
      />
      <button type="button" (click)="clearSearch()" class="close-btn">
        <svg-icon name="close-icon" class="icon"></svg-icon>
      </button>
    </div>
  </div>
  <div class="log-list" *ngIf="timesheetFound">
    <mat-accordion class="prevent-click" #accordion>
      <mat-expansion-panel
        hideToggle
        class="log-list-tab"
        *ngFor="let matterClient of matterClientListAutomaticTimesheet; let i = index"
        [expanded]="false"
        #expansion
      >
        <mat-expansion-panel-header #panelH (click)="panelH._toggle()">
          <div class="log-list-row">
            <div class="col-sm-12 col-md-5 client-name-block">
              <p>{{ matterClient.clientName }}</p>
              <p>{{ matterClient.matterName }}</p>
              <p>{{ matterClient.categoryName || '--' }}</p>
              <p
                *ngIf="matterClient.description"
                class="description-text description-with-title"
                [title]="matterClient.description"
              >
                {{ matterClient.description }}
              </p>
            </div>
            <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
              <div *ngIf="!matterClient.isActive">{{ matterClient.timerString }}</div>
              <div *ngIf="matterClient.isActive">{{ currentTimeString }}</div>
              <!-- timer start/stop button -->
              <app-timer-buttons
                class="timer-btn"
                [matterClient]="matterClient"
                (isLoading)="pageLoading($event)"
                [isDashboardStart]="false"
              ></app-timer-buttons>
              <div>
                <button
                  type="button"
                  class="btn hour-edit-btn"
                  [disabled]="matterClient.isActive || !matterClient.isEditEnabled"
                  (click)="editTimesheetinPopUp(matterClient.timesheetId, false, -1, false, false, false)"
                >
                  <svg-icon name="edit-icon-white" class="icon"></svg-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="listing-table" *ngIf="!timesheetFound">
    <p>Data not found</p>
  </div>
  <div class="log-title-box today-total-time-details">
    <p class="today-total-time-label">Today, Total Time (hh:mm:ss)</p>
    <p class="today-total-time">{{ totalTimeAllDayString }}</p>
  </div>
</div>
