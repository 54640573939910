<ng-container *ngIf="!isDashboardStart">
  <div *ngIf="!checkIsActive()" class="timer-btn timer-block authorize-click play-btn" [title]="constructTitle()">
    <button
      class="start-timer-btn matter-start-timer"
      type="button"
      (click)="startTimer(false)"
      [disabled]="checkDisableTimer()"
    >
      <svg-icon name="play-icon-green" class="icon"></svg-icon>
    </button>
  </div>

  <div *ngIf="checkIsActive()" class="timer-btn timer-block authorize-click play-btn">
    <button
      type="button"
      class="matter-start-timer"
      (click)="editTimesheetinPopUp(currentTimer?.timesheetId || 0, false, -1, true, false, true)"
      [title]="currentTimeString"
    >
      <svg-icon name="stop-icon" class="icon"></svg-icon>
    </button>
  </div>
</ng-container>
<div *ngIf="isDashboardStart">
  <div class="stop-timer-block d-flex header-timer-icon" *ngIf="!isTimerRunning">
    <svg-icon (click)="startTimer(true)" name="play-white-ico" class="icon header-timer"></svg-icon>
    <span *ngIf="!startEditingNewTime">{{ currentTimeString }}</span>
    <span *ngIf="startEditingNewTime">
      <input
        class="new-time-update"
        matInput
        type="text"
        [(ngModel)]="editedNewTimeString"
        name="time"
        (keyup)="inlineNewTimeKeyUp($event)"
        (click)="stopPropagationForInlineNewTime($event)"
      />
    </span>
    <svg-icon
      [name]="startEditingNewTime ? 'close-icon-grey' : 'edit-timer-icon'"
      class="icon header-timer"
      [ngClass]="{ 'close-edit-new-time': startEditingNewTime }"
      (click)="inlineEditTimer($event)"
    ></svg-icon>
  </div>
  <div class="start-timer-block header-timer-icon timer-start-btn" *ngIf="isTimerRunning">
    <svg-icon
      (click)="editTimesheetinPopUp(currentTimer?.timesheetId || 0, true, -1, false, false, true)"
      name="stop-icon"
      class="icon header-timer"
    ></svg-icon
    ><span class="current-time-string">{{ currentTimeString }}</span>
    <svg-icon name="edit-timer-icon" class="icon header-timer start-timer-block-edit"></svg-icon>
  </div>
</div>
