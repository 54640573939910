import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Config } from '../../constants/constants';
import { Roles, TimeSheetTimeEntryTypeIDs, TimerErrorCodes } from '../../enums/enums';
import { ValidationMessages } from '../../enums/messages';
import { AddEditManualTimeEntryRequest } from '../../interfaces/Admin/ManualRequest/AddEditManualTimeEntryRequest';
import { Category } from '../../interfaces/Admin/ManualRequest/AllCategoryResponse';
import {
  ClientList,
  MatterClientByTimeEntryTypeResponse,
  MatterList,
} from '../../interfaces/Admin/ManualRequest/MatterClientByTimeEntryTypeResponse';
import { MatterClientByTimeEntryTypeRequest } from '../../interfaces/Admin/ManualRequest/MatterClientByTimeEntryTypeResquest';
import { TimeEntryType } from '../../interfaces/Admin/ManualRequest/TimeEntryTypeResponse';
import { LogListResponse } from '../../interfaces/Admin/ManualRequest/TimesheetManualEntryLogListResponse';
import { MatterByClientIdRequest } from '../../interfaces/Admin/Matter/MatterByClientIdRequest';
import { MattersByClientId } from '../../interfaces/Admin/Matter/MattersByClientIdResponse';
import {
  HeadLinePracticeArea,
  HeadLinePracticeAreaResponse,
} from '../../interfaces/Admin/Role/HeadLinePracticeAreaNyRoleIdRespomse';
import { HeadlinePracticeAreaPhaseListResponse } from '../../interfaces/Admin/Timesheet/HeadlinePracticeAreaPhaseListResponse';
import { TimeRoundingRequest } from '../../interfaces/Admin/Timesheet/TimeRoundingRequest';
import { AuthService, LoginService, RoleService } from '../../services';
import { TimeLogService } from '../../services/time-log.service';
import { TimeRoundingUtilsService } from '../../services/time-rounding-utils.service';
import { TimesheetService } from '../../services/timesheet.service';
import { descriptionValidator } from '../../validators/description.validator';
import { timeSheetTimeValidator } from '../../validators/timesheet-time.validator';
@Component({
  selector: 'app-manual-request-modal',
  templateUrl: './manual-request-modal.component.html',
})
export class ManualRequestModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter<void>();
  @Output() isLoading = new EventEmitter<boolean>();

  @Input() nonBillableClientId: number;

  @ViewChild('picker') datePicker: MatDatepicker<Date>;

  logListDataSource = new MatTableDataSource<LogListResponse>([]);
  ifNoDataFound = false;
  selectedTimeEntryType = TimeSheetTimeEntryTypeIDs.Billable;

  manualRequestForm: UntypedFormGroup;
  timeEntryTypes: TimeEntryType[] = [];
  isManualTimeEntryDateValid = true;
  isPreviouslySelectedClientExistManualTimesheet = true;
  timeSheetTimeEntryTypeIDs = TimeSheetTimeEntryTypeIDs;

  previouslySelectedClientManualTimesheet: number | null;
  selectedClientManualEntry: number | null;
  previouslySelectedClientNameManualTimesheet: string;
  selectedClientNameManualTimesheet: string;
  previouslySelectedTimeEntryTypeManualTimesheet: number | null;
  selectedMatterManualEntry: number | null;
  isSelectedMatterManualEntryActive = false;
  selectedCategoryOfManualEntry: number | null;

  matterListOfManualEntry: MattersByClientId[] | MatterList[];

  lockedDateToBeDisplayed: string;
  isAdmin: boolean;
  currentUserId: number;
  clientListOfManualEntry: ClientList[];

  phaseByMatterId: string[] = [];

  selectedLawyerManualEntry: number | null;
  selectedHeadlinePracticeAreaEditTimesheetEntry: number | null;
  selectedPhaseEditTimesheetEntry: string | null;
  selectedHeadlinePracticeAreaManualEntry: number | null;
  selectedPhaseManualEntry: string | null;

  todayDate: Date = new Date();
  clientIDOfSelectedMatterManualEntry: number | null;

  lawyerListOfManualEntry: { id: number; lawyerName: string }[] = [];
  headLinePracticeAreaByMatterId: HeadLinePracticeArea[] = [];

  categoryListOfManualEntry: Category[];

  validationMessages = ValidationMessages;

  isEntryAdded = false;

  isRoundingApplicable = false;

  displayedColumns = [
    'clientName',
    'matterName',
    'headlinePracticeArea',
    'phase',
    'categoryName',
    'date',
    'duration',
    'description',
  ];

  constructor(
    private _timeSheetService: TimesheetService,
    private _authService: AuthService,
    private _roleSevice: RoleService,
    private _toastrService: ToastrService,
    private _modalService: NgbModal,
    private _timeLogService: TimeLogService,
    private _loginService: LoginService,
    private cdRef: ChangeDetectorRef,
  ) {
    const userInfo = this._authService.getUserInfo();
    if (userInfo) {
      this.isAdmin = userInfo.roleTypeName === Roles.administrative;
      this.currentUserId = userInfo.userId;
    }

    if (this.isAdmin)
      this.displayedColumns = [
        'clientName',
        'matterName',
        'userName',
        'headlinePracticeArea',
        'phase',
        'categoryName',
        'date',
        'duration',
        'description',
      ];

    this.manualRequestForm = new UntypedFormGroup({
      date: new UntypedFormControl(new Date(), [Validators.required]),
      time: new UntypedFormControl('', [
        Validators.required,
        timeSheetTimeValidator(true, Config.timeSheetTimeValidation.regexWithColon),
      ]),
      description: new UntypedFormControl(''),
    });
    this.getCategories();
    this.getTimeEntryTypes();
    this.removeValidationsForDescription(this.selectedTimeEntryType || 0, this.manualRequestForm);
  }

  ngOnInit(): void {
    this.getHeadlinePracticeArea();
    this.getUserDetails();
  }

  getUserDetails() {
    this.isLoading.emit(true);
    if (this.currentUserId)
      this._loginService.getUserByID(this.currentUserId).subscribe({
        next: (res) => {
          if (!this.isAdmin) {
            const userID = this.currentUserId;
            this.lawyerListOfManualEntry = [{ id: userID, lawyerName: res.result.fullName }];

            this.selectedLawyerManualEntry = this.currentUserId;
            this.getMattersClientsList(
              { timeEntryTypeId: this.selectedTimeEntryType, userId: this.selectedLawyerManualEntry },
              true,
              true,
            );
          } else {
            this.getUserListOfLegalTeam();
          }
          this.isLoading.emit(false);
          this._loginService.setPracticeAreaData(res.result.practiceArea);
        },
      });
  }

  getMattersClientsList(
    request: MatterClientByTimeEntryTypeRequest,
    updateClientList: boolean,
    updateMatterList: boolean,
  ) {
    this._timeSheetService.getMattersClientsListByTimeEntryTypeAndLegalUser(request).subscribe({
      next: this.handleGetMattersClientsListSuccess.bind(this, updateClientList, updateMatterList),
    });
  }

  checkIfPreviouslySelectedClientExists() {
    return (
      this.clientListOfManualEntry?.findIndex((client) => client.id === this.previouslySelectedClientManualTimesheet) >
      -1
    );
  }

  isTimeEntryTypeBillableOrNonBillable(timeEntryType: number): boolean {
    return (
      timeEntryType === TimeSheetTimeEntryTypeIDs.Billable || timeEntryType === TimeSheetTimeEntryTypeIDs.NonBillable
    );
  }

  handleGetMattersClientsListSuccess(
    updateClientList: boolean,
    updateMatterList: boolean,
    result: MatterClientByTimeEntryTypeResponse,
  ) {
    updateClientList && (this.clientListOfManualEntry = result.result.clientList);
    updateMatterList && (this.matterListOfManualEntry = result.result.matterList);
    this.isPreviouslySelectedClientExistManualTimesheet = true;
    if (this.clientListOfManualEntry?.length) {
      this.isPreviouslySelectedClientExistManualTimesheet =
        this.previouslySelectedClientManualTimesheet &&
        this.previouslySelectedTimeEntryTypeManualTimesheet === TimeSheetTimeEntryTypeIDs.TempTime &&
        this.selectedTimeEntryType === TimeSheetTimeEntryTypeIDs.Billable
          ? this.checkIfPreviouslySelectedClientExists()
          : true;
      if (
        (this.selectedTimeEntryType === TimeSheetTimeEntryTypeIDs.TempTime &&
          this.isTimeEntryTypeBillableOrNonBillable(this.previouslySelectedTimeEntryTypeManualTimesheet ?? 0)) ||
        (this.previouslySelectedTimeEntryTypeManualTimesheet === TimeSheetTimeEntryTypeIDs.TempTime &&
          this.isTimeEntryTypeBillableOrNonBillable(this.selectedTimeEntryType) &&
          this.checkIfPreviouslySelectedClientExists())
      ) {
        updateClientList &&
          this.setSelectedClientDetailsManualTimesheet(
            this.previouslySelectedClientManualTimesheet,
            this.previouslySelectedClientNameManualTimesheet,
          );
      } else if (this.selectedTimeEntryType === TimeSheetTimeEntryTypeIDs.NonBillable) {
        this.setSelectedClientDetailsManualTimesheet(
          this.clientListOfManualEntry[0].id,
          this.clientListOfManualEntry[0].clientName ?? '',
        );
      }
    }
  }

  setSelectedClientDetailsManualTimesheet(clientID: number | null, clientName: string) {
    this.selectedClientManualEntry = clientID;
    this.selectedClientNameManualTimesheet = clientName;
    this.getMattersByClientId();
  }

  getUserListOfLegalTeam() {
    this._timeSheetService.getUserListForTempTimeHoursFilter().subscribe((res) => {
      this.lawyerListOfManualEntry = res.result.map(({ id, userName: lawyerName }) => ({ id, lawyerName }));
    });
  }

  getTimeEntryTypes() {
    this._timeSheetService.getTimeEntryTypes().subscribe({
      next: (res) => {
        this.timeEntryTypes = res.result;
      },
      error: () => {
        this.timeEntryTypes = [];
      },
    });
  }

  getCategories() {
    this._timeSheetService.getCategories().subscribe({
      next: (res) => {
        this.categoryListOfManualEntry = res.result;
      },
    });
  }

  addManualRequest() {
    this.isEntryAdded = true;
    const req: AddEditManualTimeEntryRequest = {
      timeEntryTypeId: this.selectedTimeEntryType,
      userId: this.selectedLawyerManualEntry,
      date: this._timeSheetService.formatDate(this.manualRequestForm.controls.date.value),
      clientId: this.selectedClientManualEntry,
      matterId: this.selectedMatterManualEntry,
      practiceAreaHeadPhase: this.selectedPhaseManualEntry,
      categoryId: this.selectedCategoryOfManualEntry,
      description: this.manualRequestForm.controls.description.value,
      time: this.manualRequestForm.controls.time.value,
      isManualEntry: true,
    };
    this._timeSheetService.addManualTimeEntry(req).subscribe({
      next: (res) => {
        this._toastrService.success(res.message);
        this.reset();
        this.isEntryAdded = false;
        this._modalService.dismissAll();
        this.onTimeSheetDataChanged();
        this._timeSheetService.updateNowDashboardData(false, false);
      },
      error: (error: HttpErrorResponse) => {
        if (
          error.status === TimerErrorCodes.TimeExceededOrTimeSheetLock ||
          error.status === TimerErrorCodes.MatterNotAssigned ||
          error.status === TimerErrorCodes.DayChanged
        ) {
          this._toastrService.error(error.error.message);
          this._modalService.dismissAll();
          this.isEntryAdded = false;
        } else if (error.status === TimerErrorCodes.DateNotBetweenPlannedStartAndEndDate) {
          this._toastrService.error(error.error.message);
          this.isEntryAdded = false;
        }
        this.onTimeSheetDataChanged();
      },
    });
  }

  onTimeSheetDataChanged() {
    this._timeLogService.onTimeSheetDataChanged();
  }

  reset() {
    this.manualRequestForm.reset({ date: new Date() });
    this.isManualTimeEntryDateValid = true;
    this.selectedTimeEntryType = TimeSheetTimeEntryTypeIDs.Billable;
    this.selectedClientManualEntry = null;
    this.selectedClientNameManualTimesheet = '';
    this.previouslySelectedClientNameManualTimesheet = '';
    this.previouslySelectedClientManualTimesheet = null;
    this.isPreviouslySelectedClientExistManualTimesheet = true;
    this.selectedLawyerManualEntry = null;
    this.selectedMatterManualEntry = null;
    this.isSelectedMatterManualEntryActive = true;
    this.resetPracticeAreaAndPhase(false);
    this.selectedCategoryOfManualEntry = null;
    this.clientListOfManualEntry = [];
    this.lawyerListOfManualEntry = [];
    this.matterListOfManualEntry = [];
  }

  timeEntryTypeSelected($event: any) {
    this.isPreviouslySelectedClientExistManualTimesheet = true;
    this.previouslySelectedClientManualTimesheet = this.selectedClientManualEntry;
    this.previouslySelectedClientNameManualTimesheet = this.selectedClientNameManualTimesheet;
    this.previouslySelectedTimeEntryTypeManualTimesheet = this.selectedTimeEntryType;
    this.selectedTimeEntryType = $event?.id;
    this.manageTimeEntryTypeOperationForManualTimesheet();
    this.checkIfRoundingApplies(
      this.selectedTimeEntryType ?? 0,
      this.selectedClientManualEntry ?? 0,
      this.selectedMatterManualEntry,
    );
  }

  manageTimeEntryTypeOperationForManualTimesheet() {
    this.removeValidationsForDescription(this.selectedTimeEntryType || 0, this.manualRequestForm);
    this.selectedClientManualEntry = null;
    this.selectedClientNameManualTimesheet = '';
    this.selectedMatterManualEntry = null;
    this.isSelectedMatterManualEntryActive = true;
    this.resetPracticeAreaAndPhase(false);
    this.selectedCategoryOfManualEntry = null;
    this.matterListOfManualEntry = [];
    if (this.selectedTimeEntryType) {
      this.getMattersClientsListByTimeEntryTypeAndLegalUser();
    } else {
      this.clientListOfManualEntry = [];
    }
  }

  lawyerOfManualEntrySelected() {
    if (this.selectedTimeEntryType != 2) {
      this.clientListOfManualEntry = [];
      this.matterListOfManualEntry = [];
      this.selectedClientManualEntry = null;
      this.selectedClientNameManualTimesheet = '';
      this.isPreviouslySelectedClientExistManualTimesheet = true;
      this.selectedMatterManualEntry = null;
      this.isSelectedMatterManualEntryActive = true;
      this.resetPracticeAreaAndPhase(false);
    }
    if (this.selectedLawyerManualEntry) {
      this.getMattersClientsListByTimeEntryTypeAndLegalUser();
    }
  }

  getMattersClientsListByTimeEntryTypeAndLegalUser(updateClientList = true, updateMatterList = true) {
    let req: MatterClientByTimeEntryTypeRequest;
    if (this.selectedTimeEntryType && !this.isAdmin) {
      req = {
        timeEntryTypeId: this.selectedTimeEntryType,
        userId: this.currentUserId,
      };
      this.getMattersClientsList(req, updateClientList, updateMatterList);
    } else if (
      (this.selectedTimeEntryType && this.selectedLawyerManualEntry && this.isAdmin) ||
      (this.isAdmin && this.selectedTimeEntryType === 2)
    ) {
      req = {
        timeEntryTypeId: this.selectedTimeEntryType,
        userId: this.selectedLawyerManualEntry ? this.selectedLawyerManualEntry : this.currentUserId,
      };
      this.getMattersClientsList(req, updateClientList, updateMatterList);
    }
  }

  onClientSelectionChanged($event: any) {
    this.selectedClientNameManualTimesheet = $event?.clientName ?? '';
    this.isPreviouslySelectedClientExistManualTimesheet = true;
    this.checkIfRoundingApplies(this.selectedTimeEntryType, $event?.id, this.selectedMatterManualEntry);
  }

  getMattersByClientId() {
    if (this.selectedTimeEntryType != 2) {
      this.selectedMatterManualEntry = null;
      this.isSelectedMatterManualEntryActive = true;
      this.resetPracticeAreaAndPhase(false);
    }
    if (
      (this.selectedClientManualEntry && this.selectedTimeEntryType && this.selectedLawyerManualEntry) ||
      (this.isAdmin && this.selectedTimeEntryType == 2)
    ) {
      const req: MatterByClientIdRequest = {
        timeEntryTypeId: this.selectedTimeEntryType,
        userId:
          this.selectedTimeEntryType != 2 && this.selectedLawyerManualEntry && this.selectedClientManualEntry
            ? this.selectedLawyerManualEntry
            : this.currentUserId,
        clientId:
          this.selectedTimeEntryType != 2 && this.selectedLawyerManualEntry && this.selectedClientManualEntry
            ? this.selectedClientManualEntry
            : this.nonBillableClientId,
      };
      this._timeSheetService.getMattersByClientId(req).subscribe((res) => {
        this.matterListOfManualEntry = res.result;
      });
    } else {
      this.getMattersClientsListByTimeEntryTypeAndLegalUser(false);
    }
    this.clientIDOfSelectedMatterManualEntry = this.selectedClientManualEntry;
  }

  getClientByMatterID($event: any) {
    $event && (this.isSelectedMatterManualEntryActive = $event.isActive);
    if (
      (this.selectedMatterManualEntry && this.selectedTimeEntryType && this.selectedLawyerManualEntry) ||
      (this.isAdmin && this.selectedTimeEntryType == 2)
    ) {
      const req: number | null = this.selectedMatterManualEntry;
      this._timeSheetService.getClientByMatterId(req).subscribe((res) => {
        if (this.selectedClientManualEntry !== res.result.id) {
          this.selectedClientManualEntry = res.result.id;
          this.selectedClientNameManualTimesheet = res.result?.clientName ?? '';
          this.isPreviouslySelectedClientExistManualTimesheet = true;
        }
        this.clientIDOfSelectedMatterManualEntry = this.selectedClientManualEntry;
      });
      this.getPracticeAreaHeadPhaseList(this.selectedMatterManualEntry ?? 0, false);
    } else {
      !this.selectedClientManualEntry && this.getMattersClientsListByTimeEntryTypeAndLegalUser(false);
      this.isSelectedMatterManualEntryActive = true;
      this.resetPracticeAreaAndPhase(false);
    }
  }

  getHeadlinePracticeArea() {
    this._roleSevice.getHeadlinePracticeArea().subscribe((res: HeadLinePracticeAreaResponse) => {
      this.headLinePracticeAreaByMatterId = res.result;
    });
    this.isLoading.emit(false);
  }

  getPracticeAreaHeadPhaseList(
    matterID: number,
    isEdit: boolean = true,
    resetSelectedPhase: boolean = true,
    fromEditTimeEntry: boolean = false,
  ) {
    if (!matterID) {
      this.resetPracticeAreaAndPhase();
      return;
    }
    this._timeSheetService.getPracticeAreaHeadPhaseList(matterID ?? 0).subscribe({
      next: (res: HeadlinePracticeAreaPhaseListResponse) => {
        this.phaseByMatterId = res.result?.phase?.length ? res.result?.phase : [];
        if (isEdit) {
          !fromEditTimeEntry &&
            (this.selectedHeadlinePracticeAreaEditTimesheetEntry = res.result?.headlinePracticeAreaId ?? 0);
          resetSelectedPhase && (this.selectedPhaseEditTimesheetEntry = null);
        } else {
          this.selectedHeadlinePracticeAreaManualEntry = res.result?.headlinePracticeAreaId ?? 0;
          resetSelectedPhase && (this.selectedPhaseManualEntry = null);
        }
      },
      error: () => {
        this.resetPracticeAreaAndPhase(isEdit);
      },
    });
  }

  dateFilterSelected() {
    const date = this.manualRequestForm.controls.date.value;
    if (date) {
      const temp = this._timeSheetService.formatDate(date);
      this._timeSheetService.checkIfTimeSheetLocked(temp).subscribe((res) => {
        if (!res?.result?.isLock) {
          this.isManualTimeEntryDateValid = true;
          this.lockedDateToBeDisplayed = '';
        } else {
          this.isManualTimeEntryDateValid = false;
          this.lockedDateToBeDisplayed = this._timeSheetService.formatDate(res.result.date);
        }
      });
    }
  }

  disableDate() {
    return false;
  }

  clearDate(event: any) {
    event.stopPropagation();
    this.manualRequestForm.controls.date.setValue(null);
    this.isManualTimeEntryDateValid = true;
  }

  openDatePicker() {
    this.datePicker?.open();
  }

  resetPracticeAreaAndPhase(isEdit = true) {
    if (isEdit) {
      this.selectedHeadlinePracticeAreaEditTimesheetEntry = null;
      this.selectedPhaseEditTimesheetEntry = null;
    } else {
      this.selectedHeadlinePracticeAreaManualEntry = null;
      this.selectedPhaseManualEntry = null;
    }
    this.phaseByMatterId = [];
  }

  removeValidationsForDescription(timeEntryTypeID: number, form: UntypedFormGroup) {
    if (timeEntryTypeID === TimeSheetTimeEntryTypeIDs.TempTime) {
      form?.get('description')?.clearValidators();
      form?.get('description')?.updateValueAndValidity();
      form?.get('description')?.addValidators([descriptionValidator(Config.maxLength2000, false)]);
    } else {
      form?.get('description')?.addValidators([descriptionValidator()]);
    }
    form?.get('description')?.updateValueAndValidity();
  }

  tabClick(event: any) {
    this.isLoading.emit(true);
    if (event.index == 1) {
      this._timeSheetService.getTimesheetManualEntryLogList().subscribe({
        next: (res) => {
          this.isLoading.emit(false);
          this.logListDataSource.data = res.result;
          if (this.logListDataSource.data.length == 0) this.ifNoDataFound = true;
          else this.ifNoDataFound = false;
        },
        error: () => {
          this.isLoading.emit(false);
        },
      });
    } else {
      this.isLoading.emit(false);
    }
  }

  isTimeRounded(time: string) {
    return TimeRoundingUtilsService.isTimeRounded(time);
  }

  checkIfRoundingApplies(timeEntryType: number, clientId: number, matterId: number | null) {
    const req: TimeRoundingRequest = {
      clientId: clientId,
      timeEntryTypeId: timeEntryType,
      matterId: matterId,
    };
    this._timeSheetService.getIsTimeRounded(req).subscribe({
      next: (result) => {
        if (result) {
          this.isRoundingApplicable = result.result.timeIsRounded;
        }
      },
    });
  }

  showRoundingApplied() {
    if (
      this.isRoundingApplicable &&
      !this.manualRequestForm?.controls?.time?.hasError('isTimeInvalid') &&
      !this.manualRequestForm?.controls?.time?.hasError('isTimeExceeded') &&
      !this.isTimeRounded(this.manualRequestForm.controls.time.value)
    ) {
      return true;
    }

    return false;
  }
}
