import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimeLogService } from '../../services/time-log.service';
import { TimesheetService } from '../../services/timesheet.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit, OnDestroy {
  @Input() headerTitle: string = '';
  @Input() subjectLine: string = 'Are you sure you want to cancel editing this data?';
  @Input() confirmBtnText: string = 'Yes';
  @Input() cancelBtnText: string = 'Cancel';
  @Input() isTimerRunning: boolean = false;
  @Output() confirmed: EventEmitter<boolean> = new EventEmitter();

  currentTimeString: string = '';
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private _timeLogService: TimeLogService,
    private _timesheetService: TimesheetService,
  ) {}

  ngOnInit(): void {
    if (this.isTimerRunning) {
      this.subscribeCurrentTimer();
    }
  }

  subscribeCurrentTimer() {
    this._timeLogService.currentTimeofMatterObservable.pipe(takeUntil(this.onDestroy$)).subscribe((d) => {
      this.currentTimeString = this._timesheetService.formatTime(d, true, true);
    });
  }

  onConfirmed(): void {
    this.confirmed.emit(true);
  }

  onCancelled(): void {
    this.confirmed.emit(false);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
