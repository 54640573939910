import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-invoices-not-sent-popup',
  templateUrl: './invoices-not-sent-popup.component.html',
  styleUrls: ['./invoices-not-sent-popup.component.scss'],
})
export class InvoicesNotSentPopupComponent {
  @Input() headerTitle: string = `Invoices can't be sent`;
  @Input() message: string = '';

  constructor(public activeModal: NgbActiveModal) {}
}
