import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-data-modal',
  templateUrl: './delete-data-modal.component.html',
  styleUrls: ['./delete-data-modal.component.scss'],
})
export class DeleteDataModalComponent {
  @Input() headerTitle: string = '';
  @Input() subjectLine: string = 'Are you sure you want to delete data?';
  @Output() deleteRecord: EventEmitter<boolean> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {}

  onDeleteRecordApproved(): void {
    this.deleteRecord.emit(true);
    this.activeModal.close();
  }
}
