import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { Config } from '../../constants/constants';
import { Roles, TimeSheetTimeEntryTypeIDs } from '../../enums/enums';
import { ValidationMessages } from '../../enums/messages';
import { StopTimerRequest } from '../../interfaces/Admin/AutomaticTimeEntry/StopTimerRequest';
import { Timer, TimerResponse } from '../../interfaces/Admin/AutomaticTimeEntry/TimerResponse';
import {
  AddEditManualTimeEntryRequest,
  EditTimeSheetValuesChanged,
} from '../../interfaces/Admin/ManualRequest/AddEditManualTimeEntryRequest';
import { Category } from '../../interfaces/Admin/ManualRequest/AllCategoryResponse';
import { ClientList, MatterList } from '../../interfaces/Admin/ManualRequest/MatterClientByTimeEntryTypeResponse';
import { MatterClientByTimeEntryTypeRequest } from '../../interfaces/Admin/ManualRequest/MatterClientByTimeEntryTypeResquest';
import { TimeEntryType } from '../../interfaces/Admin/ManualRequest/TimeEntryTypeResponse';
import { MatterByClientIdRequest } from '../../interfaces/Admin/Matter/MatterByClientIdRequest';
import { MattersByClientId } from '../../interfaces/Admin/Matter/MattersByClientIdResponse';
import {
  HeadLinePracticeArea,
  HeadLinePracticeAreaResponse,
} from '../../interfaces/Admin/Role/HeadLinePracticeAreaNyRoleIdRespomse';
import { HeadlinePracticeAreaPhaseListResponse } from '../../interfaces/Admin/Timesheet/HeadlinePracticeAreaPhaseListResponse';
import { TimeRoundingRequest } from '../../interfaces/Admin/Timesheet/TimeRoundingRequest';
import {
  EndTimerParams,
  TimesheetDetails,
} from '../../interfaces/Admin/Timesheet/WeeklyTimesheetDetailsByTimesheetIdResponse';
import { AuthService, RoleService } from '../../services';
import { TimeLogService } from '../../services/time-log.service';
import { TimeRoundingUtilsService } from '../../services/time-rounding-utils.service';
import { TimesheetService } from '../../services/timesheet.service';
import { descriptionValidator } from '../../validators/description.validator';
import { timeSheetTimeValidator } from '../../validators/timesheet-time.validator';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-edit-timer-modal',
  templateUrl: './edit-timer-modal.component.html',
})
export class EditTimerModalComponent implements OnInit {
  @Input() timesheetDetail: TimesheetDetails;
  @Input() endTimerParams: EndTimerParams;
  @Input() isDashboardEnd: boolean;

  @Output() clearInlineEditor = new EventEmitter<void>();
  @Output() closeModal = new EventEmitter<void>();
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() setActiveStatus = new EventEmitter<void>();

  editTimesheetEntry: UntypedFormGroup;
  timeEntryTypes: TimeEntryType[];
  currentTimer: null | Timer;
  editTimeSheetValuesChanged: EditTimeSheetValuesChanged;
  currentUserId: number;
  clientListOfEditTimesheetEntry: ClientList[];
  matterListOfEditTimesheetEntry: MattersByClientId[] | MatterList[];
  nonBillableClientId: number;
  isAdmin = false;
  isPreviouslySelectedClientExistTimesheetEdit = true;
  isTimerRunning = false;
  currentTimeString: string;
  lastLoggedTimeNumber: number;
  lastLoggedTimeString: string;
  currentTimeNumber: number;
  timeSheetTimeEntryTypeIDs = TimeSheetTimeEntryTypeIDs;

  selectedTimeEntryTypeEditTimesheetEntry: number;
  previouslySelectedClientTimesheetEdit: number | null;
  previouslySelectedTimeEntryTypeEditTimesheet: number | null;
  selectedClientEditTimesheetEntry: number | null;
  selectedClientNameTimesheetEdit: string;
  previouslySelectedClientNameTimesheetEdit: string;
  selectedMatterEditTimesheetEntry: number | null;
  selectedHeadlinePracticeAreaEditTimesheetEntry: number | null;
  selectedPhaseEditTimesheetEntry: string | null;
  selectedHeadlinePracticeAreaManualEntry: number | null;
  selectedPhaseManualEntry: string | null;
  phaseByMatterId: string[] = [];
  previouslySelectedClientManualTimesheet: number | null;
  isPreviouslySelectedClientExistManualTimesheet = true;
  isSelectedMatterEditTimesheetEntryActive = true;
  selectedClientNameManualTimesheet: string;
  selectedClientIdManualTimesheet: number;
  previouslySelectedClientNameManualTimesheet: string;
  selectedClientManualEntry: number | null;
  previouslySelectedTimeEntryTypeManualTimesheet: number | null;
  selectedTimeEntryType: number | null = TimeSheetTimeEntryTypeIDs.Billable;
  selectedCategoryOfEditTimesheetEntry: number | null;
  isTimerStoppedFromEditPopup = false;
  totalTimeForTimeSheetEntry: number;
  lastRecordedTimeForTimeSheetEntry: number;
  lastUpdatedTimeForEditTimeSheetTimeValue = '';
  headLinePracticeAreaByMatterId: HeadLinePracticeArea[] = [];
  categoryListOfManualEntry: Category[];
  descriptionInputSubject = new Subject<string>();
  validationMessages = ValidationMessages;
  isRoundingApplicable = false;

  private destroyRef = inject(DestroyRef);

  constructor(
    private _timeSheetService: TimesheetService,
    private _timeLogService: TimeLogService,
    private _authService: AuthService,
    private _roleSevice: RoleService,
    private _toastrService: ToastrService,
    private _modalService: NgbModal,
  ) {
    const userInfo = this._authService.getUserInfo();
    if (userInfo) {
      this.isAdmin = userInfo.roleTypeName === Roles.administrative;
      this.currentUserId = userInfo.userId;
    }

    this.editTimesheetEntry = new UntypedFormGroup({
      time: new UntypedFormControl('', [
        Validators.required,
        timeSheetTimeValidator(true, Config.timeSheetTimeValidation.regexWithColon),
      ]),
      description: new UntypedFormControl(''),
    });
  }

  ngOnInit() {
    this.getHeadlinePracticeArea();
    this.subscribeCurrentTimeofMatter();
    this.subscribeNonBillableClientData();
    this.getTimeEntryTypes();
    this.getCategories();
    this.subscribeCurrenTimesheetDetails();
    this.resetEditTimesheetValueChangedParams();

    if (this.currentTimer?.timesheetId !== this.timesheetDetail.id) {
      this.editTimesheetEntry?.get('time')?.enable();
    }

    if (this.timesheetDetail.timeEntryTypeId) {
      this.selectedTimeEntryTypeEditTimesheetEntry =
        this.isDashboardEnd && this.timesheetDetail.timeEntryTypeId === TimeSheetTimeEntryTypeIDs.TempTime
          ? TimeSheetTimeEntryTypeIDs.Billable
          : this.timesheetDetail.timeEntryTypeId;
      this.getMattersClientsListByTimeEntryTypeAndUserTimesheetEdit();
    }

    if (this.timesheetDetail.clientId) {
      this.selectedClientEditTimesheetEntry = this.timesheetDetail.clientId;
      this.selectedClientNameTimesheetEdit = this.timesheetDetail.clientName ?? '';
      this.resetPreviouslySelectedTimesheetEditData();
      this.getMattersByClientIdEditTimesheet();
    }

    if (this.timesheetDetail.matterId) {
      this.selectedMatterEditTimesheetEntry = this.timesheetDetail.matterId;
      this.getPracticeAreaHeadPhaseList(this.selectedMatterEditTimesheetEntry, true, false, true);
    }

    if (this.timesheetDetail.categoryId) this.selectedCategoryOfEditTimesheetEntry = this.timesheetDetail.categoryId;
    this.timesheetDetail.phase && (this.selectedPhaseEditTimesheetEntry = this.timesheetDetail.phase);
    this.timesheetDetail.headlinePracticeAreaId &&
      (this.selectedHeadlinePracticeAreaEditTimesheetEntry = this.timesheetDetail.headlinePracticeAreaId);
    this.editTimesheetEntry.controls.description.setValue(this.timesheetDetail.description || '');
    this.editTimesheetEntry.controls.time.setValue(
      this._timeSheetService.formatTime(this._timeSheetService.getSeconds(this.timesheetDetail.totalTime), true, true),
    );

    this.removeValidationsForDescription(this.selectedTimeEntryTypeEditTimesheetEntry || 0, this.editTimesheetEntry);

    this.descriptionInputSubject
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.checkIfTimeSheetEditValuesChanged();
      });
  }

  getMattersByClientIdEditTimesheet() {
    this.isLoading.emit(true);
    if (this.selectedTimeEntryTypeEditTimesheetEntry !== 2) {
      this.selectedMatterEditTimesheetEntry = null;
      this.isSelectedMatterEditTimesheetEntryActive = true;
      this.resetPracticeAreaAndPhase();
    }
    if (
      (this.selectedClientEditTimesheetEntry && this.selectedTimeEntryTypeEditTimesheetEntry) ||
      this.selectedTimeEntryTypeEditTimesheetEntry == 2
    ) {
      const req: MatterByClientIdRequest = {
        timeEntryTypeId: this.selectedTimeEntryTypeEditTimesheetEntry,
        userId: this.currentUserId,
        clientId:
          this.selectedTimeEntryTypeEditTimesheetEntry != 2 && this.selectedClientEditTimesheetEntry
            ? this.selectedClientEditTimesheetEntry
            : this.nonBillableClientId,
      };
      this._timeSheetService.getMattersByClientId(req).subscribe({
        next: (res) => {
          this.matterListOfEditTimesheetEntry = res.result;
          this.isLoading.emit(false);
        },
      });
    } else {
      this.getMattersClientsListByTimeEntryTypeAndUserTimesheetEdit(false);
      this.isLoading.emit(false);
    }
  }

  resetPracticeAreaAndPhase(isEdit = true) {
    if (isEdit) {
      this.selectedHeadlinePracticeAreaEditTimesheetEntry = null;
      this.selectedPhaseEditTimesheetEntry = null;
    } else {
      this.selectedHeadlinePracticeAreaManualEntry = null;
      this.selectedPhaseManualEntry = null;
    }
    this.phaseByMatterId = [];
  }

  resetPreviouslySelectedTimesheetEditData() {
    this.isPreviouslySelectedClientExistTimesheetEdit = true;
    this.previouslySelectedClientTimesheetEdit = null;
    this.previouslySelectedClientNameTimesheetEdit = '';
    this.previouslySelectedTimeEntryTypeEditTimesheet = null;
  }

  getMattersClientsListByTimeEntryTypeAndUserTimesheetEdit(updateClientList = true, updateMatterList = true) {
    this.isLoading.emit(true);
    if (this.selectedTimeEntryTypeEditTimesheetEntry && !this.isAdmin) {
      const req: MatterClientByTimeEntryTypeRequest = {
        timeEntryTypeId: this.selectedTimeEntryTypeEditTimesheetEntry,
        userId: this.currentUserId,
      };
      this.getMattersClientsListEditTimesheet(req, updateClientList, updateMatterList);
    } else {
      this.isLoading.emit(false);
    }
  }

  getMattersClientsListEditTimesheet(
    request: MatterClientByTimeEntryTypeRequest,
    updateClientList: boolean,
    updateMatterList: boolean,
  ) {
    this._timeSheetService.getMattersClientsListByTimeEntryTypeAndLegalUser(request).subscribe({
      next: (res) => {
        updateClientList && (this.clientListOfEditTimesheetEntry = res.result.clientList);
        updateMatterList && (this.matterListOfEditTimesheetEntry = res.result.matterList);
        this.isPreviouslySelectedClientExistTimesheetEdit = true;
        if (this.clientListOfEditTimesheetEntry?.length) {
          this.isPreviouslySelectedClientExistTimesheetEdit =
            this.previouslySelectedClientTimesheetEdit &&
            this.previouslySelectedTimeEntryTypeEditTimesheet === TimeSheetTimeEntryTypeIDs.TempTime &&
            this.selectedTimeEntryTypeEditTimesheetEntry === TimeSheetTimeEntryTypeIDs.Billable
              ? this.clientListOfEditTimesheetEntry?.findIndex(
                  (client) => client.id === this.previouslySelectedClientTimesheetEdit,
                ) > -1
              : true;
          if (
            (this.selectedTimeEntryTypeEditTimesheetEntry === TimeSheetTimeEntryTypeIDs.TempTime &&
              (this.previouslySelectedTimeEntryTypeEditTimesheet === TimeSheetTimeEntryTypeIDs.Billable ||
                this.previouslySelectedTimeEntryTypeEditTimesheet === TimeSheetTimeEntryTypeIDs.NonBillable)) ||
            (this.previouslySelectedTimeEntryTypeEditTimesheet === TimeSheetTimeEntryTypeIDs.TempTime &&
              (this.selectedTimeEntryTypeEditTimesheetEntry === TimeSheetTimeEntryTypeIDs.Billable ||
                this.selectedTimeEntryTypeEditTimesheetEntry === TimeSheetTimeEntryTypeIDs.NonBillable) &&
              this.clientListOfEditTimesheetEntry?.findIndex(
                (client) => client.id === this.previouslySelectedClientTimesheetEdit,
              ) > -1)
          ) {
            updateClientList &&
              this.setSelectedClientDetailsTimesheetEdit(
                this.previouslySelectedClientTimesheetEdit,
                this.previouslySelectedClientNameTimesheetEdit,
              );
          } else if (this.selectedTimeEntryTypeEditTimesheetEntry === TimeSheetTimeEntryTypeIDs.NonBillable) {
            this.setSelectedClientDetailsTimesheetEdit(
              this.clientListOfEditTimesheetEntry[0].id,
              this.clientListOfEditTimesheetEntry[0]?.clientName,
            );
          }
        }
        this.isLoading.emit(false);
      },
      error: () => {
        this.isLoading.emit(false);
      },
    });
  }

  setSelectedClientDetailsTimesheetEdit(clientID: number | null, clientName: string) {
    this.selectedClientEditTimesheetEntry = clientID;
    this.selectedClientNameTimesheetEdit = clientName;
    this.getMattersByClientIdEditTimesheet();
  }

  timeEntryTypeSelected($event: any, isAutomaticEntry: boolean) {
    if (isAutomaticEntry) {
      this.isPreviouslySelectedClientExistTimesheetEdit = true;
      this.previouslySelectedClientTimesheetEdit = this.selectedClientEditTimesheetEntry;
      this.previouslySelectedClientNameTimesheetEdit = this.selectedClientNameTimesheetEdit;
      this.previouslySelectedTimeEntryTypeEditTimesheet = this.selectedTimeEntryTypeEditTimesheetEntry;
      this.selectedTimeEntryTypeEditTimesheetEntry = $event?.id;
      this.manageTimeEntryTypeOperationForEditTimesheet();
      this.checkIfRoundingApplies(
        this.selectedTimeEntryTypeEditTimesheetEntry,
        this.selectedClientIdManualTimesheet,
        this.selectedMatterEditTimesheetEntry,
      );
    }
  }

  manageTimeEntryTypeOperationForEditTimesheet() {
    this.removeValidationsForDescription(this.selectedTimeEntryTypeEditTimesheetEntry || 0, this.editTimesheetEntry);
    this.selectedClientEditTimesheetEntry = null;
    this.selectedMatterEditTimesheetEntry = null;
    this.isSelectedMatterEditTimesheetEntryActive = true;
    this.resetPracticeAreaAndPhase();
    this.selectedCategoryOfEditTimesheetEntry = null;
    this.matterListOfEditTimesheetEntry = [];
    if (this.selectedTimeEntryTypeEditTimesheetEntry) {
      this.getMattersClientsListByTimeEntryTypeAndUserTimesheetEdit();
    } else {
      this.clientListOfEditTimesheetEntry = [];
    }
  }

  onClientSelectionChanged($event: any, isAutomaticEntry: boolean = false) {
    if (isAutomaticEntry) {
      this.selectedClientNameTimesheetEdit = $event?.clientName ?? '';
      this.isPreviouslySelectedClientExistTimesheetEdit = true;
    } else {
      this.selectedClientNameManualTimesheet = $event?.clientName ?? '';
      this.isPreviouslySelectedClientExistManualTimesheet = true;
    }
    this.checkIfRoundingApplies(
      this.selectedTimeEntryTypeEditTimesheetEntry,
      $event?.id,
      this.selectedMatterEditTimesheetEntry,
    );
  }

  stopRunningTimerFromTimeSheetEdit() {
    this.clearInlineEditor.emit();
    if (!this.endTimerParams.isDashboardEnd && this.endTimerParams.index > -1) {
      this.setActiveStatus.emit();
    }
    if (this.currentTimer) {
      this.isLoading.emit(true);
      this._timeLogService.endTime();
      this._timeLogService
        .endTimer({
          userId: this.currentUserId,
          timesheetId: this.currentTimer.timesheetId,
          epochTimestamp: this.currentTimer.epochTimestamp,
          endTime: new Date().toISOString(),
          isManuallyStopped: true,
          timesheetDate: this._timeSheetService.formatDate(new Date().toDateString()),
          timezoneOffset: new Date().getTimezoneOffset() * -1,
          timezone: moment.tz.guess(),
        })
        .subscribe({
          next: this.handleEndTimerSuccess.bind(this),
          error: this.handleEndTimerFailure.bind(this),
        });
    } else {
      this._timeLogService.endTime();
      this._timeLogService.setcurrentTimesheetDetailsData(null);
    }
  }

  handleEndTimerFailure() {
    this.isTimerStoppedFromEditPopup = false;
    this.resetTimeDetailsRecordedForTimeSheetEntry();
    this.isLoading.emit(false);
    this._timeLogService.endTime();
    this._timeLogService.setcurrentTimesheetDetailsData(null);
  }

  resetTimeDetailsRecordedForTimeSheetEntry() {
    this.totalTimeForTimeSheetEntry = 0;
    this.lastRecordedTimeForTimeSheetEntry = 0;
  }

  getNowMatterListData() {
    const timeOut = setTimeout(() => {
      this._timeSheetService.getNowMatterListData();
      clearTimeout(timeOut);
    }, 2000);
  }

  getNowTimesheetListData() {
    const timeOut = setTimeout(() => {
      clearTimeout(timeOut);
      this._timeLogService.onTimeSheetDataChanged();
    }, 2000);
  }

  handleEndTimerSuccess(response: TimerResponse) {
    this.isTimerStoppedFromEditPopup = true;
    this.lastRecordedTimeForTimeSheetEntry = this._timeSheetService.getSeconds(response.result.lastLoggedTime || 0);
    this.totalTimeForTimeSheetEntry = this._timeSheetService.getSeconds(response.result.totalTimeForTimer);
    this._timeLogService.setcurrentTimesheetDetailsData(null);
    this.currentTimer = null;
    this._toastrService.success(response.message);
    this._timeSheetService.updateNowDashboardData(false, false);
    this.getNowMatterListData();
    this.getNowTimesheetListData();
    this.updateTimeToIncrementalOnTimesheetValueUpdateForNewEntry(
      this._timeSheetService.getSeconds(response.result.totalTimeForTimer),
      this._timeSheetService.getSeconds(response.result.lastLoggedTime || 0),
    );
    if (this.editTimesheetEntry.get('time')?.disabled) {
      this.editTimesheetEntry.get('time')?.enable();
    }
    this.isLoading.emit(false);
  }

  stopRunningTimerSaveTimesheetDetails() {
    this.isLoading.emit(true);
    if (this.isTimerRunning && this.currentTimer?.timesheetId === this.timesheetDetail.id) {
      this.endTimer(
        this.endTimerParams.isDashboardEnd,
        this.endTimerParams.isFromOpenTimerPopup,
        this.endTimerParams.isCancelEditTimesheet,
      );
    } else {
      this.editTimesheetOfAutomaticTimer();
    }
  }

  endTimer(isDashboardEnd: boolean, isFromOpenTimerPopup: boolean = false, isCancelEditTimesheet: boolean = false) {
    this.clearInlineEditor.emit();
    if (!isDashboardEnd) {
      this.setActiveStatus.emit();
    }
    if (this.currentTimer) {
      this.isLoading.emit(true);
      this._timeLogService.endTime();
      const date = new Date();
      let offset = date.getTimezoneOffset();
      offset = offset * -1;
      const req: StopTimerRequest = {
        userId: this.currentUserId,
        timesheetId: this.currentTimer.timesheetId,
        epochTimestamp: this.currentTimer.epochTimestamp,
        endTime: new Date().toISOString(),
        isManuallyStopped: true,
        timesheetDate: this._timeSheetService.formatDate(date.toDateString()),
        timezoneOffset: offset,
        timezone: moment.tz.guess(),
      };
      this._timeLogService.endTimer(req).subscribe({
        next: () => {
          this.isTimerStoppedFromEditPopup = false;
          this.resetTimeDetailsRecordedForTimeSheetEntry();
          const tempTimer = this.currentTimer;
          this._timeLogService.setcurrentTimesheetDetailsData(null);
          this.currentTimer = null;
          this._timeSheetService.updateNowDashboardData(false, false);
          this.getNowMatterListData();
          if (!isCancelEditTimesheet) {
            if (isDashboardEnd && tempTimer) {
              this.editTimesheetOfAutomaticTimer(ValidationMessages.timerStoppedTimeSheetSaved);
            } else {
              if (isFromOpenTimerPopup) {
                this.editTimesheetOfAutomaticTimer(ValidationMessages.timerStoppedTimeSheetSaved);
              }
              this.getNowTimesheetListData();
            }
          } else {
            this._toastrService.success(ValidationMessages.timerStoppedTimeSheetSaved);
            this.getNowTimesheetListData();
          }
          this.isLoading.emit(false);
          this._modalService.dismissAll();
        },
        error: () => {
          this.isTimerStoppedFromEditPopup = false;
          this.resetTimeDetailsRecordedForTimeSheetEntry();
          this.isLoading.emit(false);
          this._timeLogService.endTime();
          this._timeLogService.setcurrentTimesheetDetailsData(null);
          this._modalService.dismissAll();
        },
      });
    } else {
      this._timeLogService.endTime();
      this._timeLogService.setcurrentTimesheetDetailsData(null);
      this._modalService.dismissAll();
    }
  }

  editTimesheetOfAutomaticTimer(message: string = ValidationMessages.timeSheetUpdatedSuccessfully) {
    if (this.timesheetDetail.id) {
      this.isLoading.emit(true);
      const req: AddEditManualTimeEntryRequest = {
        id: this.timesheetDetail.id,
        timeEntryTypeId: this.selectedTimeEntryTypeEditTimesheetEntry,
        userId: this.currentUserId,
        date: this._timeSheetService.formatDate(new Date().toDateString()),
        clientId: this.selectedClientEditTimesheetEntry,
        matterId: this.selectedMatterEditTimesheetEntry,
        practiceAreaHeadPhase: this.selectedPhaseEditTimesheetEntry,
        categoryId: this.selectedCategoryOfEditTimesheetEntry,
        description: this.editTimesheetEntry.controls.description.value,
        time: this.editTimesheetEntry.controls.time.value,
        isManualEntry: this.timesheetDetail?.isManualEntry ?? false,
        isTimerStoppedFromPopup: !!(
          this.isTimerStoppedFromEditPopup &&
          this.lastUpdatedTimeForEditTimeSheetTimeValue &&
          this.lastUpdatedTimeForEditTimeSheetTimeValue !== this.editTimesheetEntry.controls.time.value
        ),
      };
      req.isCategoryOrDiscriptionChanged =
        this.endTimerParams.isEndTimerEdit && this.checkIfTimeSheetEditValuesChanged();
      req.isEditFromStopTimer = this.endTimerParams.isEndTimerEdit;
      this._timeSheetService.editTimesheetEntry(req).subscribe({
        next: this.handleEditTimesheetEntrySuccess.bind(this, message),
        error: this.handleEditTimesheetEntryFailure.bind(this),
      });
    }
  }

  handleEditTimesheetEntrySuccess(message: string) {
    this._toastrService.success(message);
    this.isLoading.emit(false);
    this.onTimeSheetDataChanged();
    this.resetEditTimesheetValueChangedParams();
    this._modalService.dismissAll();
  }

  handleEditTimesheetEntryFailure(response: HttpErrorResponse) {
    this._toastrService.error(response.error.message);
    this.isLoading.emit(false);
    this.onTimeSheetDataChanged();
  }

  onTimeSheetDataChanged() {
    this._timeSheetService.updateNowDashboardData(false, false);
    this._timeLogService.onTimeSheetDataChanged();
  }

  cancelEditTimeSheet() {
    if (this.timesheetDetail.id) {
      const timeSheetConfirmationModalRef = this._modalService.open(ConfirmationModalComponent, {
        windowClass: 'cancel-changes-modal edit-timesheet-delete',
        centered: true,
      });

      const isTimerRunningForCurrentTimesheet =
        this.isTimerRunning && this.currentTimer?.timesheetId === this.timesheetDetail.id;
      _.extend(timeSheetConfirmationModalRef.componentInstance, {
        headerTitle: 'Cancel Changes?',
        subjectLine: `Are you sure you want to cancel editing this data? Doing this will cancel your editing on this time entry for ${this._timeSheetService.formatTimeInHHMMSS(
          this.timesheetDetail?.totalTime || 0,
        )} hh:mm:ss.`,
        isTimerRunning: isTimerRunningForCurrentTimesheet,
      });
      timeSheetConfirmationModalRef.componentInstance.confirmed?.subscribe((result: boolean) => {
        if (result) {
          if (isTimerRunningForCurrentTimesheet) {
            this.endTimer(this.endTimerParams.isDashboardEnd, false, true);
          } else {
            this._modalService.dismissAll();
          }
        }
      });
    }
  }

  getClientByMatterIDForTimesheetEdit($event: any) {
    $event && (this.isSelectedMatterEditTimesheetEntryActive = $event.isActive);
    if (this.selectedMatterEditTimesheetEntry && this.selectedTimeEntryTypeEditTimesheetEntry) {
      const req: number | null = this.selectedMatterEditTimesheetEntry;
      this._timeSheetService.getClientByMatterId(req).subscribe({
        next: (res) => {
          if (this.selectedClientEditTimesheetEntry !== res.result.id) {
            this.selectedClientEditTimesheetEntry = res.result.id;
            this.selectedClientNameTimesheetEdit = res.result?.clientName ?? '';
            this.isPreviouslySelectedClientExistTimesheetEdit = true;
          }
        },
      });
      this.getPracticeAreaHeadPhaseList(this.selectedMatterEditTimesheetEntry);
    } else {
      !this.selectedClientEditTimesheetEntry && this.getMattersClientsListByTimeEntryTypeAndUserTimesheetEdit(false);
      this.isSelectedMatterEditTimesheetEntryActive = true;
      this.resetPracticeAreaAndPhase();
    }
  }

  getPracticeAreaHeadPhaseList(matterID: number, isEdit = true, resetSelectedPhase = true, fromEditTimeEntry = false) {
    if (!matterID) {
      this.resetPracticeAreaAndPhase();
      return;
    }
    this._timeSheetService.getPracticeAreaHeadPhaseList(matterID ?? 0).subscribe({
      next: (res: HeadlinePracticeAreaPhaseListResponse) => {
        this.phaseByMatterId = res.result?.phase?.length ? res.result?.phase : [];
        if (isEdit) {
          !fromEditTimeEntry &&
            (this.selectedHeadlinePracticeAreaEditTimesheetEntry = res.result?.headlinePracticeAreaId ?? 0);
          resetSelectedPhase && (this.selectedPhaseEditTimesheetEntry = null);
        } else {
          this.selectedHeadlinePracticeAreaManualEntry = res.result?.headlinePracticeAreaId ?? 0;
          resetSelectedPhase && (this.selectedPhaseManualEntry = null);
        }
      },
      error: () => {
        this.resetPracticeAreaAndPhase(isEdit);
      },
    });
  }

  removeValidationsForDescription(timeEntryTypeID: number, form: UntypedFormGroup) {
    if (timeEntryTypeID === TimeSheetTimeEntryTypeIDs.TempTime) {
      form?.get('description')?.clearValidators();
      form?.get('description')?.addValidators([descriptionValidator(Config.maxLength2000, false)]);
    } else {
      form?.get('description')?.addValidators([descriptionValidator()]);
    }
    form?.get('description')?.updateValueAndValidity();
  }

  checkIfTimeSheetEditValuesChanged(): boolean {
    if (this.endTimerParams.isEndTimerEdit) {
      this.editTimeSheetValuesChanged = {
        isCategoryChanged: !!(
          this.timesheetDetail?.categoryId &&
          this.timesheetDetail?.categoryId !== this.selectedCategoryOfEditTimesheetEntry
        ),
        isDescriptionChanged: !!(
          this.timesheetDetail?.categoryId &&
          this.timesheetDetail?.categoryId !== this.selectedCategoryOfEditTimesheetEntry
        ),
        isClientChanged: !!(
          this.timesheetDetail?.clientId && this.timesheetDetail?.clientId !== this.selectedClientEditTimesheetEntry
        ),
        isMatterChanged: !!(
          this.timesheetDetail?.matterId && this.timesheetDetail?.matterId !== this.selectedMatterEditTimesheetEntry
        ),
        isTimeEntryTypeChanged: !!(
          this.timesheetDetail?.timeEntryTypeId &&
          this.timesheetDetail?.timeEntryTypeId !== this.selectedTimeEntryTypeEditTimesheetEntry
        ),
      };

      const isValuesChanged =
        this.editTimeSheetValuesChanged.isTimeEntryTypeChanged ||
        this.editTimeSheetValuesChanged.isCategoryChanged ||
        this.editTimeSheetValuesChanged.isDescriptionChanged ||
        this.editTimeSheetValuesChanged.isClientChanged ||
        (this.selectedTimeEntryTypeEditTimesheetEntry !== TimeSheetTimeEntryTypeIDs.TempTime &&
          this.editTimeSheetValuesChanged.isMatterChanged);
      if (this.isTimerStoppedFromEditPopup) {
        const newTimeSheetTime = this.totalTimeForTimeSheetEntry - this.lastRecordedTimeForTimeSheetEntry;
        const formattedTime = this._timeSheetService.formatTime(newTimeSheetTime, true, true);
        this.editTimesheetEntry.get('time')?.setValue(formattedTime);
        this.lastUpdatedTimeForEditTimeSheetTimeValue = this._timeSheetService.formatTime(
          isValuesChanged ? newTimeSheetTime : this.totalTimeForTimeSheetEntry,
          true,
          true,
        );
      }
      return isValuesChanged && !!this.timesheetDetail?.lastLoggedTime;
    }
    return false;
  }

  subscribeCurrenTimesheetDetails() {
    this._timeLogService.currenTimesheetDetailsObservable
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((currentTimer) => {
        this.currentTimer = currentTimer;
      });
  }

  subscribeNonBillableClientData() {
    this._timeSheetService.allObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((nonBillableClientId) => {
      this.nonBillableClientId = nonBillableClientId;
    });
  }

  subscribeCurrentTimeofMatter() {
    this._timeLogService.currentTimeofMatterObservable
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((currentTimeNumber) => {
        this.currentTimeNumber = currentTimeNumber;
        this.currentTimeString = this._timeSheetService.formatTime(currentTimeNumber, false);
        this.isTimerRunning = this._timeLogService.isTimerRunning;

        if (this.isTimerRunning && this.currentTimer?.timesheetId === this.timesheetDetail.id) {
          this.lastLoggedTimeNumber = this._timeLogService.getLastLoggedTimeOfMatterData();
          this.updateTimeToIncrementalOnTimesheetValueUpdateForNewEntry(
            this.currentTimeNumber,
            this.lastLoggedTimeNumber,
          );
          if (this.editTimesheetEntry.get('time')?.enabled) {
            this.editTimesheetEntry.get('time')?.disable();
          }
        }
      });
  }

  updateTimeToIncrementalOnTimesheetValueUpdateForNewEntry(totalTimeForTimer: number, lastLoggedTimeForTimer: number) {
    if (this.checkIfTimeSheetEditValuesChanged() && totalTimeForTimer >= lastLoggedTimeForTimer) {
      const newTimeSheetTime = totalTimeForTimer - lastLoggedTimeForTimer;
      this.editTimesheetEntry.get('time')?.setValue(this._timeSheetService.formatTime(newTimeSheetTime, true, true));
      this.lastUpdatedTimeForEditTimeSheetTimeValue = this._timeSheetService.formatTime(newTimeSheetTime, true, true);
      this.lastLoggedTimeString = this._timeSheetService.formatTime(newTimeSheetTime, false);
    } else {
      this.editTimesheetEntry.get('time')?.setValue(this._timeSheetService.formatTime(totalTimeForTimer, true, true));
      this.lastUpdatedTimeForEditTimeSheetTimeValue = this._timeSheetService.formatTime(totalTimeForTimer, true, true);
    }
  }

  getHeadlinePracticeArea() {
    this._roleSevice.getHeadlinePracticeArea().subscribe((res: HeadLinePracticeAreaResponse) => {
      this.headLinePracticeAreaByMatterId = res.result;
    });
  }

  getTimeEntryTypes() {
    this._timeSheetService.getTimeEntryTypes().subscribe({
      next: (res) => {
        this.timeEntryTypes = res.result;
      },
      error: () => {
        this.timeEntryTypes = [];
      },
    });
  }

  getCategories() {
    this._timeSheetService.getCategories().subscribe({
      next: (res) => {
        this.categoryListOfManualEntry = res.result;
      },
    });
  }

  resetEditTimesheetValueChangedParams() {
    this.editTimeSheetValuesChanged = {
      isCategoryChanged: false,
      isClientChanged: false,
      isDescriptionChanged: false,
      isMatterChanged: false,
      isTimeEntryTypeChanged: false,
    };
  }

  isTimeRounded(time: string) {
    return TimeRoundingUtilsService.isTimeRounded(time);
  }

  checkIfRoundingApplies(timeEntryTypeId: number, clientId: number, matterId: number | null) {
    const req: TimeRoundingRequest = {
      clientId: clientId,
      timeEntryTypeId: timeEntryTypeId,
      matterId: matterId,
    };
    this._timeSheetService.getIsTimeRounded(req).subscribe({
      next: (result) => {
        if (result) {
          this.isRoundingApplicable = result.result.timeIsRounded;
        }
      },
    });
  }

  showRoundingApplied() {
    if (
      this.isRoundingApplicable &&
      !this.editTimesheetEntry?.controls?.time?.hasError('isTimeInvalid') &&
      !this.editTimesheetEntry?.controls?.time?.hasError('isTimeExceeded') &&
      !this.isTimeRounded(this.editTimesheetEntry.controls.time.value)
    ) {
      return true;
    }

    return false;
  }
}
