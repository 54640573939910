import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceServicePlan, MediaTypes } from '../../enums/enums';
import { InvoiceService } from '../../services/invoice.service';

@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss'],
})
export class PreviewModalComponent implements OnInit {
  @Input() pdfSrc: any;
  @Input() invoiceId: number = 0;
  @Input() invoiceServicePlan: InvoiceServicePlan;
  @Input() clientId: number;
  @Input() planInvoiceId: number;
  @Input() timesheetMonth: number;
  @Input() mediaType: MediaTypes = MediaTypes.Pdf;

  isLoading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private _invoiceService: InvoiceService,
  ) {}

  ngOnInit(): void {
    if (this.invoiceId) {
      this.getPreviewInvoice();
    } else if (this.invoiceServicePlan === InvoiceServicePlan.OnDemandTimesheets) {
      this.getPreviewTimesheet();
    }
  }

  getPreviewInvoice() {
    this.isLoading = true;
    this._invoiceService.previewInvoicePdf(this.invoiceId).subscribe({
      next: (response: ArrayBuffer) => {
        this.prepareBlob(response);
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  prepareBlob(buffer: ArrayBuffer) {
    const blob = new Blob([buffer], { type: this.mediaType });
    const fileReader = new FileReader();
    fileReader.onload = () => {
      this.pdfSrc = new Uint8Array(fileReader.result as ArrayBuffer);
    };
    fileReader.readAsArrayBuffer(blob);
  }

  getPreviewTimesheet() {
    this._invoiceService
      .previewOndemandTimesheetPdf({
        clientId: this.clientId,
        planInvoiceId: this.planInvoiceId,
        timesheetMonth: this.timesheetMonth,
      })
      .subscribe({
        next: (response: ArrayBuffer) => {
          this.prepareBlob(response);
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }
}
