import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import 'moment-timezone';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { APIUrls } from '../enums/apiUrls';
import { AddAutomaticTimeEntryRequest } from '../interfaces/Admin/AutomaticTimeEntry/AddAutomaticTimeEntryRequest';
import { StartTimerRequest } from '../interfaces/Admin/AutomaticTimeEntry/StartTimerRequest';
import { StopTimerRequest } from '../interfaces/Admin/AutomaticTimeEntry/StopTimerRequest';
import { Timer, TimerResponse } from '../interfaces/Admin/AutomaticTimeEntry/TimerResponse';
import { UpdateTimerRequest } from '../interfaces/Admin/AutomaticTimeEntry/UpdateTimerRequest';
import {
  ValidateNewStartTimeEntryRequest,
  ValidateNewStartTimeEntryResponse,
} from '../interfaces/Admin/AutomaticTimeEntry/ValidateNewStartTimeEntry';
import { SuccessResponse } from '../interfaces/Admin/SuccessResponse';
import { ApiService } from './api.service';
import { TimesheetService } from './timesheet.service';

@Injectable({
  providedIn: 'root',
})
export class TimeLogService {
  private totaltimeOfDaySubject = new BehaviorSubject<number>(0);
  totaltimeOfDayObservable: Observable<number> = this.totaltimeOfDaySubject.asObservable();

  private currentTimeofMatterSubject = new BehaviorSubject<number>(0);
  currentTimeofMatterObservable: Observable<number> = this.currentTimeofMatterSubject.asObservable();

  private lastLoggedTimeOfMatterSubject = new BehaviorSubject<number>(0);

  private currentTimesheetSubject = new BehaviorSubject<Timer | null>(null);
  currenTimesheetDetailsObservable: Observable<Timer | null> = this.currentTimesheetSubject.asObservable();

  private totalBillableAndNonBillableTimeOfDaySubject = new BehaviorSubject<number>(0);
  totalBillableAndNonBillableTimeOfDayObservable: Observable<number> =
    this.totalBillableAndNonBillableTimeOfDaySubject.asObservable();

  private timeSheetDataChangedSubject: Subject<boolean> = new Subject();
  timeSheetDataChangedObservable: Observable<boolean> = this.timeSheetDataChangedSubject.asObservable();

  private isIncompleteTimeSheetSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isIncompleteTimeSheetObservable: Observable<boolean> = this.isIncompleteTimeSheetSubject.asObservable();

  channel = new BroadcastChannel('app-data');

  setIntervalId: any;
  isTimerRunning: boolean;

  constructor(
    private _apiService: ApiService,
    private _timesheetService: TimesheetService,
  ) {}

  onTimeSheetDataChanged(isSaved: boolean = true) {
    this.timeSheetDataChangedSubject.next(isSaved);
  }

  setIncompleteTimeSheet(isIncompleteTimeSheet: boolean = false) {
    this.isIncompleteTimeSheetSubject.next(isIncompleteTimeSheet);
  }

  async stopTimerForTabClose(id: number) {
    return new Promise((resolve, reject) => {
      const currentTimesheet = this.currentTimesheetSubject.getValue();
      if (currentTimesheet) {
        this.endTimer({
          userId: id,
          timesheetId: currentTimesheet.timesheetId,
          epochTimestamp: currentTimesheet.epochTimestamp,
          endTime: new Date().toISOString(),
          isManuallyStopped: false,
          timesheetDate: this._timesheetService.formatDate(new Date().toDateString()),
          timezoneOffset: new Date().getTimezoneOffset() * -1,
          timezone: moment.tz.guess(),
        }).subscribe({
          next: () => {
            this.endTime();
            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
      }
    });
  }

  setcurrentTimesheetDetailsData(data: Timer | null) {
    if (data) this.currentTimesheetSubject.next(data);
    else this.currentTimesheetSubject.next(null);
  }

  setcurrentTimeofMatterData(data: number) {
    this.currentTimeofMatterSubject.next(data);
  }

  setLastLoggedTimeOfMatterData(time: number) {
    this.lastLoggedTimeOfMatterSubject.next(time);
  }

  getLastLoggedTimeOfMatterData(): number {
    return this.lastLoggedTimeOfMatterSubject.getValue();
  }

  setTotaltimeOfDayData(data: number) {
    this.totaltimeOfDaySubject.next(data);
  }

  setTotalBillableAndNonBillableTimeofDay(time: number) {
    this.totalBillableAndNonBillableTimeOfDaySubject.next(time);
  }

  getTotalBillableAndNonBillableTimeOfDay(): number {
    return this.totalBillableAndNonBillableTimeOfDaySubject.getValue();
  }

  startTime() {
    if (!this.isTimerRunning) {
      this.isTimerRunning = true;
      this.setIntervalId = setInterval(() => {
        this.setcurrentTimeofMatterData(this.currentTimeofMatterSubject.getValue() + 1);
        this.setTotaltimeOfDayData(this.totaltimeOfDaySubject.getValue() + 1);
        this.channel.postMessage(this.currentTimeofMatterSubject.getValue());
      }, 1000);
    }
  }

  endTime() {
    this.isTimerRunning = false;
    this.channel.postMessage('');
    this.setcurrentTimeofMatterData(0);
    this.setLastLoggedTimeOfMatterData(0);
    clearInterval(this.setIntervalId);
  }

  endTimerGlobally() {
    try {
      this.isTimerRunning = false;
      this.channel.postMessage('');
      this.setcurrentTimeofMatterData(0);
      this.setLastLoggedTimeOfMatterData(0);
      this.setTotaltimeOfDayData(0);
      this.setTotalBillableAndNonBillableTimeofDay(0);
      this.setcurrentTimesheetDetailsData(null);
      clearInterval(this.setIntervalId);
    } catch (error) {
      console.error('Broadcast channel is not supported!');
    }
  }

  startTimer(startTimerReq: StartTimerRequest): Observable<TimerResponse> {
    return this._apiService.post<TimerResponse>(APIUrls.StartTimer, { ...startTimerReq });
  }

  updateTimer(updateTimerReq: UpdateTimerRequest): Observable<TimerResponse> {
    return this._apiService.post<TimerResponse>(APIUrls.UpdateTimerByTimesheetId, { ...updateTimerReq });
  }

  getTimerDetailsForUser(req: {
    userId: number;
    timesheetId: number;
    lastUpdateTime: string;
    systemDate: string;
  }): Observable<TimerResponse> {
    return this._apiService.post<TimerResponse>(APIUrls.GetUserTimerDetails, { ...req });
  }

  endTimer(stopTimerReq: StopTimerRequest): Observable<TimerResponse> {
    return this._apiService.post<TimerResponse>(APIUrls.StopTimerByTimesheetId, { ...stopTimerReq });
  }

  checkValidAllowedTime(
    validateNewTimeReq: ValidateNewStartTimeEntryRequest,
  ): Observable<ValidateNewStartTimeEntryResponse> {
    return this._apiService.post<ValidateNewStartTimeEntryResponse>(APIUrls.CheckValidAllowedTime, validateNewTimeReq);
  }

  addAutomaticTimeEntry(addAutomaticTimeEntryRequest: AddAutomaticTimeEntryRequest): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.AddAutomaticTimeEntry, { ...addAutomaticTimeEntryRequest });
  }
}
