import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { APIUrls } from '../enums/apiUrls';
import {
  GetBillOnCompletionListRequest,
  GetBillOnCompletionListResponse,
  SetBillOnCompletionStatusRequest,
  SetBillOnCompletionStatusResponse,
} from '../interfaces/Admin/Invoice/BillOnCompletionRequestResponse';
import { CityPlanInvoiceListResponse, EmailSentStatus } from '../interfaces/Admin/Invoice/CityPlanInvoiceListResponse';
import { EditInvoiceRequest, EditOndemandInvoiceRequest } from '../interfaces/Admin/Invoice/EditInvoiceRequest';
import { EnterprisePlanInvoiceListResponse } from '../interfaces/Admin/Invoice/EnterprisePlanInvoiceListResponse';
import { ExtendedEnterprisePlanInvoiceListResponse } from '../interfaces/Admin/Invoice/ExtendedEnterprisePlanInvoiceListResponse';
import { InvoiceDetailsByIdResposne } from '../interfaces/Admin/Invoice/InvoiceDetailsByIdResponse';
import { InvoiceListRequest } from '../interfaces/Admin/Invoice/InvoiceListRequest';
import {
  IsInvoicesReadyToBeRaisedForResponse,
  IsInvoicesReadyToBeRaisedRequest,
} from '../interfaces/Admin/Invoice/IsInvoicesReadyToBeRaisedRequestResponse';
import {
  OndemandInvoiceListRequest,
  OndemandTimesheetPreviewRequest,
  OndemandTimesheetRequest,
  SendMultipleOndemandTimesheetRequest,
} from '../interfaces/Admin/Invoice/OndemandInvoiceListRequest';
import { OndemandPlanInvoiceListResponse } from '../interfaces/Admin/Invoice/OndemandPlanInvoiceListResponse';
import { OndemandPlanTimesheetListResponse } from '../interfaces/Admin/Invoice/OndemandPlanTimesheetListResponse';
import { PaymentMethodListResponse } from '../interfaces/Admin/Invoice/PaymentMethodListResponse';
import { RaiseMultipleInvoicesByPlanTypeRequest } from '../interfaces/Admin/Invoice/RaiseMultipleInvoicesByPlanTypeRequest';
import { SendMultipleInvoicesByPlanTypeRequest } from '../interfaces/Admin/Invoice/SendMultipleInvoicesByPlanTypeRequest';
import { WIPTimeEntryTypesRequest } from '../interfaces/Admin/Invoice/WIPTimeEntryTypesRequest';
import { WIPTimeEntryTypesResponse } from '../interfaces/Admin/Invoice/WIPTimeEntryTypesResponse';
import { ClientListForMatterFilterResponse } from '../interfaces/Admin/Matter/ClientListForMatterFilterResponse';
import { SuccessResponse } from '../interfaces/Admin/SuccessResponse';
import { ClientInvoiceDataSource } from '../interfaces/Client/ClientDetails';
import { ApiService } from './api.service';

interface CityPlan {
  id: number;
  servicePlanName: string;
}
@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  private servicePlanSubject = new BehaviorSubject<CityPlan[]>([]);
  servicePlanObservable: Observable<CityPlan[]> = this.servicePlanSubject.asObservable();

  private clientInvoicesSubject = new BehaviorSubject<ClientInvoiceDataSource | null>(null);
  clientInvoicesObservable: Observable<ClientInvoiceDataSource | null> = this.clientInvoicesSubject.asObservable();

  constructor(private _apiService: ApiService) {}

  getEmailStatus(): EmailSentStatus[] {
    return [
      {
        id: 0,
        status: 'Unsent',
      },
      {
        id: 1,
        status: 'Sent',
      },
    ];
  }

  setServicePlanData(data: { id: number; servicePlanName: string }[]) {
    this.servicePlanSubject.next(data);
  }

  setClientInvoices(data: ClientInvoiceDataSource) {
    data && this.clientInvoicesSubject.next(data);
  }

  getServicePlanId(cityPlan: string) {
    let temp: CityPlan | undefined;
    this.servicePlanObservable.subscribe((res) => {
      temp = res.find((row) => row.servicePlanName === cityPlan);
    });
    if (temp) return temp.id;
    else return -1;
  }

  getWIPTimeEntryTypes(req: WIPTimeEntryTypesRequest): Observable<WIPTimeEntryTypesResponse> {
    return this._apiService.post<WIPTimeEntryTypesResponse>(APIUrls.GetWIPTimesheetEntries, { ...req });
  }

  updateWIPEntriesByTimesheetId(
    checkedTimesheetIds: number[],
    uncheckedTimesheetIds: number[],
  ): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.UpdateWIPEntriesByTimesheetId, {
      checkedTimesheetIds,
      uncheckedTimesheetIds,
    });
  }

  raiseInvoiceByPlanType(req: RaiseMultipleInvoicesByPlanTypeRequest): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.RaiseMultipleInvoicesByPlanType, { ...req });
  }

  sendMultipleInvoices(req: SendMultipleInvoicesByPlanTypeRequest): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.SendMultipleInvoices, { ...req });
  }

  getPaymentMethodList(): Observable<PaymentMethodListResponse> {
    return this._apiService.get<PaymentMethodListResponse>(APIUrls.GetPaymentmethodList);
  }

  resendInvoice(id: number): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(`${APIUrls.ResendInvoice}/${id}`, {});
  }

  getParentClientList(): Observable<ClientListForMatterFilterResponse> {
    return this._apiService.get<ClientListForMatterFilterResponse>(APIUrls.GetParentClientList);
  }

  //city invoice
  getCityPlanInvoiceList(req: InvoiceListRequest): Observable<CityPlanInvoiceListResponse> {
    return this._apiService.post<CityPlanInvoiceListResponse>(APIUrls.GetCitytPlanInvoiceListByType, { ...req });
  }

  getInvoiceDetailsById(id: number): Observable<InvoiceDetailsByIdResposne> {
    return this._apiService.post<InvoiceDetailsByIdResposne>(`${APIUrls.GetInvoiceDetailsById}/${id}`, {});
  }

  editInvoice(req: EditInvoiceRequest): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.UpdateInvoiceDetails, { ...req });
  }

  //enterprise
  getEnterprisePlanInvoiceList(req: InvoiceListRequest): Observable<EnterprisePlanInvoiceListResponse> {
    return this._apiService.post<EnterprisePlanInvoiceListResponse>(APIUrls.GetEnterprisePlanInvoiceListByType, {
      ...req,
    });
  }

  //extended enterprise
  getExtendedEnterprisePlanInvoiceList(req: InvoiceListRequest): Observable<ExtendedEnterprisePlanInvoiceListResponse> {
    return this._apiService.post<ExtendedEnterprisePlanInvoiceListResponse>(
      APIUrls.GetExtendedEnterprisePlanInvoceListByType,
      { ...req },
    );
  }

  //download Invoice
  getInvoicePdf(invoiceId: number) {
    return this._apiService.post(
      APIUrls.DownloadPdfForInvoiceAndTimesheetReport,
      { invoiceId },
      { responseType: 'arraybuffer' },
    );
  }

  //preview Invoice
  previewInvoicePdf(invoiceId: number) {
    return this._apiService.get(
      APIUrls.GetInvoicePreview,
      { planInvoiceId: invoiceId },
      { responseType: 'arraybuffer' },
    );
  }

  //ondemand
  getOndemandPlanInvoiceList(req: OndemandInvoiceListRequest): Observable<OndemandPlanInvoiceListResponse> {
    return this._apiService.post<OndemandPlanInvoiceListResponse>(APIUrls.GetOnDemandPlanInvoiceList, { ...req });
  }

  editOndemandInvoice(req: EditOndemandInvoiceRequest): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.UpdateOnDemandInvoiceDetails, { ...req });
  }

  //ondemand timesheet
  getOndemandPlanTimesheetList(req: OndemandTimesheetRequest): Observable<OndemandPlanTimesheetListResponse> {
    return this._apiService.post<OndemandPlanTimesheetListResponse>(APIUrls.GetOnDemandTimesheetList, { ...req });
  }

  previewOndemandTimesheetPdf(req: OndemandTimesheetPreviewRequest) {
    return this._apiService.post(APIUrls.GetTimesheetPreview, { ...req }, { responseType: 'arraybuffer' });
  }

  sendMultipleTimesheet(req: SendMultipleOndemandTimesheetRequest): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.SendMultipleTimesheets, { ...req });
  }

  downloadOndemandTimesheetPdf(req: OndemandTimesheetPreviewRequest) {
    return this._apiService.post(APIUrls.DownloadOnDemandTimesheet, { ...req }, { responseType: 'arraybuffer' });
  }

  resendOndemandTimesheet(req: OndemandTimesheetPreviewRequest): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.ResendOnDemandTimesheet, { ...req });
  }

  checkIfInvoicesAreReadyToBeSent(
    req: IsInvoicesReadyToBeRaisedRequest,
  ): Observable<IsInvoicesReadyToBeRaisedForResponse> {
    return this._apiService.post<IsInvoicesReadyToBeRaisedForResponse>(APIUrls.CheckInvoiceRestriction, req);
  }

  getBillOnCompletionList(req: GetBillOnCompletionListRequest): Observable<GetBillOnCompletionListResponse> {
    return this._apiService.post<GetBillOnCompletionListResponse>(APIUrls.GetBillOnCompletionList, req);
  }

  setBillOnCompletionStatus(req: SetBillOnCompletionStatusRequest): Observable<SetBillOnCompletionStatusResponse> {
    return this._apiService.post<SetBillOnCompletionStatusResponse>(APIUrls.SetBillOnCompletionStatus, req);
  }
}
