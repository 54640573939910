<div>
    <div class="modal-header" [ngClass]="{'manual-header': headerTitle}">
        <h4 class="modal-title" id="myModalLabel">{{headerTitle}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <svg-icon name="modal-close-icon" class="icon"></svg-icon>
        </button>
    </div>
    <div class="modal-body">
        <div class="confirm-message">
            <div class="confirm-message-content">{{ subjectLine }}</div>
        </div>
        <div class="btns-wrap">
            <button class="btn btn-md" (click)="onDeleteRecordApproved()">Yes</button>
            <button class="btn btn-outline" (click)="activeModal.dismiss()">Cancel</button>
        </div>
    </div>
</div>