<div class="auth-wrap">
  <div class="auth-wrap-left">
    <div class="logo">
      <img src="../../../../assets/images/HarperJames-Logo.svg" alt="" />
    </div>
    <div class="auth-wrap-left-content">
      <h1>Let’s get going!</h1>
      <img src="../../../../assets/images/login-set.png" alt="" />
    </div>
  </div>
  <div class="auth-wrap-right">
    <div class="auth-wrap-right-content">
      <div class="mail-lock-icon">
        <img src="../../../../assets/images/mail-lock-icon.svg" alt="" />
      </div>
      <h1 class="margin-0">Verify your email</h1>
      <h3>We've sent a verification code to your email address {{ currentUserEmailId }}</h3>
      <div class="auth-form">
        <form [formGroup]="otpForm">
          <div class="otp-input-wrapper">
            <div class="form-field form-field-otp" *ngFor="let input of formInput; index as i">
              <input
                #formRow
                id="otp"
                type="text"
                [formControlName]="input"
                [maxlength]="otpCharacterLimit"
                (keyup)="keyUpEvent($event, i)"
                onfocus="this.select()"
                (input)="onInputChanged($event, input, i)"
              />
            </div>
          </div>
          <div class="otp-error-message" *ngIf="OTPverificationFailed">{{ errorMessage }}</div>
          <button class="btn btn-lg" [disabled]="isVerifyButtonClicked" (click)="onSubmit()">Verify</button>
          <div [ngClass]="isNewOPTAlreadySent ? 'resend-otp disable' : 'resend-otp'">
            Haven’t received the emailed code?
            <div class="waiting-time-with-resend-option">
              <p *ngIf="isNewOPTAlreadySent">
                You can request a new code in <span class="text-info"> {{ timeLeft }} seconds: </span>
              </p>
              <a (click)="resendCode()">Resend a new code</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- *********************** 2FA Varification modal *********************** -->
<ng-template #faverification let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel">
      Do you want to set up a password in addition to the verification code?
    </h4>
  </div>
  <form [formGroup]="faForm">
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-sm-12 mb-2">
          <div class="form-checkbox form-checkbox--with-label">
            <input
              id="askMeNextTime"
              type="radio"
              value="3"
              [attr.name]="'faRadio'"
              formControlName="faValue"
              class="input-checkbox"
            />
            <label for="askMeNextTime" class="form-checkbox-lbl">Ask me next time</label>
          </div>
        </div>
        <div class="col-sm-12 mb-2">
          <div class="form-checkbox form-checkbox--with-label">
            <input
              id="authYes"
              type="radio"
              value="1"
              [attr.name]="'faRadio'"
              formControlName="faValue"
              class="input-checkbox"
            />
            <label for="authYes" class="form-checkbox-lbl">Yes</label>
          </div>
        </div>
        <div class="col-sm-12 mb-2">
          <div class="form-checkbox form-checkbox--with-label">
            <input
              id="authNo"
              type="radio"
              value="2"
              [attr.name]="'faRadio'"
              formControlName="faValue"
              class="input-checkbox"
            />
            <label for="authNo" class="form-checkbox-lbl">Never</label>
          </div>
        </div>
      </div>
      <div class="successfully-foot-btn">
        <button class="btn btn-block btn-md" data-dismiss="modal" (click)="confirmFA()">Proceed</button>
      </div>
    </div>
  </form>
</ng-template>

<div class="loader-animation" *ngIf="isLoading">
  <svg-icon name="loader-animation" style="color: #8f9bba" class="input-icon"></svg-icon>
</div>
