<div class="modal-header">
  <button type="button" class="close" (click)="closeModal.emit()" aria-label="Close">
    <svg-icon name="modal-close-icon" class="icon"></svg-icon>
  </button>
  <h4 class="modal-title" id="myModalLabel">{{ headerDate }}</h4>
</div>
<div class="modal-body">
  <div class="row justify-content-center">
    <div class="col-sm-9">
      <h6 class="text-center">It's a new day. Timer stopped. Let's start again.</h6>
      <p>Get in to your productive zone again.</p>
      <button type="button" (click)="openHourLogModal.emit()" class="btn">Open Timer</button>
    </div>
  </div>
</div>
