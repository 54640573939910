export enum ValidationMessages {
  maxLength2000ForDescription = 'Description must not exceed 2000 characters',
  maxLength150ForDescription = 'Description must not exceed 150 characters',
  allowedCharactersForDescription = 'Invalid Description',
  descriptionRequired = 'Description cannot be blank',
  maxLength2000ForNotes = 'Notes must not exceed 2000 characters',
  maxLength500ForAddress = 'Address must not exceed 500 characters',
  featureIDRequired = 'Feature cannot be blank',
  passwordValidationMessage = 'Password must be at least 8 characters long containing a number, a lower case letter, an uppercase letter and special characters like !, @, #, $, ., %, ^, &, *.',
  maxLength250ForTitle = 'Title must not exceed 250 characters',
  titleRequired = 'Title cannot be blank',
  allowedCharactersForTitle = 'Invalid Title',
  maxLength500ForDescription = 'Description must not exceed 500 characters',
  timeSheetTimeInvalidFormat = 'Please enter time in 01:30 hh:mm or 01.30 decimal hours format',
  timeSheetTimePlaceholder = 'e.g 05:30 or 5.5 hours',
  timeSheetTimeInvalid = 'Please enter valid time',
  timeContinuingBookingSameDetails = 'Time - Continuing booking (Same details)',
  timeNewBookingDetailsChanged = 'Time - New booking (Details changed)',
  timeNewBooking = 'Time - New booking',
  timerStoppedTimeSheetSaved = 'Timer stopped and saved to Timesheet',
  timeSheetUpdatedSuccessfully = 'Successfully updated timesheet',
  timeSheetLoggedSuccessfully = 'Time Entry Logged Successfully',
  invalidEmbedLinkForBusinessReporting = 'Please provide valid Embed link',
  workRequestNumberOfFilesUploadExeeds = 'Number of documents attached cannot be exceeding 25',
  workRequestFileSizeExeeds = 'File size cannot be exceeding 25MB',
  roleTypeRequired = 'Role Type cannot be blank',
  roleNameAlredyExist = 'Role Name exists',
  forgotPasswordResetTwoFAValidation = 'Password cannot be reset as you have not enabled login with password',
}

export enum InformationMessages {
  resendInvoiceSuccess = 'Invoice Sent Successfully',
  resendInvoiceSuccessSubText = 'We’ve notified the users on their email address',
  resendInvoiceFailure = 'The sending of email failed',
  resendInvocieFailureSubText = 'Please configure email address in client contact and resend',
}
