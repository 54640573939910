import { Injectable } from '@angular/core';
import * as SignalR from '@microsoft/signalr';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class HubConnectionService {
  connectionBuilder: SignalR.HubConnectionBuilder;
  connection: SignalR.HubConnection;

  constructor(private _authService: AuthService) {
    this.connectionBuilder = new SignalR.HubConnectionBuilder();
  }

  setupHubConnection() {
    const token: string = this._authService.get('accessToken') ?? '';
    if (token && !this.connection?.connectionId) {
      this.connection = this.connectionBuilder
        .configureLogging(SignalR.LogLevel.Information)
        .withUrl(this._authService.hubUrl, { accessTokenFactory: () => token })
        .build();

      this.connection.start().catch((err) => {
        return console.error(err.toString());
      });
    }
  }
}
