import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { APIUrls } from '../enums/apiUrls';
import { SuccessResponse } from '../interfaces/Admin/SuccessResponse';
import { AddUpdateWorkRequestResponse } from '../interfaces/Admin/WorkRequest/AddUpdateWorkRequest';
import { ClientDetails } from '../interfaces/Client/ClientDetails';
import { ClientsListResponse } from '../interfaces/Client/ClientsListResponse';
import { WorkRequestDetailsByIdResponse } from '../interfaces/Client/WorkRequestDetailsByIdResponse';
import { InProgressDocumentUploadResponse } from '../interfaces/Client/WorkRequestDocumentUploadInProgress';
import { WorkRequestListRequest } from '../interfaces/Client/WorkRequestListRequest';
import { WorkRequestListResponse } from '../interfaces/Client/WorkRequestListResponse';
import { WorkRequestStatusResponse } from '../interfaces/Client/WorkRequestStatusResponse';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ClientModuleService {
  private clientSubject = new BehaviorSubject<ClientDetails>({
    clientId: 0,
    clientName: '',
    servicePlanId: 0,
    servicePlanName: '',
  });
  clientObservable: Observable<ClientDetails> = this.clientSubject.asObservable();
  private ProfilePictureSubject = new BehaviorSubject<string>('');
  profilePictureObservable: Observable<string> = this.ProfilePictureSubject.asObservable();

  setClientId(client: ClientDetails) {
    this.clientSubject.next(client);
  }

  setClientProfilePicture(profileURL: string) {
    this.ProfilePictureSubject.next(profileURL);
  }

  constructor(private _apiService: ApiService) {}

  getClientList(email: string): Observable<ClientsListResponse> {
    return this._apiService.get(APIUrls.GetListOfClients, { emailId: email });
  }

  addUpdateWorkRequest(formData: FormData): Observable<AddUpdateWorkRequestResponse> {
    return this._apiService.post(APIUrls.AddUpdateWorkRequest, formData);
  }

  uploadWorkRequestDocuments(formData: FormData): Observable<SuccessResponse> {
    return this._apiService.post(APIUrls.UploadWorkRequestDocuments, formData);
  }

  getWorkRequestListStatus(): Observable<WorkRequestStatusResponse> {
    return this._apiService.get(APIUrls.GetWorkRequestStatus);
  }

  getWorkRequestList(workRequestListReq: WorkRequestListRequest): Observable<WorkRequestListResponse> {
    return this._apiService.post(APIUrls.GetWorkRequestList, { ...workRequestListReq });
  }

  getWorkRequestDetailsById(id: number): Observable<WorkRequestDetailsByIdResponse> {
    return this._apiService.get(`${APIUrls.GetWorkRequestDetailsById}/${id}`);
  }

  getUploadPendingDocumentWorkRequestList(): Observable<InProgressDocumentUploadResponse> {
    return this._apiService.get(APIUrls.CheckIsWorkRequestDocumentUploaded);
  }

  //download work
  downloadWorkRequestDocumentById(workRequestId: number, isFromWorkRequestList: boolean) {
    return this._apiService.post(
      APIUrls.DownloadWorkRequestDocumentById,
      { workRequestId, isFromWorkRequestList },
      { responseType: 'blob' },
    );
  }
}
