import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) { }

  transform(url: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.domSanitizer.sanitize(SecurityContext.URL, url) ?? '');
  }

}
