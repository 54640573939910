import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-documents-upload-in-progress',
  templateUrl: './documents-upload-in-progress.component.html',
  styleUrls: ['./documents-upload-in-progress.component.scss'],
})
export class DocumentsUploadInProgressComponent {
  @Input() headerTitle: string = 'Work request added/updated successfully';

  constructor(public activeModal: NgbActiveModal) {}
}
