<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel">Availability for new work</h4>
</div>
<div class="modal-body">
  <div class="form-field form-field-select">
    <label for="availabilitySelection">Availability Today</label>
    <ng-select
      labelForId="availabilitySelection"
      [multiple]="false"
      [closeOnSelect]="true"
      [searchable]="false"
      placeholder="Select your Availability"
      [(ngModel)]="selectedAvailability"
    >
      <ng-option *ngFor="let status of availabilityStatusList" [value]="status.id">
        <span
          class="status-dot"
          [ngClass]="{
            green: status.id === 1,
            red: status.id === 2,
            yellow: status.id === 3
          }"
        ></span>
        {{ status.status }}</ng-option
      >
    </ng-select>
  </div>
  <div class="foot-btn">
    <button
      class="btn btn-md"
      data-dismiss="modal"
      (click)="editAvailabilityStatus()"
      [disabled]="!selectedAvailability"
    >
      Submit
    </button>
  </div>
</div>
