import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, DestroyRef, EventEmitter, Input, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import saveAs from 'file-saver';
import * as _ from 'lodash';
import { DefaultGlobalConfig, GlobalConfig, ToastrService } from 'ngx-toastr';
import { take } from 'rxjs';
import { ClientListForMatterFilter as ClientListWorkRequest } from 'src/app/shared/interfaces/Admin/Matter/ClientListForMatterFilterResponse';
import { Config } from '../../constants/constants';
import { ValidationMessages } from '../../enums/messages';
import { HeadLinePracticeArea } from '../../interfaces/Admin/Role/HeadLinePracticeAreaNyRoleIdRespomse';
import {
  SubPracticeArea,
  SubPracticeAreaByHeadlineIdResponse,
} from '../../interfaces/Admin/Role/SubPracticeAreaByHeadlineIdResponse';
import { AddUpdateWorkRequestResponse } from '../../interfaces/Admin/WorkRequest/AddUpdateWorkRequest';
import {
  ProjectDocumentMappingDetails,
  WorkRequest,
  WorkRequestDetailsByIdResponse,
} from '../../interfaces/Client/WorkRequestDetailsByIdResponse';
import { WorkRequestStatus } from '../../interfaces/Client/WorkRequestStatusResponse';
import { AuthService, RoleService } from '../../services';
import { ClientModuleService } from '../../services/client-module.service';
import { TimesheetService } from '../../services/timesheet.service';
import { WorkRequestService } from '../../services/work-request.service';
import { descriptionValidator } from '../../validators/description.validator';
import { DocumentsUploadInProgressComponent } from '../documents-upload-in-progress/documents-upload-in-progress.component';

@Component({
  selector: 'app-work-request-modal',
  templateUrl: './add-update-work-request-modal.component.html',
  styleUrls: ['./add-update-work-request-modal.component.scss'],
})
export class AddUpdateWorkRequestModalComponent implements AfterViewInit {
  @Input() isEdit: boolean;
  @Input() workRequestId: number;
  @Input() isAdmin: boolean;
  @Input() headLinePracticeArea: HeadLinePracticeArea[] = [];
  @Input() clientListWorkRequest: ClientListWorkRequest[] = [];
  @Input() statusDataSource: WorkRequestStatus[] = [];
  @Output() closeModal = new EventEmitter<void>();
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() getWorkRequestListSaveAndUpdate = new EventEmitter<void>();

  workRequestForm: UntypedFormGroup;
  validationMessages = ValidationMessages;
  config = Config;

  todayDate: Date = new Date();
  selectedClientName = '';
  workRequestHPA: number | null;
  workRequestSPA: number[];
  workRequestStatus: number | null;
  subPracticeArea: SubPracticeArea[];
  workRequestFiles: File[] = [];
  currentUserFullName: string;
  currentUserEmail: string;
  selectedClient: number;

  documentUploadInProgressEditWorkRequest = false;
  editWorkRequestFilesReceivedInitially: ProjectDocumentMappingDetails[] = [];
  editWorkRequestFilesReceived: ProjectDocumentMappingDetails[] = [];
  deletedFileIds: number[] = [];

  private destroyRef = inject(DestroyRef);

  constructor(
    private _clientModuleService: ClientModuleService,
    private _roleService: RoleService,
    private _toastrService: ToastrService,
    private _authService: AuthService,
    private _timesheetService: TimesheetService,
    private _modalService: NgbModal,
    private _workRequestService: WorkRequestService,
  ) {
    const userInfo = this._authService.getUserInfo();
    if (userInfo) {
      this.currentUserEmail = userInfo.emailId;
      this.currentUserFullName = userInfo.fullName;
    } else {
      this._authService.logout();
    }

    this.workRequestForm = new UntypedFormGroup({
      id: new UntypedFormControl(0),
      RequestNo: new UntypedFormControl(''),
      title: new UntypedFormControl('', [Validators.required, descriptionValidator(Config.maxLength250)]),
      description: new UntypedFormControl('', [Validators.required, descriptionValidator(Config.maxLength500)]),
      fromDate: new UntypedFormControl('', [Validators.required]),
      toDate: new UntypedFormControl('', [Validators.required]),
      name: new UntypedFormControl(this.currentUserFullName, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      jobTitle: new UntypedFormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      email: new UntypedFormControl(this.currentUserEmail, [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
      ]),
      phone: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
    });
    this.workRequestForm.controls.name.disable();
    this.workRequestForm.controls.email.disable();
  }

  ngAfterViewInit() {
    if (this.isAdmin) {
      this.workRequestForm.controls.name.enable();
      this.workRequestForm.controls.email.enable();
    } else {
      this.subscribeSelectedClient();
    }
    if (this.isEdit) {
      this.isLoading.emit(true);
      this._clientModuleService.getWorkRequestDetailsById(this.workRequestId).subscribe({
        next: this.handleWorkRequestDetailsByIdSuccess.bind(this),
        error: () => {
          this.closeModal.emit();
          this.isLoading.emit(false);
        },
      });
    }
  }

  handleWorkRequestDetailsByIdSuccess(res: WorkRequestDetailsByIdResponse) {
    const formData = res.result.workRequest;
    if (this.isAdmin) {
      this.selectedClient = formData.clientId;
    }
    this.documentUploadInProgressEditWorkRequest = formData.isUploadToBlob ?? false;
    this.patchFormValues(formData);
    if (formData.headlinePracticeAreaId) {
      this.workRequestHPA = formData.headlinePracticeAreaId;
      this.workRequestSPA = formData.subPracticeAreaId.split(',').map((spa) => {
        return parseInt(spa);
      });
      this.workRequestStatus = formData.workRequestStatusId;
      this.getSubPracticeAreaByHeadLineId([this.workRequestHPA]).then(() => {
        if (res.result.projectDocumentMappingDetails?.length) {
          this.editWorkRequestFilesReceived = res.result.projectDocumentMappingDetails || [];
          this.editWorkRequestFilesReceivedInitially = res.result.projectDocumentMappingDetails || [];
        }
        if (this.isAdmin) {
          const controls = this.findInvalidControls();
          if (controls.length) {
            controls.forEach((formControl) => {
              if (this.workRequestForm.get(formControl)?.hasError('required')) {
                this.markControlDirty(formControl);
                this.workRequestForm.get(formControl)?.setValue('');
              } else if (this.workRequestForm.get(formControl)?.hasError('pattern')) {
                this.markControlDirty(formControl);
              }
            });
          }
        }
      });
    }
    this.isLoading.emit(false);
  }

  patchFormValues(formData: WorkRequest) {
    this.workRequestForm.patchValue({
      id: this.workRequestId,
      RequestNo: formData.requestNo,
      title: formData.subject,
      description: formData.description,
      fromDate: formData.requestedStartDate,
      toDate: formData.requestedFinishDate,
      name: formData.name,
      jobTitle: formData.jobTitle,
      email: formData.email,
      phone: formData.phoneNumber,
    });
  }

  subscribeSelectedClient() {
    this._clientModuleService.clientObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (client: { clientId: number; clientName: string }) => {
        this.selectedClient = client.clientId;
        this.selectedClientName = client.clientName;
      },
      error: () => {
        this.isLoading.emit(false);
      },
    });
  }

  markControlDirty(controlName: string) {
    this.workRequestForm.get(controlName)?.markAsTouched();
    this.workRequestForm.get(controlName)?.markAsDirty();
  }

  findInvalidControls() {
    const invalid = [];
    const controls = this.workRequestForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  workRequestHPASelected() {
    this.workRequestSPA = [];
    if (this.workRequestHPA) {
      this.getSubPracticeAreaByHeadLineId([this.workRequestHPA]);
    } else {
      this.subPracticeArea = [];
    }
  }

  getSubPracticeAreaByHeadLineId(ids: number[]): Promise<void> {
    return new Promise((resolve) => {
      this._roleService.getSubPracticeAreaByHPAId(ids).subscribe({
        next: (res: SubPracticeAreaByHeadlineIdResponse) => {
          this.subPracticeArea = _.flatten(
            res.result.map((item) => {
              return item.subPracticeAreaResponseModel;
            }),
          );
          resolve();
        },
        error: () => resolve(),
      });
    });
  }

  removeFile(file: File) {
    const tempFileIndex = this.workRequestFiles.findIndex((obj) => {
      return obj.name == file.name;
    });
    if (tempFileIndex != -1) {
      this.workRequestFiles.splice(tempFileIndex, 1);
    }
  }

  removeFileFromEditReceived(id: number) {
    const tempFileIndex = this.editWorkRequestFilesReceived.findIndex((obj) => {
      return obj.documentId == id;
    });
    if (tempFileIndex != -1) {
      this.deletedFileIds.push(id);
      this.editWorkRequestFilesReceived.splice(tempFileIndex, 1);
    }
  }

  onFileChange(event: Event) {
    const element = (event.target as HTMLInputElement).files;
    if (!element?.length) {
      return;
    }
    if (this.checkIfFileSelectionLimitExceeds(element.length)) {
      this.isLoading.emit(false);
      this.showErrorMessage('Number of documents attached cannot be exceeding 25');
      return;
    }
    const receivedFilesLength: number = this.editWorkRequestFilesReceived?.length || 0;

    if (this.checkIfFileSelectionLimitExceeds(receivedFilesLength + this.workRequestFiles.length, true)) {
      this.isLoading.emit(false);
      this.showErrorMessage('Number of documents attached cannot be exceeding 25');
      return;
    }

    Array.from(element).forEach((file) => {
      if (!this.checkFileExtensions(file.name.toLowerCase())) {
        this.isLoading.emit(false);
        this.showErrorMessage(`Document format ${file.name.substring(file.name.lastIndexOf('.'))} is not supported`);
      } else if (this.checkIfFileSizeExceeds(file.size)) {
        this.isLoading.emit(false);
        this.showErrorMessage('File size cannot be exceeding 25MB');
      } else {
        let temp: number;
        if (this.isEdit) {
          temp = this.editWorkRequestFilesReceived?.findIndex(
            (editWorkRequestFile) => editWorkRequestFile.documentName === file.name,
          );
        } else {
          temp = this.workRequestFiles?.findIndex((workRequestFile) => workRequestFile.name === file.name);
        }
        temp === -1 && this.workRequestFiles.push(file);
      }
    });
  }

  downloadDocument(id: number) {
    this.isLoading.emit(true);
    const mediaType = 'application/zip';

    const downloadSubscription = (downloadFunction: any) => {
      downloadFunction(id, true).subscribe({
        next: (response: Blob) => {
          const blob = new Blob([response], { type: mediaType });
          saveAs(blob);
          this.isLoading.emit(false);
        },
        error: () => {
          this.isLoading.emit(false);
        },
      });
    };

    if (this.isAdmin) {
      downloadSubscription(
        this._workRequestService.downloadWorkRequestDocumentByIdForAdmin.bind(this._workRequestService),
      );
    } else {
      downloadSubscription(this._clientModuleService.downloadWorkRequestDocumentById.bind(this._clientModuleService));
    }
  }

  checkIfFileSizeExceeds(fileSize: number) {
    return fileSize > 25 * 2 ** 20;
  }

  checkFileExtensions(fileName: string) {
    return Config.fileUploadFormats.split(',').includes(fileName.substring(fileName.lastIndexOf('.')));
  }

  checkIfFileSelectionLimitExceeds(length: number, checkForEqual: boolean = false) {
    if (checkForEqual) {
      return length >= 25;
    }
    return length > 25;
  }

  showErrorMessage(message: string) {
    const globalConfig: GlobalConfig = DefaultGlobalConfig;
    globalConfig.preventDuplicates = true;
    this._toastrService.toastrConfig = globalConfig;
    this._toastrService.error(message);
  }

  disableDate() {
    return false;
  }

  addUpdateWorkRequest() {
    if (this.workRequestForm.valid && this.workRequestHPA && this.workRequestSPA.length) {
      this.isLoading.emit(true);
      const formData: FormData = new FormData();
      formData.append('Id', this.isEdit ? this.workRequestForm.controls?.id?.value : '0');
      formData.append('RequestNo', this.isEdit ? this.workRequestForm.controls?.RequestNo?.value : '');
      formData.append('Subject', this.workRequestForm.controls.title.value);
      formData.append('HeadlinePracticeAreaId', this.workRequestHPA.toString());
      if (this.workRequestSPA.length) {
        this.workRequestSPA.forEach((_spa, index) => {
          formData.append('SubPracticeAreaId', this.workRequestSPA[index].toString());
        });
      }
      formData.append(
        'RequestedStartDate',
        this._timesheetService.formatDate(this.workRequestForm.controls.fromDate.value),
      );
      formData.append(
        'RequestedFinishDate',
        this._timesheetService.formatDate(this.workRequestForm.controls.toDate.value),
      );
      formData.append('ClientId', this.selectedClient.toString());
      formData.append(
        'WorkRequestStatusId',
        this.isAdmin && this.isEdit ? this.workRequestStatus?.toString() ?? '1' : '1',
      );
      formData.append('Description', this.workRequestForm.controls.description.value);
      formData.append('ContactPersonName', this.workRequestForm.controls.name.value);
      formData.append('ContactPersonEmail', this.workRequestForm.controls.email.value);
      formData.append('ContactPersonPhoneNumber', this.workRequestForm.controls.phone.value);
      formData.append('ContactPersonJobTitle', this.workRequestForm.controls.jobTitle.value);
      formData.append('HasAnyDocumentsToUpload', this.workRequestFiles?.length ? true.toString() : false.toString());
      this.onAddUpdateWorkRequest(formData).subscribe({
        next: this.handleAddWorkRequestSuccess.bind(this),
        error: this.handleAddEditWorkRequestError.bind(this),
      });
    }
  }

  onAddUpdateWorkRequest(formData: FormData) {
    return this.isAdmin
      ? this._workRequestService.addUpdateWorkRequestByAdmin(formData)
      : this._clientModuleService.addUpdateWorkRequest(formData);
  }

  handleAddEditWorkRequestError(error: HttpErrorResponse) {
    if (error.error.message) this._toastrService.error(error.error.message);
    this.isLoading.emit(false);
  }

  handleAddWorkRequestSuccess(result: AddUpdateWorkRequestResponse) {
    (this.workRequestFiles?.length || this.deletedFileIds.length) &&
      this.uploadDocuments({
        id: result.result?.['id'],
        subject: this.workRequestForm.controls.title.value,
        clientID: this.selectedClient || 0,
        contactPersonEmail: this.workRequestForm.controls.email.value,
      });
    this.isLoading.emit(false);
    if (this.workRequestFiles.length) {
      this.showDocumentUploadInProgressModal();
    } else {
      this._toastrService.success(result.message);
    }
    this.isLoading.emit(false);
    this.closeModal.emit();
    this.getWorkRequestListSaveAndUpdate.emit();
  }

  showDocumentUploadInProgressModal(isWorkRequestEdit: boolean = false) {
    const modalRef = this._modalService.open(DocumentsUploadInProgressComponent, {
      windowClass: 'd-block modal instruction-modal documents-upload-in-progress-modal fade show',
      centered: true,
    });
    modalRef.componentInstance.headerTitle = isWorkRequestEdit
      ? 'Work request updated successfully'
      : 'Work request added successfully';
  }

  uploadDocuments(data: { id: number; subject: string; clientID: number; contactPersonEmail: string }) {
    const formData: FormData = new FormData();
    if (this.isEdit) {
      const fileNames: string[] = this.editWorkRequestFilesReceivedInitially?.map((file) => {
        return file.documentName;
      });
      this.workRequestFiles.forEach((workRequestFile) => {
        if (!fileNames.includes(workRequestFile.name)) {
          formData.append('workRequestDocuments', workRequestFile);
        }
      });
      if (this.deletedFileIds.length) {
        this.deletedFileIds.forEach((deletedFileId) => formData.append('DeletedDocumentIds', deletedFileId.toString()));
      }
    } else {
      this.workRequestFiles.forEach((workRequestFile) => formData.append('workRequestDocuments', workRequestFile));
    }
    formData.append('Subject', data.subject);
    formData.append('ClientId', data.clientID.toString());
    formData.append('ContactPersonEmail', data.contactPersonEmail);
    formData.append('IsUpdateWorkRequest', this.isEdit.toString());
    formData.append('Id', data.id.toString());
    this.isAdmin
      ? this._workRequestService.uploadWorkRequestDocumentsByAdmin(formData).pipe(take(1)).subscribe()
      : this._clientModuleService.uploadWorkRequestDocuments(formData).pipe(take(1)).subscribe();
  }
}
