import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) { }

  transform(element: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(this.domSanitizer.sanitize(SecurityContext.HTML, element) ?? '');
  }

}
