import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DefaultGlobalConfig, ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Config } from 'src/app/shared/constants/constants';
import { UserDetailsResponse } from 'src/app/shared/interfaces/Auth/UserDetails';
import { ValidationMessages } from '../../enums/messages';
import { AuthService, LoginService } from '../../services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: UntypedFormGroup = new UntypedFormGroup({
    emailControl: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(Config.validEmailRegex),
    ]),
  });

  isForgotPasswordClicked: boolean = false;
  isClient: boolean | string | null = false;
  is2FAEnabledForClient: boolean = true;
  isClientExist: boolean = true;
  canProceed: boolean = false;
  clientDoesNotExistErrorMsg: string = '';
  msgIf2FANotEnabled: string = ValidationMessages.forgotPasswordResetTwoFAValidation;
  emailRegex: RegExp = new RegExp(Config.validEmailRegex);
  emailFieldUpdate = new Subject<string>();

  private destroyRef = inject(DestroyRef);

  constructor(
    private _loginService: LoginService,
    private router: Router,
    private _authService: AuthService,
    private toastrService: ToastrService,
  ) {
    const currentUser = this._authService.get('encryptedValue');
    this.isClient = this._authService.get('isClient') ?? this._authService.checkForClientUser();
    if (currentUser) {
      this.router.navigate(this.isClient ? ['client'] : ['admin'], { replaceUrl: true });
    }
  }

  ngOnInit(): void {
    if (this.isClient) {
      this.subscribeEmailUpdateValueChange();
    } else {
      this.canProceed = true;
    }
  }

  subscribeEmailUpdateValueChange() {
    this.emailFieldUpdate
      .pipe(debounceTime(1000), distinctUntilChanged())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.canProceed = false;
        if (this.emailRegex.test(value)) {
          this.verifyEmail(value);
        } else {
          this.is2FAEnabledForClient = true;
        }
      });
  }

  verifyEmail(email: string) {
    this._loginService.getUserByEmailID(email).subscribe({
      next: (res: UserDetailsResponse) => {
        if (res.result) {
          this.is2FAEnabledForClient = res.result.isTwoFactorEnabled;
          this.isClientExist = true;
          this.canProceed = true;
        }
      },
      error: (error: HttpErrorResponse) => {
        this.is2FAEnabledForClient = true;
        if (error.error.statusCode === 400) {
          this.isClientExist = false;
          this.clientDoesNotExistErrorMsg = error.error.message;
        } else {
          this.isClientExist = true;
        }
        this.canProceed = false;
      },
    });
  }

  forgotPassword() {
    this.isForgotPasswordClicked = true;
    if (!this.forgotPasswordForm.valid) {
      this.isForgotPasswordClicked = false;
      this.validateEmail();
      return;
    }
    this._loginService.forgotPassword(this.forgotPasswordForm.controls.emailControl.value).subscribe({
      next: (res) => {
        this.isForgotPasswordClicked = false;
        this.router.navigate(this.isClient ? ['client/verify'] : ['verify'], {
          state: {
            isUserLoggingIn: false,
            userId: res.userId,
            emailId: this.forgotPasswordForm?.controls?.emailControl?.value || '',
            isAdmin: !this.isClient,
          },
          replaceUrl: true,
        });
      },
      error: (error: HttpErrorResponse) => {
        this.isForgotPasswordClicked = false;
        this.toastrService.toastrConfig = { ...DefaultGlobalConfig, preventDuplicates: true };
        this.toastrService.error(error.error.message);
      },
    });
  }

  validateEmail() {
    if (this.forgotPasswordForm.get('emailControl')?.hasError('required')) {
      this.forgotPasswordForm.get('emailControl')?.setValue('');
    }
    this.forgotPasswordForm.get('emailControl')?.markAsTouched();
    this.forgotPasswordForm.get('emailControl')?.markAsDirty();
  }
}
