import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Config } from '../../constants/constants';
import { ValidationMessages } from '../../enums/messages';
import { ClientListForMatterFilter } from '../../interfaces/Admin/Matter/ClientListForMatterFilterResponse';
import { MatterList } from '../../interfaces/Admin/Matter/MatterListForClientsResponse';
import { descriptionValidator } from '../../validators/description.validator';

@Component({
  selector: 'app-insert-budget-notes',
  templateUrl: './insert-budget-notes.component.html',
  styleUrls: ['./insert-budget-notes.component.scss'],
})
export class InsertBudgetNotesComponent {
  @Input() matterID: number = 0;
  @Input() clientID: number = 0;
  @Input() matterListDataSource: MatterList[] = [];
  @Input() clientListDatasource: ClientListForMatterFilter[] = [];
  @Output() sendNotes: EventEmitter<{ clientID: number; matterID: number; notes: string }> = new EventEmitter();

  sendNotesForm: FormGroup;
  validationMessages = ValidationMessages;

  constructor(public activeModal: NgbActiveModal) {
    this.initSendNotesForm();
  }

  initSendNotesForm() {
    this.sendNotesForm = new FormGroup({
      notes: new FormControl('', [descriptionValidator(Config.maxLength2000)]),
    });
  }

  sentNotes() {
    if (this.sendNotesForm.invalid || !(this.clientID && this.matterID)) {
      return;
    }
    this.sendNotes.emit({
      clientID: this.clientID,
      matterID: this.matterID,
      notes: this.sendNotesForm?.get('notes')?.value,
    });
    this.activeModal.close();
  }
}
