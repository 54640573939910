/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-vertical-navigation',
  templateUrl: './vertical-navigation.component.html',
  styleUrls: ['./vertical-navigation.component.scss'],
})
export class VerticalNavigationComponent {
  @Input() menuItems: any[];
  @Input() role: string;
  @Input() expectedToggleState: boolean;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  open() {
    this.trigger.openMenu();
  }

  close() {
    this.trigger.closeMenu();
  }
}
