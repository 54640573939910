<ng-container>
  <div class="modal-header" [ngClass]="{ 'manual-header': headerTitle }">
    <h4 class="modal-title" id="myModalLabel" *ngIf="headerTitle">{{ headerTitle }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="onCancelled(); activeModal.dismiss()">
      <svg-icon name="modal-close-icon" class="icon"></svg-icon>
    </button>
  </div>
  <div class="modal-body">
    <div class="confirm-message">
      <div class="confirm-message-content" *ngIf="!isTimerRunning; else runningTimerSubjectLine">{{ subjectLine }}</div>
      <ng-template #runningTimerSubjectLine>
        <div class="confirm-message-content">
          Are you sure you want to cancel editing this data? Doing this will cancel your editing on this time entry for
          {{ currentTimeString }} hh:mm.
        </div>
      </ng-template>
    </div>
    <div class="btns-wrap">
      <button class="btn btn-md" (click)="onConfirmed()">{{ confirmBtnText }}</button>
      <button class="btn btn-outline" (click)="onCancelled(); activeModal.dismiss()">{{ cancelBtnText }}</button>
    </div>
  </div>
</ng-container>
