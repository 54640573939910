import { Sort } from '@angular/material/sort';
import { DefaultGlobalConfig, GlobalConfig } from 'ngx-toastr';
import {
  ClientStatus,
  MatterStatus,
  TimeSheetTimeEntryTypeIDs,
  UserStatus,
} from '../enums/enums';
import { UserStatusDataSource } from '../interfaces/Admin/User/UserStatus';

export class Config {
  static readonly intervalForCheckingDocumentUploadForWorkRequests = 30000;

  static readonly twoDecimalPlacesFormat: string = '1.2-2';
  static readonly threeDecimalPlacesFormat: string = '1.3-3';

  static readonly maxLength250Regex = '^.{0,250}$';
  static readonly maxLength2000Regex = `^.{1,2000}$`;
  static readonly maxLength2000 = 2000;
  static readonly maxLength150 = 150;
  static readonly maxLength250 = 250;
  static readonly maxLength500 = 500;
  static readonly allowedCharactersDescriptionRegex = `^[a-zA-Z0-9!@#$÷×%^&?=()/+€£¥*'"-_,;. \r\n]*$`;
  static readonly maxLength150Regex = `^[a-zA-Z0-9!@#$%^&*'"-_,;. \r\n]{1,150}$`;
  static readonly maxLength500Regex = `^[a-zA-Z0-9!@#$%^&*'"-_,;. \r\n]{1,500}$`;
  static readonly validEmailRegex = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
  static readonly validPasswordRegex = '^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$';
  static readonly validPasswordRegexAcceptingSpecialChars =
    '^(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*.]{8,16}$';
  static readonly restrictDecimalPlacesBy2Digits = '^([0-9]+(.?[0-9]?[0-9]?)?)';
  static readonly allowedHoursDecimalPlaces = 2;
  static readonly availabilityPopupInterval = 86400; // 24h

  static readonly fileUploadFormats = '.csv,.doc,.docx,.jpg,.jpeg,.pdf,.png,.ppt,.pptx,.xlsx,.xls,.xml'; // extensions must be listed without space and comma separated so that splitting it with comma would be easier
  static readonly numberOfFilesAllowedWorkRequest: number = 25;

  static readonly iframeUrlCheckForBusinessReporting = `^<iframe(.+)</iframe>$`;

  static readonly allowOnlyNumbers = /^[+]?(\d+)$/;
  static readonly allowOnlyAlphaNumeric = /^[a-zA-Z0-9 ]+$/;
  static readonly allowOnlyPercentage = /^[+]?(\d+(?:[.]\d*)?|\.\d+)$/;
  static readonly allowOnlyCapsAlphabets = /^[A-Z]+$/;
  static readonly allowNumeric = /^\d+$/;

  static readonly timeSheetTimeValidation = {
    regexWithColon: /^\d{1,2}:\d{2}$/, // /^\d{1,2}:\d{1,2}$/, (Previous used regex)
    regexWithDot: /^\d{1,2}\.\d{1,2}$/,
    regexWithColonHHmmSS: /^\d{1,2}:\d{2}:\d{2}$/,
    regexWithDotHHmmSS: /^\d{1,2}\.\d{2}\.\d{2}$/,
  };

  static readonly momentDateFormat = 'DD/MM/YYYY';

  static readonly defaultTimeEntryTypeIDForMatters = TimeSheetTimeEntryTypeIDs.Billable;

  static readonly toastConfigForInvoices: GlobalConfig = {
    ...DefaultGlobalConfig,
    closeButton: true,
    preventDuplicates: true,
    disableTimeOut: true,
    messageClass: 'invoices-toastr toastr-message',
  };

  static readonly userStatusDataSource: UserStatusDataSource[] = [
    {
      id: 1,
      text: UserStatus.inactive,
    },
    {
      id: 0,
      text: UserStatus.active,
    },
  ];

  static readonly defaultMatterOwnershipSelectedForLegalUsers: string = '1';
  static readonly defaultMatterStatusForLegalUsers: string = MatterStatus.active;
  static readonly defaultClientStatus: string = ClientStatus.active;
  static readonly defaultSortForMatterList: Sort = { direction: 'desc', active: 'lastTimesheetDate' };
  static readonly defaultSortForBillOnCompletionList: Sort = { direction: 'desc', active: 'createdDate' };

  static readonly defaultSortingForWeeklyTimeSheet: Sort = { active: 'LastUpdateTime', direction: 'desc' };
  static readonly defaultSortingForReports: Sort = { active: 'title', direction: 'asc' };

  static readonly timeSheetReportSortColumnsIDs: Array<{ column: string; sortID: string }> = [
    {
      column: 'timeentrytype',
      sortID: 'TimeEntryTypeName',
    },
    {
      column: 'billablerate',
      sortID: 'HourlyRate',
    },
    {
      column: 'billableamount',
      sortID: 'TotalCost',
    },
    {
      column: 'headpracticearea',
      sortID: 'headlinepracticearea',
    },
  ];

  static readonly invoicesPageSize: number = 200;
  static readonly timeSheetReportsGroupByPageSize: number = 100;
  static readonly allowOnlyNumericAdvanceAmountPaid = /^[+]?(\d+(?:[.]\d*)?|\.\d+)$/;

  static readonly billOnCompletionColumns: string[] = [
    'clientName',
    'matterName',
    'dmsCode',
    'createdDate',
    'HeadlinePracticeArea',
    'responsibleSolicitor',
    'InstructedBy',
    'status',
    'billOnCompletion',
  ];

  static readonly clientUserRoleType: string = 'Client';
  static readonly clientUserRole: string = 'Prime Client';
}
