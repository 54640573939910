<div class="modal-header">
  <button type="button" class="close" (click)="closeModal.emit()" aria-label="Close">
    <svg-icon name="modal-close-icon" class="icon"></svg-icon>
  </button>
  <h4 class="modal-title" id="myModalLabel">{{ isEdit ? 'Edit' : 'New' }} instruction/project</h4>
</div>
<div class="modal-body">
  <form [formGroup]="workRequestForm">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-field form-field-select">
          <label for="clientName">Client Name</label>
          <ng-select
            *ngIf="isAdmin"
            labelForId="clientName"
            [items]="clientListWorkRequest"
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="true"
            bindLabel="clientName"
            bindValue="id"
            [(ngModel)]="selectedClient"
            [virtualScroll]="true"
            [ngModelOptions]="{ standalone: true }"
            placeholder="Select Client"
          >
          </ng-select>
          <input
            *ngIf="!isAdmin"
            id="clientName"
            matInput
            value="{{ selectedClientName }}"
            type="text"
            placeholder=""
            disabled
          />
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-field form-field-select">
          <label for="title">Title</label>
          <input id="title" matInput formControlName="title" type="text" placeholder="Enter Title" />
          <mat-error
            *ngIf="
              workRequestForm.controls?.title?.touched &&
              workRequestForm.controls?.title?.hasError('descriptionRequired') &&
              !workRequestForm.controls?.title?.hasError('invalidDescription')
            "
            >{{ validationMessages.titleRequired }}
          </mat-error>
          <mat-error
            *ngIf="
              workRequestForm.controls?.title?.touched &&
              workRequestForm.controls?.title?.hasError('limitExceeds') &&
              !workRequestForm.controls?.title?.hasError('invalidDescription')
            "
            >{{ validationMessages.maxLength250ForTitle }}
          </mat-error>
          <mat-error
            *ngIf="
              workRequestForm.controls?.title?.touched &&
              !(
                workRequestForm.controls?.title?.hasError('limitExceeds') ||
                workRequestForm.controls?.title?.hasError('descriptionRequired')
              ) &&
              workRequestForm.controls?.title?.hasError('invalidDescription')
            "
            >{{ validationMessages.allowedCharactersForTitle }}
          </mat-error>
        </div>
      </div>
      <div class="col-sm-12 mb-2">
        Please give as much as detail as you can about what you require and attach any documents below
      </div>
      <div class="col-sm-12">
        <div class="form-field desc-input">
          <label for="description">Description</label>
          <textarea
            id="description"
            formControlName="description"
            name="subject"
            placeholder="Enter Description"
          ></textarea>
          <mat-error
            *ngIf="
              workRequestForm.controls?.description?.touched &&
              workRequestForm.controls?.description?.hasError('descriptionRequired') &&
              !workRequestForm.controls?.description?.hasError('invalidDescription')
            "
            >{{ validationMessages.descriptionRequired }}
          </mat-error>
          <mat-error
            *ngIf="
              workRequestForm.controls?.description?.touched &&
              workRequestForm.controls?.description?.hasError('limitExceeds') &&
              !workRequestForm.controls?.description?.hasError('invalidDescription')
            "
            >{{ validationMessages.maxLength500ForDescription }}
          </mat-error>
          <mat-error
            *ngIf="
              workRequestForm.controls?.description?.touched &&
              !(
                workRequestForm.controls?.description?.hasError('limitExceeds') ||
                workRequestForm.controls?.description?.hasError('descriptionRequired')
              ) &&
              workRequestForm.controls?.description?.hasError('invalidDescription')
            "
            >{{ validationMessages.allowedCharactersForDescription }}
          </mat-error>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-field form-field-select">
          <label for="headlinePracticeArea">Legal area</label>
          <ng-select
            labelForId="headlinePracticeArea"
            [items]="headLinePracticeArea"
            [multiple]="false"
            [closeOnSelect]="true"
            bindLabel="headlinePracticeAreaName"
            bindValue="id"
            [searchable]="true"
            (change)="workRequestHPASelected()"
            [(ngModel)]="workRequestHPA"
            [ngModelOptions]="{ standalone: true }"
            placeholder="Select Legal Area"
          >
          </ng-select>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-field form-field-select">
          <label for="subPracticeArea">Type of advice needed</label>
          <ng-select
            labelForId="subPracticeArea"
            [items]="subPracticeArea"
            [multiple]="true"
            [closeOnSelect]="false"
            [clearSearchOnAdd]="true"
            bindLabel="subPracticeAreaName"
            bindValue="subPracticeAreaId"
            [searchable]="true"
            [(ngModel)]="workRequestSPA"
            [ngModelOptions]="{ standalone: true }"
            placeholder="Type of advice needed"
          >
          </ng-select>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-field date-input">
          <label for="fromDate">Requested start date</label>
          <mat-form-field appearance="fill">
            <input
              id="fromDate"
              matInput
              (keydown)="disableDate()"
              formControlName="fromDate"
              [min]="workRequestForm.controls.fromDate.value || todayDate"
              [max]="workRequestForm.controls.toDate.value"
              [matDatepicker]="requestFromDate"
              placeholder="Select Start Date"
            />
            <mat-datepicker-toggle [for]="requestFromDate">
              <mat-icon matDatepickerToggleIcon>
                <svg-icon name="calendar" class="icon"></svg-icon>
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #requestFromDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-field date-input">
          <label for="toDate">Requested end date</label>
          <mat-form-field appearance="fill">
            <input
              id="toDate"
              matInput
              (keydown)="disableDate()"
              [min]="workRequestForm.controls.fromDate.value || todayDate"
              [matDatepicker]="requestToDate"
              placeholder="Select End Date"
              formControlName="toDate"
            />
            <mat-datepicker-toggle [for]="requestToDate">
              <mat-icon matDatepickerToggleIcon>
                <svg-icon name="calendar" class="icon"></svg-icon>
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #requestToDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-6" *ngIf="isAdmin && isEdit">
        <div class="form-field form-field-select">
          <label for="workRequestStatus">Status</label>
          <ng-select
            labelForId="workRequestStatus"
            [items]="statusDataSource"
            [multiple]="false"
            [closeOnSelect]="true"
            bindLabel="workRequestStatus"
            bindValue="id"
            [searchable]="true"
            [(ngModel)]="workRequestStatus"
            [ngModelOptions]="{ standalone: true }"
            placeholder="Select Status"
          >
          </ng-select>
        </div>
      </div>
      <div class="col-sm-12">
        <h4 class="modal-form-title">Contact Details</h4>
      </div>
      <div class="col-sm-6">
        <div class="form-field">
          <label for="name">Name</label>
          <input id="name" matInput formControlName="name" type="text" placeholder="Enter Name" />
          <mat-error
            *ngIf="workRequestForm.controls.name.hasError('required') && workRequestForm.controls.name.touched"
          >
            Name cannot be blank
          </mat-error>
          <mat-error
            *ngIf="
              workRequestForm.controls.name.hasError('pattern') &&
              workRequestForm.controls.name.dirty &&
              workRequestForm.controls.name.touched
            "
          >
            Invalid name
          </mat-error>
          <svg-icon name="user-icon" style="color: #5f636b" class="input-icon"></svg-icon>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-field">
          <label for="jobTitle">Job title</label>
          <input id="jobTitle" matInput formControlName="jobTitle" type="text" placeholder="Enter Job Title" />
          <mat-error
            *ngIf="workRequestForm.controls.jobTitle.hasError('required') && workRequestForm.controls.jobTitle.touched"
          >
            Job Title cannot be blank
          </mat-error>
          <mat-error
            *ngIf="
              workRequestForm.controls.jobTitle.hasError('pattern') &&
              workRequestForm.controls.jobTitle.dirty &&
              workRequestForm.controls.jobTitle.touched
            "
          >
            Invalid job title
          </mat-error>
          <svg-icon name="job-icon" style="color: #5f636b" class="input-icon"></svg-icon>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-field">
          <label for="email">Email</label>
          <input id="email" matInput type="text" formControlName="email" placeholder="Enter Email" name="email" />
          <mat-error
            *ngIf="workRequestForm.controls.email.hasError('required') && workRequestForm.controls.email.touched"
          >
            Email cannot be blank
          </mat-error>
          <mat-error
            *ngIf="
              workRequestForm.controls.email.hasError('pattern') &&
              workRequestForm.controls.email.dirty &&
              workRequestForm.controls.email.touched
            "
          >
            Invalid email
          </mat-error>
          <svg-icon name="mail-icon" style="color: #5f636b" class="input-icon"></svg-icon>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-field">
          <label for="phone">Phone Number</label>
          <input id="phone" matInput type="text" formControlName="phone" placeholder="Enter Phone" />
          <mat-error
            *ngIf="workRequestForm.controls.phone.hasError('required') && workRequestForm.controls.phone.touched"
          >
            Phone Number cannot be blank
          </mat-error>
          <mat-error
            *ngIf="
              workRequestForm.controls.phone.hasError('pattern') &&
              workRequestForm.controls.phone.dirty &&
              workRequestForm.controls.phone.touched
            "
          >
            Invalid phone number
          </mat-error>
          <svg-icon name="call-icon" style="color: #5f636b" class="input-icon"></svg-icon>
        </div>
      </div>
    </div>
    <div *ngIf="isEdit">
      <div *ngFor="let file of editWorkRequestFilesReceived">
        <div class="pdf-file-view">
          <span><svg-icon name="pdf-icon"></svg-icon></span>
          <div class="pdf-file-view-wrap">
            <div class="pdf-file-name">{{ file.documentName }}</div>
            <div class="pdf-size">{{ file.documentSize }}</div>
          </div>
          <span class="close-icon"
            ><svg-icon
              name="close-icon-grey"
              (keyup)="removeFileFromEditReceived(file.documentId)"
              (click)="removeFileFromEditReceived(file.documentId)"
            ></svg-icon
          ></span>
        </div>
      </div>
    </div>
    <div *ngFor="let file of workRequestFiles">
      <div class="pdf-file-view">
        <span><svg-icon name="pdf-icon"></svg-icon></span>
        <div class="pdf-file-view-wrap">
          <div class="pdf-file-name">{{ file.name }}</div>
          <div class="pdf-size">{{ file.size }}</div>
        </div>
        <span class="close-icon"
          ><svg-icon (keyup)="removeFile(file)" name="close-icon-grey" (click)="removeFile(file)"></svg-icon
        ></span>
      </div>
    </div>

    <div class="foot-btn-wrap">
      <div class="file-upload-input">
        <input
          type="file"
          [accept]="config.fileUploadFormats"
          multiple
          id="file-input"
          class="file"
          (change)="onFileChange($event)"
        />
        <span class="file-upload-input btn btn-md btn-outline">
          <svg-icon name="upload-icon"></svg-icon>
          Upload Document
        </span>
      </div>
      <div class="file-upload-input" *ngIf="isEdit">
        <button
          class="file-upload-input btn btn-md btn-outline download-document"
          [disabled]="documentUploadInProgressEditWorkRequest"
          (click)="downloadDocument(workRequestForm.controls.id.value)"
        >
          <svg-icon name="download-icon"></svg-icon>
          Download Document
        </button>
      </div>
      <button
        class="btn btn-md"
        [disabled]="!workRequestForm.valid || !workRequestHPA || !workRequestSPA.length"
        (click)="addUpdateWorkRequest()"
      >
        Submit
      </button>
    </div>
  </form>
  <div class="upload-document-note">
    <span>*</span> Number of documents should be no more than 25 and maximum size per document is 25MB
  </div>
</div>
