import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Config } from '../constants/constants';

@Pipe({
  name: 'decimalPlaces'
})
export class DecimalPlacesPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) { }

  transform(value: number, digitsInfo: string = Config.twoDecimalPlacesFormat): string | null {
    return this.decimalPipe.transform(value, digitsInfo);
  }

}
