import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIUrls } from '../enums/apiUrls';
import { SuccessResponse } from '../interfaces/Admin/SuccessResponse';
import { AddUpdateWorkRequestResponse } from '../interfaces/Admin/WorkRequest/AddUpdateWorkRequest';
import { WorkRequestListRequest } from '../interfaces/Admin/WorkRequest/WorkRequestListRequest';
import { WorkRequestListResponse } from '../interfaces/Admin/WorkRequest/WorkRequestListResponse';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class WorkRequestService {
  constructor(private _apiService: ApiService) {}

  getWorkRequestList(req: WorkRequestListRequest): Observable<WorkRequestListResponse> {
    return this._apiService.post<WorkRequestListResponse>(APIUrls.GetWorkRequestListForAdmin, { ...req });
  }

  addUpdateWorkRequestByAdmin(formData: FormData): Observable<AddUpdateWorkRequestResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.AddUpdateWorkRequestByAdmin, formData);
  }

  uploadWorkRequestDocumentsByAdmin(formData: FormData): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.UploadWorkRequestDocumentsByAdmin, formData);
  }

  //download work
  downloadWorkRequestDocumentByIdForAdmin(workRequestId: number, isFromWorkRequestList: boolean) {
    return this._apiService.post(
      APIUrls.DownloadWorkRequestDocumentByIdForAdmin,
      { workRequestId, isFromWorkRequestList },
      { responseType: 'blob' },
    );
  }
}
