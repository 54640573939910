import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { Timer } from '../../interfaces/Admin/AutomaticTimeEntry/TimerResponse';
import {
  MatterClientList,
  MatterClientListForAutomaticTimesheetList,
} from '../../interfaces/Admin/HJHours/MatterClientListResponse';
import { MatterClientListResquest } from '../../interfaces/Admin/HJHours/MatterClientListResquest';
import {
  EndTimerParams,
  TimesheetDetails,
  WeeklyTimesheetDetailsByTimesheetIdResponse,
} from '../../interfaces/Admin/Timesheet/WeeklyTimesheetDetailsByTimesheetIdResponse';
import { TimeLogService } from '../../services/time-log.service';
import { TimesheetService } from '../../services/timesheet.service';
import { EditTimerModalComponent } from '../edit-timer-modal/edit-timer-modal.component';

@Component({
  selector: 'app-timer-pop-up-modal',
  templateUrl: './timer-pop-up-modal.component.html',
  styleUrl: './timer-pop-up-modal.component.scss',
})
export class TimerPopUpModalComponent implements OnInit {
  @Input() currentUserId: number;

  @Output() closeModal = new EventEmitter<void>();
  @Output() isLoading = new EventEmitter<boolean>();

  headerDate = new Date().toDateString();
  searchFieldUpdate = new Subject<string>();
  matterClientListAutomaticTimesheet: MatterClientListForAutomaticTimesheetList[];

  currentTimeString: string;
  currentTimer: Timer | null;
  automaticTimesheetSearch = '';
  isTimerRunning = false;

  timesheetFound = false;

  totalTimeAllDayString: string;
  endTimerParams: EndTimerParams;

  private destroyRef = inject(DestroyRef);

  constructor(
    private _timeLogService: TimeLogService,
    private _timeSheetService: TimesheetService,
    private _modalService: NgbModal,
  ) {
    this.searchFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
      this.isLoading.emit(true);
      this.getMatterSearchClientList();
    });
  }

  ngOnInit(): void {
    this.getMatterClientList();
    this.subscribeTotalTimeOfDay();
    this.subscribeCurrentTimeofMatter();
    this.subscribeCurrenTimesheetDetails();
  }

  subscribeTotalTimeOfDay() {
    this._timeLogService.totaltimeOfDayObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((totalTime) => {
      this.totalTimeAllDayString = this._timeSheetService.formatTime(totalTime, false);
    });
  }

  subscribeCurrenTimesheetDetails() {
    this._timeLogService.currenTimesheetDetailsObservable
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((currentTimesheet) => {
        this.currentTimer = currentTimesheet;
      });
  }

  subscribeCurrentTimeofMatter() {
    this._timeLogService.currentTimeofMatterObservable
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((currentTime) => {
        this.currentTimeString = this._timeSheetService.formatTime(currentTime, false);
        this.isTimerRunning = this._timeLogService.isTimerRunning;
      });
  }

  processMatterClientList(matterClientList: MatterClientList[]) {
    this.timesheetFound = !!matterClientList?.length;

    if (!this.timesheetFound) {
      this.matterClientListAutomaticTimesheet = [];
    } else {
      this.setMatterClientListAutomaticTimesheet(matterClientList);
      this.manageTimerRunningForOpenPopupTimer();
    }
    this.isLoading.emit(false);
  }

  getMatterClientList() {
    const req: MatterClientListResquest = {
      userId: this.currentUserId,
      timesheetDate: this._timeSheetService.formatDate(new Date().toDateString()),
    };
    this._timeSheetService.getMatterClientList(req).subscribe({
      next: (res) => {
        this.processMatterClientList(res.result?.matterClients);
      },
      error: () => {
        this.isLoading.emit(false);
      },
    });
  }

  getMatterSearchClientList() {
    const req: MatterClientListResquest = {
      userId: this.currentUserId,
      search: this.automaticTimesheetSearch.trim() || null,
      timesheetDate: this._timeSheetService.formatDate(new Date().toDateString()),
    };
    this._timeSheetService.getSearchMatterClientList(req).subscribe({
      next: (res) => {
        this.processMatterClientList(res.result?.matterClients);
      },
      error: () => {
        this.isLoading.emit(false);
      },
    });
  }

  setMatterClientListAutomaticTimesheet(matterClients: MatterClientList[]) {
    this.matterClientListAutomaticTimesheet = matterClients.map((row) => ({
      ...row,
      isActive: false,
      timerValue: 0,
      timerString: this._timeSheetService.formatTime(this._timeSheetService.getSeconds(row.totalTime), false),
      isEditEnabled: new Date().toDateString() === new Date(row.timesheetDate).toDateString(),
    }));
  }

  manageTimerRunningForOpenPopupTimer() {
    const runningTimesheet = this.matterClientListAutomaticTimesheet.filter(
      (matterTimesheet) => matterTimesheet.timerStatus === 'Running',
    );
    if (runningTimesheet.length) {
      const timesheet = runningTimesheet[0];

      if (this.isTimerRunning && this.currentTimer) {
        if (this.currentTimer.timesheetId === timesheet.timesheetId) {
          timesheet.isActive = true;
        } else {
          this.stopTimerForInternetLossTabClose();
        }
      }
      this.isLoading.emit(false);
    } else {
      if (
        this.isTimerRunning &&
        this.matterClientListAutomaticTimesheet.findIndex(
          (clientMatter) => clientMatter.timesheetId === this.currentTimer?.timesheetId,
        ) > -1
      ) {
        this.stopTimerForInternetLossTabClose();
      }
      this.isLoading.emit(false);
    }
  }

  editTimesheetinPopUp(
    timesheetId: number,
    isDashboardEnd: boolean,
    index: number,
    isFromOpenTimerPopup: boolean,
    isCancelEditTimesheet: boolean,
    isEndTimerEdit: boolean,
  ) {
    if (timesheetId) {
      this.isLoading.emit(true);
      this._timeSheetService.getWeeklyTimesheetByTimesheetId(timesheetId).subscribe({
        next: (res: WeeklyTimesheetDetailsByTimesheetIdResponse) => {
          this.isLoading.emit(true);
          this.endTimerParams = {
            isDashboardEnd: isDashboardEnd,
            isFromOpenTimerPopup: isFromOpenTimerPopup,
            isCancelEditTimesheet: isCancelEditTimesheet,
            index: index,
            isEndTimerEdit: isEndTimerEdit,
          };
          this.openTimeSheetEditModal(isDashboardEnd, res.result.timesheetDetails);
        },
        error: () => {
          this.isLoading.emit(true);
        },
      });
    }
  }

  openTimeSheetEditModal(isDashboard: boolean, timesheetDetail: TimesheetDetails) {
    const editTimeSheetModalRef = this._modalService.open(EditTimerModalComponent, {
      windowClass: 'edit-timesheet-modal edit-timeentry-modal',
      centered: true,
    });
    editTimeSheetModalRef.componentInstance.endTimerParams = this.endTimerParams;
    editTimeSheetModalRef.componentInstance.isDashboardEnd = isDashboard;
    editTimeSheetModalRef.componentInstance.timesheetDetail = timesheetDetail;
    editTimeSheetModalRef.componentInstance.closeModal?.subscribe(() => editTimeSheetModalRef.close());
    editTimeSheetModalRef.componentInstance.isLoading?.subscribe((loading: boolean) => this.isLoading.emit(loading));
    editTimeSheetModalRef.componentInstance.setActiveStatus?.subscribe(
      () => (this.matterClientListAutomaticTimesheet[this.endTimerParams.index].isActive = false),
    );
  }

  stopTimerForInternetLossTabClose() {
    if (this.isTimerRunning && this.currentTimer) {
      return this._timeLogService.stopTimerForTabClose(this.currentUserId);
    }
    return Promise.resolve();
  }

  clearSearch() {
    this.automaticTimesheetSearch = '';
    this.getMatterSearchClientList();
  }

  pageLoading(event: boolean) {
    this.isLoading.emit(event);
  }
}
