import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-resend-invoice-information-modal',
  templateUrl: './resend-invoice-information-modal.component.html',
})
export class ResendInvoiceInformationModalComponent {
  @Input() headerTitle: string = '';
  @Input() subjectLine: string = '';
  @Input() message: string = '';
  @Input() resentEmailId = '';

  constructor(public activeModal: NgbActiveModal) {}
}
