import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-day-change-modal',
  templateUrl: './day-change-modal.component.html',
})
export class DayChangeModalComponent {
  @Output() closeModal = new EventEmitter<void>();
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() openHourLogModal = new EventEmitter<void>();
  headerDate = new Date().toDateString();
}
