import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Config } from '../../constants/constants';
import { TimeSheetTimeEntryTypeIDs, TimerErrorCodes } from '../../enums/enums';
import { ValidationMessages } from '../../enums/messages';
import { StartTimerRequest } from '../../interfaces/Admin/AutomaticTimeEntry/StartTimerRequest';
import { PreviousRunning, Timer, TimerResponse } from '../../interfaces/Admin/AutomaticTimeEntry/TimerResponse';
import { ValidateNewStartTimeEntryResponse } from '../../interfaces/Admin/AutomaticTimeEntry/ValidateNewStartTimeEntry';
import { MatterClientListForAutomaticTimesheetList } from '../../interfaces/Admin/HJHours/MatterClientListResponse';
import { MatterList } from '../../interfaces/Admin/Matter/MatterListResponse';
import {
  EndTimerParams,
  TimesheetDetails,
  WeeklyTimesheetDetailsByTimesheetIdResponse,
} from '../../interfaces/Admin/Timesheet/WeeklyTimesheetDetailsByTimesheetIdResponse';
import { AuthService } from '../../services';
import { TimeLogService } from '../../services/time-log.service';
import { TimesheetService } from '../../services/timesheet.service';
import { EditTimerModalComponent } from '../edit-timer-modal/edit-timer-modal.component';

@Component({
  selector: 'app-timer-buttons',
  templateUrl: './timer-buttons.component.html',
  styleUrl: './timer-buttons.component.scss',
})
export class TimerButtonsComponent implements OnInit {
  @Input() matterClient: MatterClientListForAutomaticTimesheetList;
  @Input() isDashboardStart: boolean;
  @Input() isFromMatter = false;
  @Input() dataSource: MatterList;
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() validationErrros = new EventEmitter<{ timeInvalid: boolean; timeExceed: boolean }>();

  private destroyRef = inject(DestroyRef);
  timeSheetTimeEntryTypeIDs = TimeSheetTimeEntryTypeIDs;
  isTimerRunning = false;
  currentUserId: number;
  currentTimer: null | Timer;
  endTimerParams: EndTimerParams;
  previousRunningTimerDetails: PreviousRunning[];
  currentTimeString: string;
  validationMessages = ValidationMessages;

  startEditingNewTime = false;
  editedNewTimeString = '00:00:00';
  isEditedNewTimeInvalid = false;
  isEditedNewTimeExceeded = false;

  constructor(
    private _timeLogService: TimeLogService,
    private _authService: AuthService,
    private _timeSheetService: TimesheetService,
    private _toastrService: ToastrService,
    private _modalService: NgbModal,
  ) {
    const userInfo = this._authService.getUserInfo();
    if (userInfo) {
      this.currentUserId = userInfo.userId;
    }
  }

  ngOnInit(): void {
    this.subscribeCurrentTimeofMatter();
    this.subscribeCurrenTimesheetDetails();
    this.subscribeClearInlineEditor();
    this.subscribeTimerStartEnd();
  }

  subscribeTimerStartEnd() {
    if (this.isDashboardStart) {
      this._timeSheetService.startEndTimer.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((timerDetail) => {
        if (timerDetail.isStart) {
          this.matterClient = timerDetail.matterClient;
          this.startTimer(false);
        }
      });
    }
  }

  subscribeClearInlineEditor() {
    if (this.isDashboardStart) {
      this._timeSheetService.clearInlineEditor.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.startEditingNewTime = false;
        this.resetInlineEditTimerToDefault();
      });
    }
  }

  subscribeCurrentTimeofMatter() {
    this._timeLogService.currentTimeofMatterObservable
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((currentTimeNumber) => {
        this.currentTimeString = this._timeSheetService.formatTime(currentTimeNumber, false);
        this.isTimerRunning = this._timeLogService.isTimerRunning;
      });
  }

  subscribeCurrenTimesheetDetails() {
    this._timeLogService.currenTimesheetDetailsObservable
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((currentTimer) => {
        this.currentTimer = currentTimer;
      });
  }

  getNowMatterListData() {
    const timeOut = setTimeout(() => {
      this._timeSheetService.getNowMatterListData();
      clearTimeout(timeOut);
    }, 2000);
  }

  getNowTimesheetListData() {
    const timeOut = setTimeout(() => {
      clearTimeout(timeOut);
      this._timeLogService.onTimeSheetDataChanged();
    }, 2000);
  }

  inlineEditTimer($event: any) {
    $event.stopPropagation();
    this.resetInlineEditTimerToDefault();
    this.startEditingNewTime = !this.startEditingNewTime;
  }

  resetInlineEditTimerToDefault() {
    this.editedNewTimeString = '00:00:00';
  }

  inlineNewTimeKeyUp($event: any) {
    this.stopPropagationForInlineNewTime($event);
    this.validateInlineNewTime();
  }

  stopPropagationForInlineNewTime($event: any) {
    $event.stopPropagation();
  }

  validateInlineNewTime() {
    const regexWithColonHHmmSS: RegExp = Config.timeSheetTimeValidation.regexWithColonHHmmSS;
    const regexWithDotHHmmSS: RegExp = Config.timeSheetTimeValidation.regexWithDot;
    const s: string = this.editedNewTimeString;
    if (!regexWithColonHHmmSS.exec(s)?.[0] && !regexWithDotHHmmSS.exec(s)?.[0]) {
      this.isEditedNewTimeInvalid = true;
      this.isEditedNewTimeExceeded = false;
      this.validationErrros.emit({
        timeInvalid: this.isEditedNewTimeInvalid,
        timeExceed: this.isEditedNewTimeExceeded,
      });
      return;
    }
    this.isEditedNewTimeInvalid = false;
    if (s.includes(':')) {
      const sub: string[] = s.split(':');
      this.isEditedNewTimeExceeded = !(
        sub?.[0] &&
        this.checkForValidHours(parseInt(sub[0])) &&
        sub[1] &&
        this.checkForValidMinutesOrSeconds(parseInt(sub[1])) &&
        sub[2] &&
        this.checkForValidMinutesOrSeconds(parseInt(sub[2]))
      );
    } else if (s.includes('.')) {
      const sub: string[] = s.split('.');
      this.isEditedNewTimeExceeded = !(
        sub?.[0] &&
        this.checkForValidHours(parseInt(sub[0])) &&
        sub[1] &&
        parseInt(sub[1]) >= 0 &&
        parseInt(sub[1]) <= 99
      );
    }
    this.validationErrros.emit({ timeInvalid: this.isEditedNewTimeInvalid, timeExceed: this.isEditedNewTimeExceeded });
  }

  checkForValidHours(hours: number) {
    return hours >= 0 && hours < 24;
  }

  checkForValidMinutesOrSeconds(numbers: number) {
    return numbers >= 0 && numbers <= 59;
  }

  startTimer(isDashboardStart: boolean) {
    if (!this.isTimerRunning) {
      this.isLoading.emit(true);

      if (
        isDashboardStart &&
        !(this.isEditedNewTimeInvalid || this.isEditedNewTimeExceeded) &&
        this.editedNewTimeString &&
        this.editedNewTimeString !== '00:00:00'
      ) {
        this.validateNewEditedTimeForStartTimer()
          .then((result) => {
            if (result.result.isValidTime) {
              const validAllowedTime: string = this.updateEditedNewTimeStringWithColon();
              this.startTimerSaveNewTimesheet(
                isDashboardStart,
                true,
                validAllowedTime !== result.result.validAllowedTime ? validAllowedTime : result.result.validAllowedTime,
              );
            }
            this.isLoading.emit(false);
          })
          .catch((result: ValidateNewStartTimeEntryResponse) => {
            if (result.statusCode === TimerErrorCodes.TimeExceededOrTimeSheetLock) {
              this._toastrService.error(result.message);
            }
            this.isLoading.emit(false);
          });
      } else {
        this.startTimerSaveNewTimesheet(isDashboardStart);
      }
    }
  }

  validateNewEditedTimeForStartTimer(): Promise<ValidateNewStartTimeEntryResponse> {
    return new Promise((resolve, reject) => {
      this._timeLogService
        .checkValidAllowedTime({
          timeEditDateTime: new Date().toISOString(),
          userId: this.currentUserId,
          timesheetDate: this._timeSheetService.formatDate(new Date().toDateString()),
          enteredTime: this.updateEditedNewTimeStringWithColon(),
          timezoneOffset: new Date().getTimezoneOffset() * -1,
        })
        .subscribe({
          next: (result) => {
            if (result.result.isValidTime) {
              resolve(result);
            } else {
              reject(result);
            }
          },
          error: (error: HttpErrorResponse) => {
            this.isLoading.emit(false);
            reject(error.error);
          },
        });
    });
  }

  updateEditedNewTimeStringWithColon() {
    const regexWithDotHHmmSS = Config.timeSheetTimeValidation.regexWithDotHHmmSS;
    let validAllowedTime = this.editedNewTimeString;
    if (regexWithDotHHmmSS.exec(this.editedNewTimeString)?.[0]) {
      const splitted = this.editedNewTimeString.split('.');
      validAllowedTime = `${splitted[0]}:${splitted[1]}:${splitted[2]}`;
    }
    return validAllowedTime;
  }

  prepareRequestParamsForStartTimerSaveNewTimesheet(): StartTimerRequest {
    const date = new Date();
    return {
      userId: this.currentUserId,
      clientId: this.isFromMatter ? this.dataSource?.clientId || null : null,
      matterId: this.isFromMatter ? this.dataSource?.id || null : null,
      startTime: new Date().toISOString(),
      epochTimestamp: Math.round(Date.now() / 1000).toString(),
      timesheetDate: this._timeSheetService.formatDate(date.toDateString()),
      timezoneOffset: date.getTimezoneOffset() * -1,
      timezone: moment.tz.guess(),
      timesheetId: 0,
      isTimeEdit: this.isFromMatter ? undefined : false,
      timeEntryTypeId: this.isFromMatter ? Config.defaultTimeEntryTypeIDForMatters : TimeSheetTimeEntryTypeIDs.TempTime,
    };
  }

  getClientMatterDescriptionCategory() {
    return {
      clientId: this.matterClient.clientId ? this.matterClient.clientId : null,
      matterId: this.matterClient.matterId ? this.matterClient.matterId : null,
      description: this.matterClient.description ? this.matterClient.description : '',
      categoryId: this.matterClient.categoryId ? this.matterClient.categoryId : null,
    };
  }

  startTimerSaveNewTimesheet(isDashboardStart: boolean, updateStarTime = false, validAllowedTime = '') {
    const req: StartTimerRequest = this.prepareRequestParamsForStartTimerSaveNewTimesheet();
    if (!this.isFromMatter) {
      if (updateStarTime && validAllowedTime) {
        const validAllowedTimeSplitted = validAllowedTime.split(':');
        req.startTime = moment()
          .subtract({
            hours: parseInt(validAllowedTimeSplitted[0]),
            minutes: parseInt(validAllowedTimeSplitted[1]),
            seconds: parseInt(validAllowedTimeSplitted[2]),
          })
          .toISOString();
        req.isTimeEdit = true;
      }
      if (!isDashboardStart) {
        const { clientId, matterId, description, categoryId } = this.getClientMatterDescriptionCategory();
        req.clientId = clientId;
        req.matterId = matterId;
        req.epochTimestamp = this.matterClient.epochTimestamp;
        req.timesheetId = this.matterClient.timesheetId;
        req.description = description;
        req.categoryId = categoryId;
        req.timeEntryTypeId = this.matterClient.timeEntryTypeId ?? TimeSheetTimeEntryTypeIDs.TempTime;
      }
    }
    this.onStartTimerSaveNewTimesheet(isDashboardStart, req);
  }

  onStartTimerSaveNewTimesheet(isDashboardStart: boolean, req: StartTimerRequest) {
    this._timeLogService.startTimer(req).subscribe({
      next: (res: TimerResponse) => {
        if (res.result.previousRunningTimerDetails?.length) {
          this.previousRunningTimerDetails = res.result.previousRunningTimerDetails;
          this.StopPreviousRunningTimers();
        }

        this._timeLogService.setcurrentTimesheetDetailsData(res.result);
        if (!isDashboardStart && !this.isFromMatter) this.matterClient.isActive = true;
        !isDashboardStart && this.isFromMatter && (this.dataSource.isActive = true);

        this._timeLogService.setcurrentTimeofMatterData(
          this._timeSheetService.getSeconds(res.result.totalTimeForTimer),
        );
        this._timeLogService.setLastLoggedTimeOfMatterData(
          this._timeSheetService.getSeconds(res.result.lastLoggedTime),
        );
        this._timeLogService.startTime();
        this._toastrService.success(res.message);
        if (!this.isFromMatter) {
          this.getNowMatterListData();
          this.getNowTimesheetListData();
        }
        this.isLoading.emit(false);
      },
      error: (error: HttpErrorResponse) => {
        this.isLoading.emit(false);
        if (
          error?.error?.statusCode === TimerErrorCodes.MatterNotAssigned ||
          error?.error?.statusCode === TimerErrorCodes.DateNotBetweenPlannedStartAndEndDate ||
          error?.error?.statusCode === TimerErrorCodes.TimeExceededOrTimeSheetLock
        ) {
          error?.error?.message && this._toastrService.error(error?.error?.message);
        }
      },
    });
  }

  StopPreviousRunningTimers() {
    this.previousRunningTimerDetails.forEach(async (row) => {
      this._timeLogService
        .endTimer({
          userId: this.currentUserId,
          timesheetId: row.previousRunningTimesheetId,
          epochTimestamp: row.previousRunningEpochTimestamp,
          endTime: new Date().toISOString(),
          isManuallyStopped: false,
          timesheetDate: this._timeSheetService.formatDate(new Date().toDateString()),
          timezoneOffset: new Date().getTimezoneOffset() * -1,
          timezone: moment.tz.guess(),
        })
        .subscribe((res) => {
          this.resetInlineEditTimerToDefault();
          this._toastrService.success(res.message);
          this._timeSheetService.updateNowDashboardData(false, false);
        });
    });
  }

  editTimesheetinPopUp(
    timesheetId: number,
    isDashboardEnd: boolean,
    index: number,
    isFromOpenTimerPopup: boolean,
    isCancelEditTimesheet: boolean,
    isEndTimerEdit: boolean,
  ) {
    if (timesheetId) {
      this.isLoading.emit(true);
      this._timeSheetService.getWeeklyTimesheetByTimesheetId(timesheetId).subscribe({
        next: (res: WeeklyTimesheetDetailsByTimesheetIdResponse) => {
          this.isLoading.emit(true);
          this.endTimerParams = {
            isDashboardEnd: isDashboardEnd,
            isFromOpenTimerPopup: isFromOpenTimerPopup,
            isCancelEditTimesheet: isCancelEditTimesheet,
            index: index,
            isEndTimerEdit: isEndTimerEdit,
          };
          this.openTimeSheetEditModal(isDashboardEnd, res.result.timesheetDetails);
        },
        error: () => {
          this.isLoading.emit(true);
        },
      });
    }
  }

  openTimeSheetEditModal(isDashboard: boolean, timesheetDetail: TimesheetDetails) {
    const editTimeSheetModalRef = this._modalService.open(EditTimerModalComponent, {
      windowClass: 'edit-timesheet-modal edit-timeentry-modal',
      centered: true,
    });
    editTimeSheetModalRef.componentInstance.endTimerParams = this.endTimerParams;
    editTimeSheetModalRef.componentInstance.isDashboardEnd = isDashboard;
    editTimeSheetModalRef.componentInstance.timesheetDetail = timesheetDetail;
    editTimeSheetModalRef.componentInstance.closeModal?.subscribe(() => editTimeSheetModalRef.close());
    editTimeSheetModalRef.componentInstance.isLoading?.subscribe((loading: boolean) => this.isLoading.emit(loading));
    editTimeSheetModalRef.componentInstance.clearInlineEditor?.subscribe(() => {
      this.startEditingNewTime = false;
      this._timeSheetService.clearInlineEditorFn();
    });
    editTimeSheetModalRef.componentInstance.setActiveStatus?.subscribe(() => (this.matterClient.isActive = false));
  }

  checkDisableTimer() {
    if (this.isFromMatter) {
      return this.isTimerRunning;
    } else {
      return (
        (!(this.matterClient.categoryName || this.matterClient.description) &&
          this.matterClient.timeEntryTypeId !== this.timeSheetTimeEntryTypeIDs.TempTime) ||
        this.isTimerRunning
      );
    }
  }

  constructTitle() {
    const stopAndSaveMessage = 'Please stop and save current time entry to start a new time entry';
    const incompleteDetailsMessage = 'Please make sure all details for this entry are provided';
    if (this.isFromMatter) {
      return this.isTimerRunning ? stopAndSaveMessage : 'Start Time';
    } else if (this.isTimerRunning) {
      return stopAndSaveMessage;
    } else {
      const isDetailsIncomplete =
        !(this.matterClient.categoryName || this.matterClient.description) &&
        this.matterClient.timeEntryTypeId !== this.timeSheetTimeEntryTypeIDs.TempTime;
      return isDetailsIncomplete ? incompleteDetailsMessage : 'Start Time';
    }
  }

  checkIsActive() {
    return this.isFromMatter ? this.dataSource.isActive : this.matterClient.isActive;
  }
}
