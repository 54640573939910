<div class="preview-invoices">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <svg-icon name="modal-close-icon" class="icon"></svg-icon>
  </button>
  <div class="modal-body">
    <pdf-viewer
      [src]="pdfSrc"
      [render-text]="true"
      [original-size]="false"
      style="width: 920px; height: 800px"
      [fit-to-page]="true"
    >
    </pdf-viewer>
  </div>
</div>

<div class="loader-animation" *ngIf="isLoading">
  <svg-icon name="loader-animation" style="color: #8f9bba" class="input-icon"></svg-icon>
</div>
