import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BusinessReportsHelpSections } from '../../enums/enums';

@Component({
  selector: 'app-reports-help-modal',
  templateUrl: './reports-help-modal.component.html',
  styleUrls: ['./reports-help-modal.component.scss'],
})
export class ReportsHelpModalComponent {
  @Input() headerTitle: string = '';
  activePanelIDs: string[] = [];

  businessReportsHelpSections = BusinessReportsHelpSections;

  constructor(public activeModal: NgbActiveModal) {
    this.activePanelIDs = [BusinessReportsHelpSections.GenerateEmbedLinkSection];
  }
}
