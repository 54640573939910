<div>
    <div class="modal-header" [ngClass]="{ 'manual-header': headerTitle }">
        <h4 class="modal-title" id="myModalLabel">{{ headerTitle }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <svg-icon name="modal-close-icon" class="icon"></svg-icon>
        </button>
    </div>
    <div class="modal-body">
        <div class="timer-info mb">
            Your timer is currently active and has surpassed the designated alert duration. The timer has been running
            for <strong>{{currentTimeString}}</strong> hh:mm.
        </div>
        <div class="timesheet-details mb">
            <div><strong>Timer Details -</strong></div>
            <div>Title: {{ description || '--' }}</div>
            <div *ngIf="(clientName || matterName); else noClientMatter">
                <div *ngIf="clientName">Client: {{ clientName }}</div>
                <div *ngIf="matterName">Matter: {{ matterName }}</div>
            </div>
            <ng-template #noClientMatter>
                <div>Unassigned Matter/Category</div>
            </ng-template>
        </div>
        <div class="info-footer mb">
            Kindly take a moment to review and manage the timer accordingly.
        </div>
    </div>
</div>