import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService, UserService } from '../../services';

@Component({
  selector: 'app-availability-modal',
  templateUrl: './availability-modal.component.html',
})
export class AvailabilityModalComponent {
  constructor(
    private _userService: UserService,
    private _authService: AuthService,
    private _toastrService: ToastrService,
  ) {}

  @Input() selectedAvailability = 0;
  @Input() availabilityStatusList: { id: number; status: string }[];
  @Output() editedAvailability: EventEmitter<string> = new EventEmitter();

  editAvailabilityStatus() {
    if (this.selectedAvailability)
      this._userService.updateAvailabilityStatus(this.selectedAvailability).subscribe({
        next: () => {
          this._authService.set('isStatusSet', 'true');
          if (this.availabilityStatusList?.length) {
            const selectedStatus = this.availabilityStatusList.find((status) => {
              return status.id === this.selectedAvailability;
            });
            if (selectedStatus) {
              this.editedAvailability.emit(selectedStatus.status);
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          this._toastrService.error(error?.error?.message);
        },
      });
  }
}
