import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DefaultGlobalConfig, ToastrService } from 'ngx-toastr';
import { SuccessResponse } from '../../interfaces/Admin/SuccessResponse';
import { OTPResponse } from '../../interfaces/Auth/OTPResponse';
import { InternalNavigationState } from '../../interfaces/NavigationState';
import { AuthService, LoginService } from '../../services';
import { HubConnectionService } from '../../services/hub-connection.service';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrl: './otp-verification.component.scss',
})
export class OtpVerificationComponent implements OnInit, AfterViewInit {
  isAdmin: boolean;
  isLoading = false;

  currentUserEmailId: string;
  currentUserId: number;
  isNewUser: boolean;
  isNewPwdSet: boolean;
  isUserLoggingIn: boolean;

  isNewUserPwdSet: boolean | null;
  currentUserFullName: string;
  currentUserRole: string;
  currentUserRoleId: number;
  currentUserRoleTypeId: number;
  currentUserAccessToken: string;

  otpForm: UntypedFormGroup;
  formInput = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6'];
  otpCharacterLimit = 6;
  inputTextBoxLimit = 1;
  errorMessage = '';

  timeLeft = 60;
  isNewOPTAlreadySent = true;
  interval: NodeJS.Timeout;

  isVerifyButtonClicked = false;
  OTPverificationFailed = false;

  faForm: UntypedFormGroup;

  @ViewChildren('formRow') rows: QueryList<ElementRef>;
  @ViewChild('faverification') faverification: TemplateRef<ElementRef>;

  constructor(
    private router: Router,
    private _loginService: LoginService,
    private _toastrService: ToastrService,
    private _authService: AuthService,
    private _hubConnectionService: HubConnectionService,
    private _modalService: NgbModal,
  ) {
    this.faForm = new UntypedFormGroup({
      faValue: new UntypedFormControl(),
    });

    this.otpForm = this.toFormGroup(this.formInput);
    const navigation = this.router.getCurrentNavigation();
    if (navigation) {
      const state = navigation.extras.state as {
        isUserLoggingIn: boolean;
        userId: number;
        isNewUser: boolean;
        emailId: string;
        isAdmin: boolean;
        isNewPwdSet?: boolean;
      };

      if (state) {
        this.isAdmin = state.isAdmin;
        this.isUserLoggingIn = state.isUserLoggingIn ?? false;
        this.currentUserId = state.userId ?? 0;
        this.isNewUser = state.isNewUser ?? false;
        this.currentUserEmailId = state.emailId ?? '';
        this.isNewPwdSet = state.isNewPwdSet ?? false; // for admin
      } else {
        this.navigateToLogin();
      }
    } else {
      this.navigateToLogin();
    }
  }

  navigateToLogin() {
    this.router.navigate(this.isAdmin ? ['login'] : ['client', 'login'], {
      replaceUrl: true,
    });
  }

  ngOnInit() {
    this.startTimer();
  }

  ngAfterViewInit() {
    document.getElementById('otp')?.focus();
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
        this.isNewOPTAlreadySent = false;
      }
    }, 1000);
  }

  toFormGroup(elements: string[]) {
    const group: { [key: string]: UntypedFormControl } = {};

    elements.forEach((key) => {
      group[key] = new UntypedFormControl('', Validators.required);
    });
    return new UntypedFormGroup(group);
  }

  keyUpEvent(event: KeyboardEvent, index: number) {
    let pos = index;
    const keyCode = event.code;
    const excludedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];
    if (!((event.key >= '0' && event.key <= '9') || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    } else {
      pos = keyCode === 'Backspace' || keyCode === 'ArrowLeft' ? index - 1 : index + 1;
      const elements = this.rows.toArray();
      pos > -1 && pos < this.formInput.length && elements[pos].nativeElement.focus();
    }
  }

  onInputChanged($event: Event, formIndex: string, fieldIndex: number) {
    const targetValue = ($event?.target as HTMLInputElement)?.value
      ?.trim()
      ?.replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1');
    const otpStrings: string[] = targetValue?.split('') || [];
    if (formIndex === this.formInput[0] && otpStrings.length === this.otpCharacterLimit) {
      // checking if the input field is the first to paste the OTP and the OTP contains default character limit
      otpStrings.forEach((value, index) => {
        this.otpForm.controls[this.formInput[index]]?.setValue(value);
      });
    } else {
      const otpValue = otpStrings.join('');
      // If the input is not on the first position then we need to divide the characters that entered and apply the character limit on that
      // so that the input doesn't contain more than character limit characters
      const limitDigitsRegex = new RegExp(`^[0-9]{${this.inputTextBoxLimit}}`, 'g');
      const match = limitDigitsRegex.exec(otpValue);
      this.otpForm.controls[this.formInput[fieldIndex]]?.setValue(match ? match[0] : otpValue);
    }
  }

  getVerificationCode() {
    let verificationCode = '';
    this.formInput.forEach((form) => {
      verificationCode += this.otpForm.value[form];
    });
    return verificationCode;
  }

  openFaVerificationModal() {
    this._modalService.open(this.faverification, {
      windowClass: 'd-block modal FA-modal fade show',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
  }

  handleOTPVerificationSuccessForClient(res: OTPResponse) {
    const userDetails = res.result;
    this.isNewUserPwdSet = userDetails.isNewPwdSet;
    this.currentUserFullName = userDetails.fullName;
    this.currentUserRole = userDetails.roleName;
    this.currentUserRoleId = userDetails.roleId;
    this.currentUserRoleTypeId = userDetails.roleTypeId;
    this.currentUserAccessToken = userDetails.accessToken;
    localStorage.setItem('accessToken', userDetails.accessToken);
    localStorage.setItem('isRM', 'true');
    localStorage.setItem('isClient', 'true');
    if (userDetails.twoFactorAuthentication !== null) {
      this.faForm.controls.faValue.setValue(userDetails.twoFactorAuthentication.toString());
      if (userDetails.twoFactorAuthentication == 3) {
        this.openFaVerificationModal();
      } else if ((userDetails.twoFactorAuthentication == 1 && !userDetails.isNewPwdSet) || !this.isUserLoggingIn) {
        localStorage.clear();
        localStorage.setItem('isClient', 'true');
        localStorage.setItem('isRM', 'true');
        this.navigateToResetPasswordForClient(userDetails.twoFactorAuthentication === 1 && !userDetails.isNewPwdSet);
      } else {
        this.setStateAndNavigateToList(true);
      }
    } else {
      this.faForm.controls.faValue.setValue('3');
      this.openFaVerificationModal();
    }
    res.result.accessToken && this._hubConnectionService.setupHubConnection();
    this.isVerifyButtonClicked = false;
  }

  setStateAndNavigateToList(skipLocationChange = false) {
    const state = {
      accessToken: this.currentUserAccessToken,
      emailId: this.currentUserEmailId,
      userId: this.currentUserId,
      fullName: this.currentUserFullName,
      role: this.currentUserRole,
      roleId: this.currentUserRoleId,
      roleTypeId: this.currentUserRoleTypeId,
    };
    this._authService.set('accessToken', this.currentUserAccessToken, state);
    this.router.navigate(['client', 'list'], {
      state: state,
      skipLocationChange,
    });
  }

  handleOTPVerificationSuccessForAdmin(result: OTPResponse) {
    const state: InternalNavigationState = {
      isUserLoggingIn: this.isUserLoggingIn,
      isNewUser: this.isNewUser,
      isNewPwdSet: this.isNewPwdSet,
      userId: this.currentUserId,
    };
    const userDetails = result.result;
    userDetails.accessToken && this._hubConnectionService.setupHubConnection();
    if (!this.isUserLoggingIn) {
      this.navigateToResetPassword(state, userDetails.accessToken);
    } else {
      state.roleTypeName = userDetails.roleTypeName;
      state.memberTypeName = userDetails.memberTypeName;
      state.roleId = userDetails.roleId;
      state.userName = userDetails.userName;
      state.roleName = userDetails.roleName;
      this._authService.set('accessToken', userDetails.accessToken, state);
      this._authService.set('isStatusSet', 'false');
      if (!this.isNewUser) {
        this._authService.set('loggedInAt', new Date().toISOString());
        this.router.navigate(['admin'], { replaceUrl: true });
      } else if (this.isNewPwdSet) {
        this.router.navigate(['profile'], {
          state: state,
          replaceUrl: true,
          skipLocationChange: true,
        });
      } else {
        this.navigateToResetPassword(state, userDetails.accessToken, true);
      }
    }
    this.isVerifyButtonClicked = false;
  }

  navigateToResetPasswordForClient(skipLocationChange: boolean) {
    this.router.navigate(['client', 'reset'], {
      state: {
        accessToken: this.currentUserAccessToken,
        userId: this.currentUserId,
      },
      skipLocationChange,
    });
  }

  navigateToResetPassword(state: InternalNavigationState, accessToken: string, skipLocationChange = false) {
    this._authService.remove('isRM');
    this._authService.set('accessToken', accessToken);
    this.router.navigate(['resetpassword'], {
      state: state,
      replaceUrl: true,
      skipLocationChange: skipLocationChange,
    });
  }

  onSubmit() {
    this.isVerifyButtonClicked = true;
    if (this.otpForm.valid && this.currentUserId) {
      this._loginService.otpVerification(parseInt(this.getVerificationCode()), this.currentUserId).subscribe({
        next: (res: OTPResponse) => {
          !this.isAdmin
            ? this.handleOTPVerificationSuccessForClient(res)
            : this.handleOTPVerificationSuccessForAdmin(res);
        },
        error: (error: HttpErrorResponse) => {
          this.isVerifyButtonClicked = false;
          this.OTPverificationFailed = true;
          this.errorMessage = error.error.message;
          this.removeErrorMessage();
        },
      });
    } else {
      this.isVerifyButtonClicked = false;
      this.OTPverificationFailed = true;
      this.errorMessage = this.getVerificationCode().length
        ? 'Incorrect verification code'
        : 'A verification code cannot be blank';
      this.removeErrorMessage();
    }
  }

  removeErrorMessage() {
    setTimeout(() => {
      this.errorMessage = '';
      this.OTPverificationFailed = false;
    }, 5000);
  }

  resendCode() {
    if (!this.isNewOPTAlreadySent) {
      if (!this.isAdmin) {
        localStorage.setItem('isClient', 'true');
        localStorage.setItem('isRM', 'true');
      }
      this._loginService.resendOtp(this.currentUserEmailId).subscribe({
        next: (res: SuccessResponse) => {
          !this.isAdmin && localStorage.clear();
          this._toastrService.success(res.message);
          this.isNewOPTAlreadySent = true;
          this.timeLeft = 60;
          this.startTimer();
        },
        error: (error: HttpErrorResponse) => {
          const globalConfig = DefaultGlobalConfig;
          this._toastrService.toastrConfig = { ...globalConfig, preventDuplicates: true };
          this._toastrService.error(error.error.message);
        },
      });
    }
  }

  confirmFA() {
    this.isLoading = true;
    this._loginService
      .twoFactorAuthentication(this.currentUserId, parseInt(this.faForm.controls.faValue.value))
      .subscribe({
        next: () => {
          this._modalService.dismissAll();
          if (
            (this.isNewUserPwdSet !== null &&
              this.isNewUserPwdSet === false &&
              this.faForm.controls.faValue.value === '1') ||
            (!this.isNewUserPwdSet && this.faForm.controls.faValue.value === '1')
          )
            this.navigateToResetPasswordForClient(true);
          else this.setStateAndNavigateToList();
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }
}
