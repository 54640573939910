<div class="auth-wrap">
  <div class="auth-wrap-left">
    <div class="logo">
      <img src="../../assets/images/HarperJames-Logo.svg" alt="" />
    </div>
    <div class="auth-wrap-left-content">
      <h1>Let’s get going!</h1>
      <img src="../../assets/images/login-set.png" alt="" />
    </div>
  </div>
  <div class="auth-wrap-right">
    <div class="auth-wrap-right-content">
      <h1 class="margin-0">Forgot password?</h1>
      <h3>Enter your details to receive a reset link</h3>
      <div class="auth-form forgot-password-form">
        <form [formGroup]="forgotPasswordForm">
          <div class="form-field">
            <label for="email">Email</label>
            <input
              id="email"
              matInput
              type="text"
              formControlName="emailControl"
              placeholder="Enter Email"
              name="email"
              #email
              (keydown.space)="$event.preventDefault()"
              (ngModelChange)="emailFieldUpdate.next($event)"
            />
            <mat-error
              *ngIf="
                forgotPasswordForm.controls.emailControl.hasError('required') &&
                forgotPasswordForm.controls.emailControl.touched
              "
            >
              Email cannot be blank
            </mat-error>
            <mat-error
              *ngIf="
                forgotPasswordForm.controls.emailControl.hasError('pattern') &&
                forgotPasswordForm.controls.emailControl.dirty &&
                forgotPasswordForm.controls.emailControl.touched
              "
            >
              Invalid email address
            </mat-error>
            <svg-icon name="mail-icon" style="color: #8f9bba" class="input-icon"></svg-icon>
          </div>
          <div class="client-forgot-password-error" *ngIf="isClient && !is2FAEnabledForClient">
            {{ msgIf2FANotEnabled }}
          </div>
          <div class="client-forgot-password-error" *ngIf="isClient && !isClientExist">
            {{ clientDoesNotExistErrorMsg }}
          </div>
          <button
            type="submit"
            (click)="forgotPassword()"
            [disabled]="
              !canProceed || isForgotPasswordClicked || (isClient && !(is2FAEnabledForClient && isClientExist))
            "
            class="btn btn-lg"
          >
            Submit
          </button>
        </form>
        <div class="back-link">
          <a [routerLink]="isClient ? ['/client/login'] : ['/login']"
            ><svg-icon name="back-arrow" class="icon"></svg-icon> <span>Back to Sign in</span></a
          >
        </div>
      </div>
    </div>
  </div>
</div>
