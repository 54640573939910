import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { ClientListForMatterFilter as ClientListEditMatterBudget } from 'src/app/shared/interfaces/Admin/Matter/ClientListForMatterFilterResponse';
import { MatterList as MatterListEditMatterBudget } from 'src/app/shared/interfaces/Admin/Matter/MatterListForClientsResponse';
import { Config } from '../../constants/constants';
import { BudgetDetails } from '../../interfaces/Admin/Budget/BudgetDetailsResponse';
import { EditBudgetRequest } from '../../interfaces/Admin/Budget/EditBudgetRequest';
import { MatterClientByTimeEntryTypeRequest } from '../../interfaces/Admin/ManualRequest/MatterClientByTimeEntryTypeResquest';
import { MatterByClientIdRequest } from '../../interfaces/Admin/Matter/MatterByClientIdRequest';
import { SuccessResponse } from '../../interfaces/Admin/SuccessResponse';
import { LocalPermissions } from '../../interfaces/LocalPermissions';
import { BudgetService } from '../../services/budget.service';
import { TimesheetService } from '../../services/timesheet.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { InsertBudgetNotesComponent } from '../insert-budget-notes/insert-budget-notes.component';
@Component({
  selector: 'app-edit-matter-budget-popup',
  templateUrl: './edit-matter-budget-popup.component.html',
  styleUrls: ['./edit-matter-budget-popup.component.scss'],
})
export class EditMatterBudgetPopupComponent implements OnInit {
  @Input() matterIDForEditMatterBudgetDirectly: number = -1; // If query params include matter id then it will open edit matter budget popup directly for Matter budget threshold functionality otherwise it will work normally
  @Input() currentUserId: number = 0;
  @Input() isAdmin: boolean = false;
  @Input() editMatterPermissionForAdmin: LocalPermissions;

  editBudgetForm: UntypedFormGroup;
  clientListEditMatterBudget: ClientListEditMatterBudget[] = [];
  matterListEditMatterBudget: MatterListEditMatterBudget[] = [];
  selectedClientMatterBudget: number | null;
  selectedMatterMatterBudget: number | null;
  isMatterActive: boolean = false;
  servicePlanNameOfMatter: string = '';
  currentBudget: BudgetDetails;
  isLoading: boolean = false;
  isBudgetUpdating: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private _timeSheetService: TimesheetService,
    private _toastrService: ToastrService,
    private _budgetService: BudgetService,
    private modalService: NgbModal,
  ) {
    this.initEditMatterBudgetForm();
    this.currentBudget = this.initCurrentMatterBudget();
  }

  ngOnInit(): void {
    this.manageLoader();
    this.getMattersClientsListByUserID(true, true);
  }

  manageLoader(isLoading: boolean = true, showLoader: boolean = true) {
    if (!showLoader) {
      return;
    }
    this.isLoading = isLoading;
  }

  initCurrentMatterBudget() {
    return {
      matterId: 0,
      solicitorHours: 0,
      legalAssistantHours: 0,
      solicitorHourlyRate: 0,
      legalAssistantHourlyRate: 0,
      budgetInHours: 0,
      totalBudgetAmount: 0,
      isActive: false,
      servicePlanName: '',
    };
  }

  initEditMatterBudgetForm() {
    this.editBudgetForm = new UntypedFormGroup({
      solicitorHours: new UntypedFormControl(),
      legalAssistantHours: new UntypedFormControl(),
      solicitorHourlyRate: new UntypedFormControl({ value: 0, disabled: true }),
      legalAssistantHourlyRate: new UntypedFormControl({ value: 0, disabled: true }),
      totalBudgetAmount: new UntypedFormControl({ value: 0, disabled: true }),
      budgetInHours: new UntypedFormControl({ value: 0, disabled: true }),
      changeInAmount: new UntypedFormControl({ value: 0, disabled: true }),
    });
  }

  getFormControl(fieldID: string) {
    return this.editBudgetForm.get(fieldID);
  }

  clientSelected() {
    this.manageLoader();
    this.resetEditBudgetForm();
    this.isMatterActive = false;
    this.servicePlanNameOfMatter = '';
    // HJS-703
    this.selectedMatterMatterBudget = null;
    if (this.selectedClientMatterBudget) {
      const req: MatterByClientIdRequest = {
        clientId: this.selectedClientMatterBudget,
        timeEntryTypeId: 0,
        userId: this.currentUserId,
        includeActiveOnly: false,
      };
      this._timeSheetService.getMattersByClientId(req).subscribe({
        next: (res) => {
          this.matterListEditMatterBudget = res.result;
          this.getServicePlanNameBasedOnSelectedMatter(this.matterListEditMatterBudget);
          this.manageLoader(false);
        },
        error: () => {
          this.manageLoader(false);
          this.getMattersClientsListByUserID(false);
        },
      });
    } else {
      this.manageLoader(false);
      this.getMattersClientsListByUserID(false);
    }
  }

  matterSelected($event?: any) {
    this.manageLoader();
    if ($event) {
      this.isMatterActive = $event.isActive;
      this.servicePlanNameOfMatter = $event.servicePlanName || '';
    }
    if (this.selectedMatterMatterBudget) {
      this._budgetService.getBudgetDetails(this.selectedMatterMatterBudget).subscribe({
        next: (res) => {
          this.currentBudget = res.result;
          this.isMatterActive = this.currentBudget.isActive;
          this.editBudgetForm.patchValue({
            solicitorHourlyRate: this.currentBudget.solicitorHourlyRate ?? 0,
            legalAssistantHourlyRate: this.currentBudget.legalAssistantHourlyRate ?? 0,
            solicitorHours: this.currentBudget.solicitorHours ?? 0,
            legalAssistantHours: this.currentBudget.legalAssistantHours ?? 0,
            totalBudgetAmount: this.currentBudget.totalBudgetAmount ?? 0,
            budgetInHours: this.currentBudget.budgetInHours ?? 0,
            changeInAmount: 0,
          });
          this.enableDisableNewMatterBudget(this.isMatterActive);
          this.manageLoader(false);
        },
      });

      const req: number | null = this.selectedMatterMatterBudget;
      this._timeSheetService.getClientByMatterId(req).subscribe((res) => {
        this.selectedClientMatterBudget !== res.result.id && (this.selectedClientMatterBudget = res.result.id);
      });
    } else {
      this.manageLoader(false);
      this.resetEditBudgetForm();
      this.isMatterActive = false;
      this.servicePlanNameOfMatter = '';
      !this.selectedClientMatterBudget && this.getMattersClientsListByUserID(false);
    }
  }

  enableNewMatterBudget() {
    this.getFormControl('solicitorHours')?.enable();
    this.getFormControl('legalAssistantHours')?.enable();
  }

  disableNewMatterBudget() {
    this.getFormControl('solicitorHours')?.disable();
    this.getFormControl('legalAssistantHours')?.disable();
  }

  enableDisableNewMatterBudget(isEnabled: boolean = true) {
    if (isEnabled) {
      this.enableNewMatterBudget();
    } else {
      this.disableNewMatterBudget();
    }
  }

  onMatterStatusChanged($event: MatSlideToggleChange) {
    const modal = this.modalService.open(ConfirmationModalComponent, {
      windowClass: 'cancel-changes-modal edit-timesheet-delete update-matter-status-confirmation',
      centered: true,
    });
    modal.componentInstance.headerTitle = '';
    modal.componentInstance.subjectLine = `Are you sure want to ${$event.checked ? 'Active' : 'Inactive'} this matter?`;
    modal.componentInstance.confirmed?.subscribe((result: boolean) => {
      if (result) {
        this.updateMatterStatus($event.checked);
      } else {
        this.isMatterActive = !$event.checked;
        $event.source.checked = this.isMatterActive;
      }
      this.modalService.dismissAll();
    });
  }

  updateMatterStatus(isMatterActive: boolean) {
    this.manageLoader();
    if (this.selectedMatterMatterBudget) {
      this._budgetService
        .updateMatterStatus({ matterId: this.selectedMatterMatterBudget, isActive: isMatterActive })
        .subscribe({
          next: (response: SuccessResponse) => {
            this.isMatterActive = isMatterActive;
            this.manageLoader(false);
            this._toastrService.success(response.message);
            this.enableDisableNewMatterBudget(this.isMatterActive);
          },
          error: (error: HttpErrorResponse) => {
            this.manageLoader(false);
            this._toastrService.success(error?.error?.message);
            this.enableDisableNewMatterBudget(this.isMatterActive);
          },
        });
    }
  }

  onAddNotes() {
    const insertNotesModalRef = this.modalService.open(InsertBudgetNotesComponent, {
      windowClass: 'insert-budget-notes-modal manual-request-modal',
      centered: true,
    });
    insertNotesModalRef.componentInstance.matterID = this.selectedMatterMatterBudget;
    insertNotesModalRef.componentInstance.clientID = this.selectedClientMatterBudget;
    insertNotesModalRef.componentInstance.clientListDatasource = [...this.clientListEditMatterBudget];
    insertNotesModalRef.componentInstance.matterListDataSource = [...this.matterListEditMatterBudget];
    insertNotesModalRef.componentInstance.sendNotes?.subscribe(
      (result: { clientID: number; matterID: number; notes: string }) => {
        if (result?.notes.trim()) {
          this.manageLoader();
          this.insertBudgetNotes(result);
          insertNotesModalRef?.dismiss();
        }
      },
    );
  }

  insertBudgetNotes(notesData: { clientID: number; matterID: number; notes: string }) {
    this._budgetService
      .sendBudgetNotes({ clientId: notesData.clientID, matterId: notesData.matterID, notes: notesData.notes })
      .subscribe({
        next: (result) => {
          this._toastrService.success(result.message);
          this.manageLoader(false);
        },
        error: (error: HttpErrorResponse) => {
          this._toastrService.error(error.error.message);
          this.manageLoader(false);
        },
      });
  }

  // HJS-703
  getMattersClientsListByUserID(updateClientList: boolean = true, updateMatterList: boolean = true) {
    const req: MatterClientByTimeEntryTypeRequest = {
      userId: this.isAdmin ? 0 : this.currentUserId,
      timeEntryTypeId: 0,
      includeActiveOnly: false,
    };
    this.getMattersClientsListForEditBudget(req, updateClientList, updateMatterList);
  }

  // HJS-703
  getMattersClientsListForEditBudget(
    request: MatterClientByTimeEntryTypeRequest,
    updateClientList: boolean,
    updateMatterList: boolean,
  ) {
    this._timeSheetService.getMattersClientsListByTimeEntryTypeAndLegalUser(request).subscribe({
      next: (res) => {
        updateClientList && (this.clientListEditMatterBudget = res.result.clientList);
        if (updateMatterList) {
          this.matterListEditMatterBudget = res.result.matterList;
          if (this.matterIDForEditMatterBudgetDirectly !== -1) {
            if (
              this.matterListEditMatterBudget.findIndex(
                (matter) => matter.id === this.matterIDForEditMatterBudgetDirectly,
              ) > -1
            ) {
              this.selectedMatterMatterBudget = this.matterIDForEditMatterBudgetDirectly;
              this.getServicePlanNameBasedOnSelectedMatter(this.matterListEditMatterBudget);
              this.matterSelected();
            }
          }
        }
        this.manageLoader(false);
      },
    });
  }

  getServicePlanNameBasedOnSelectedMatter(matterList: MatterListEditMatterBudget[]) {
    if (
      this.selectedMatterMatterBudget &&
      matterList.findIndex((matter) => matter.id === this.selectedMatterMatterBudget) > -1
    ) {
      this.servicePlanNameOfMatter =
        matterList.find((matter) => matter.id === this.selectedMatterMatterBudget)?.servicePlanName ?? '';
    }
  }

  hoursChanged() {
    const solicitorHours = +this.getFormControl('solicitorHours')?.value;
    const legalAssistantHour = +this.getFormControl('legalAssistantHours')?.value;
    const solicitorHourlyRate = +this.getFormControl('solicitorHourlyRate')?.value;
    const legalAssistantHourlyRate = +this.getFormControl('legalAssistantHourlyRate')?.value;
    try {
      this.getFormControl('totalBudgetAmount')?.setValue(
        this.calculateTotalBudgetAmount(
          solicitorHours,
          solicitorHourlyRate,
          legalAssistantHour,
          legalAssistantHourlyRate,
        ),
      );
      this.getFormControl('budgetInHours')?.setValue(this.calculateBudgetInHour(solicitorHours, legalAssistantHour));
      if (this.currentBudget.totalBudgetAmount != null) {
        this.getFormControl('changeInAmount')?.setValue(
          this.getFormControl('totalBudgetAmount')?.value - this.currentBudget.totalBudgetAmount,
        );
      }
    } catch (error) {
      throwError(() => error);
    }
  }

  calculateTotalBudgetAmount(
    solicitorHours: number,
    solicitorHourlyRate: number,
    legalAssistantHour: number,
    legalAssistantHourlyRate: number,
  ) {
    return solicitorHours * solicitorHourlyRate + legalAssistantHour * legalAssistantHourlyRate;
  }

  calculateBudgetInHour(solicitorHours: number, legalAssistantHour: number) {
    const parseAndFixValue = (value: number) => {
      return parseFloat(value?.toFixed(2));
    };
    return parseAndFixValue(parseAndFixValue(solicitorHours) + parseAndFixValue(legalAssistantHour / 2));
  }

  onSolicitorHoursFieldInputChanged(fieldName: string) {
    const value = this.getFormControl(fieldName)
      ?.value?.replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1');
    const limitDigitsRegex = new RegExp(`${Config.restrictDecimalPlacesBy2Digits}`, 'g');
    this.getFormControl(fieldName)?.setValue(
      value.match(limitDigitsRegex)?.[0] ? value.match(limitDigitsRegex)?.[0] : value,
    );
  }

  validate(controlName: string) {
    this.getFormControl(controlName)?.updateValueAndValidity();
  }

  resetEditBudgetForm() {
    this.editBudgetForm.reset();
    this.currentBudget = this.initCurrentMatterBudget();
  }

  resetBudgetAfterPopupClose() {
    this.resetEditBudgetForm();
    this.selectedClientMatterBudget = null;
    this.selectedMatterMatterBudget = null;
    this.isMatterActive = false;
    this.servicePlanNameOfMatter = '';
  }

  editBudget() {
    this.isBudgetUpdating = true;
    let req: EditBudgetRequest;
    if (this.selectedMatterMatterBudget) {
      const matterBudgetData = this.editBudgetForm.getRawValue();
      req = {
        matterId: this.selectedMatterMatterBudget,
        solicitorHours: parseFloat(matterBudgetData['solicitorHours']),
        solicitorHourlyRate: parseFloat(matterBudgetData['solicitorHourlyRate']),
        legalAssistantHours: parseFloat(matterBudgetData['legalAssistantHours']),
        legalAssistantHourlyRate: parseFloat(matterBudgetData['legalAssistantHourlyRate']),
        totalBudgetAmount: parseFloat(matterBudgetData['totalBudgetAmount']),
        budgetInHours: this.calculateBudgetInHour(
          parseFloat(matterBudgetData['solicitorHours']),
          parseFloat(matterBudgetData['legalAssistantHours']),
        ),
        currentBudgetAmount: this.currentBudget.totalBudgetAmount ?? 0,
        changeInBudget: parseFloat(matterBudgetData['changeInAmount'] ?? 0),
        isActive: this.isMatterActive,
      };
      this._budgetService.editBudget(req).subscribe({
        next: (res) => {
          this.isBudgetUpdating = false;
          this._toastrService.success(res.message);
          this.resetBudgetAfterPopupClose();
          this.activeModal.dismiss();
        },
        error: (error: HttpErrorResponse) => {
          this.isBudgetUpdating = false;
          this._toastrService.error(error.error.message);
        },
      });
    }
  }
}
