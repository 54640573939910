import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIUrls } from '../enums/apiUrls';
import { BudgetDetailsResponse } from '../interfaces/Admin/Budget/BudgetDetailsResponse';
import {
  EditBudgetRequest,
  SendBudgetNotesRequest,
  UpdateMatterStatusRequest,
} from '../interfaces/Admin/Budget/EditBudgetRequest';
import { SuccessResponse } from '../interfaces/Admin/SuccessResponse';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class BudgetService {
  constructor(private _apiService: ApiService) {}

  getBudgetDetails(id: number): Observable<BudgetDetailsResponse> {
    return this._apiService.get(`${APIUrls.GetBudgetDetails}/${id}`);
  }

  editBudget(req: EditBudgetRequest): Observable<SuccessResponse> {
    return this._apiService.post(APIUrls.Budget, { ...req });
  }

  sendBudgetNotes(req: SendBudgetNotesRequest): Observable<SuccessResponse> {
    return this._apiService.post(APIUrls.SendNotes, { ...req });
  }

  updateMatterStatus(req: UpdateMatterStatusRequest): Observable<SuccessResponse> {
    return this._apiService.post(APIUrls.MatterUpdateStatus, { ...req });
  }
}
