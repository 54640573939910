<mat-sidenav-container>
  <ng-container *ngFor="let menu of menuItems">
    <mat-sidenav-content *ngIf="menu?.roles?.includes(role) || !menu.roles">
      <mat-nav-list
        *ngIf="!menu?.children?.length; else menuWithChildren"
        class="asm-menu-anchor"
        routerLinkActive="active-menu-parent"
      >
        <a
          mat-list-item
          [routerLink]="[menu.route]"
          routerLinkActive="active-list-item"
          matTooltipPosition="right"
          matTooltip="{{ !expectedToggleState ? '' : menu.label }}"
        >
          <i *ngIf="menu.iconClasses" class="asm-menu-anchor__icon" [class]="menu.iconClasses"></i>
          <span class="asm-menu-anchor__label" *ngIf="!expectedToggleState">{{ menu.label }}</span>
        </a>
      </mat-nav-list>
      <ng-template #menuWithChildren>
        <mat-expansion-panel
          [class.mat-elevation-z0]="true"
          #linkActive="routerLinkActive"
          routerLinkActive="active-menu-parent"
          [expanded]="linkActive.isActive"
          *ngIf="menu?.roles?.includes(role) && menu?.children"
          [hideToggle]="expectedToggleState"
        >
          <mat-expansion-panel-header
            *ngIf="menu?.label"
            matTooltipPosition="right"
            matTooltip="{{ !expectedToggleState ? '' : menu.label }}"
          >
            <i *ngIf="menu.iconClasses" class="asm-menu-anchor__icon" [class]="menu.iconClasses"></i>
            <span class="asm-menu-anchor__label" *ngIf="!expectedToggleState">{{ menu?.label }}</span>
          </mat-expansion-panel-header>
          <mat-nav-list class="children">
            <div *ngFor="let child of menu.children" class="asm-menu-anchor">
              <a
                mat-list-item
                [routerLink]="[child.route]"
                *ngIf="child?.roles?.includes(role)"
                routerLinkActive="active-list-item"
                class="asm-menu-anchor__child"
                matTooltipPosition="right"
                matTooltip="{{ !expectedToggleState ? '' : child.label }}"
              >
                <i
                  *ngIf="child.iconClasses && expectedToggleState"
                  class="asm-menu-anchor__icon"
                  [class]="child.iconClasses"
                ></i>
                <span class="asm-menu-anchor__label" *ngIf="!expectedToggleState">{{ child.label }}</span>
              </a>
            </div>
          </mat-nav-list>
        </mat-expansion-panel>
      </ng-template>
    </mat-sidenav-content>
  </ng-container>
</mat-sidenav-container>
