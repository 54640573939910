import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TimeRoundingUtilsService {

    public static roundTimeForBilling(time: string) {

        if (time.indexOf(':') >= 0)
        {
            // time has been input in hh:mm format
            const split = time.split(':');
            const hours = parseFloat(split[0]);
            const minutes = parseFloat(split[1]);

            if (hours == 0 && minutes == 0)
            {
                return '0:06';
            }

            let roundedMinutes = Math.ceil(minutes / 6) * 6;
            let roundedHours = hours;

            if (roundedMinutes == 60)
            {
                roundedMinutes = 0;
                roundedHours++;            
            }

            return roundedHours.toString().padStart(2, '0') + ':' + roundedMinutes.toString().padStart(2, '0');

        }
        else
        {

            // time has been input in decimal format
            const decimalTime = parseFloat(time);

            if (decimalTime == 0)
            {
                return 0.1;
            }

            let roundedTime = Math.ceil(decimalTime * 60 / 6.0) * 6.0 / 60;
            
            return roundedTime.toFixed(2).toString();

        }

    }

    public static isTimeRounded(time: string) {

        if (time.indexOf('.') >= 0)
        {
            const split = time.split('.');
            if (split[1].length == 1 || split[1].endsWith("0"))
            {
                return true;
            } 
            return false;
        }
        else
        {
            const roundedTime = this.roundTimeForBilling(time);
            return time == roundedTime;
        }

    }

}