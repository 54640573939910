<div>
    <div class="modal-header" [ngClass]="{'manual-header': headerTitle}">
        <h4 class="modal-title" id="myModalLabel">{{headerTitle}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <svg-icon name="modal-close-icon" class="icon"></svg-icon>
        </button>
    </div>
    <div class="modal-body">
        <div ngbAccordion #accordion="ngbAccordion" [closeOthers]="true">
            <div [ngbAccordionItem]="businessReportsHelpSections.GenerateEmbedLinkSection"
                [id]="businessReportsHelpSections.GenerateEmbedLinkSection" class="generate-embed-link"
                [collapsed]="false">
                <div ngbAccordionHeader>
                    <button ngbAccordionButton>
                        <span class="arrow-icon">
                            <svg-icon name="downarrow-black" class="icon"></svg-icon>
                        </span>
                        <h4>How to generate Embed Link from Excel?</h4>
                    </button>
                </div>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <div class="special-notes">
                            <p><span class="notes">Note - </span>The embed link can only be generated by opening an
                                excel
                                sheet in the web application of Microsoft Excel.</p>
                        </div>
                        <div class="sub-text">
                            Please find the below Steps to generate an Embed Link for Business Reporting.
                        </div>
                        <div class="steps-content">
                            <ol type="ordered" class="ol-for-embed-link-section">
                                <li>
                                    <p><span class="steps">Step 1: </span>Open <strong>Excel File</strong></p>
                                </li>
                                <li>
                                    <p><span class="steps">Step 2: </span>Go to the <strong>File</strong> menu</p>
                                </li>
                                <li>
                                    <p><span class="steps">Step 3: </span>Click on the <strong>Share</strong> option</p>
                                </li>
                                <li>
                                    <p><span class="steps">Step 4: </span>Click on <strong>Embed</strong></p>
                                </li>
                                <li>
                                    <p><span class="steps">Step 5: </span>Set values in What to show, Appearance and
                                        Interaction</p>
                                </li>
                                <li>
                                    <p><span class="steps">Step 6: </span>Go to <strong>Embed code</strong> and copy the
                                        text inside quotation marks at <strong>src=""</strong> <span class="steps">
                                            OR</span> Copy the
                                        <strong>whole Embed code</strong> <br />
                                    </p>
                                    <div class="sub-text special-notes">
                                        <p><span class="notes">Note - </span>If the Embed code is copied and pasted
                                            partially or incompletely it will not open the Excel sheet in the HJS
                                            system.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <p><span class="steps">Step 7: </span>Paste the text in the text box on the Business
                                        Reporting page of the HJS Portal</p>
                                </li>
                                <li>
                                    <p><span class="steps">Step 8: </span>Click on the <strong>Load data</strong> button
                                        next to
                                        the text box</p>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div [ngbAccordionItem]="businessReportsHelpSections.ManageViewInExcelReportSync"
                [id]="businessReportsHelpSections.ManageViewInExcelReportSync" class="manage-view-in-excel">
                <div ngbAccordionHeader>
                    <button ngbAccordionButton>
                        <span class="arrow-icon">
                            <svg-icon name="downarrow-black" class="icon"></svg-icon>
                        </span>
                        <h4>How to sync the updated data to the HJS Business Reports?</h4>
                    </button>
                </div>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <div class="sub-text">
                            Please find the below Steps to sync the updated data to the HJS Business Reports using
                            <strong>View in Excel feature for Saved Reports</strong>.
                        </div>
                        <div class="steps-content">
                            <ol type="ordered" class="ol-for-view-in-excel-section">
                                <li>
                                    <p><span class="steps">Step 1: </span>Open the <strong>Saved Report</strong></p>
                                </li>
                                <li>
                                    <p><span class="steps">Step 2: </span>
                                        <span>
                                            Above the Report Details section, <strong>View in Excel</strong> would be
                                            available.
                                        </span>
                                        <span>
                                            Clicking <strong>View in Excel</strong> button, the report will be opened in
                                            the
                                            <strong>New Tab</strong> of the browser in an <strong>Excel Web
                                                Application</strong>.
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <div><span class="steps">Step 3: </span>
                                        There are two ways to open the Excel Sheet in the Desktop Application:
                                        <div class="list-section">
                                            <ol type="i">
                                                <li class="ordered-list-items">
                                                    <p>
                                                        Go to <strong>File</strong> Menu<br />
                                                        Click on <strong>Info</strong><br />
                                                        In <strong>Info</strong> section, click on <strong>Open in
                                                            Desktop
                                                            App</strong>
                                                    </p>
                                                </li>
                                                <li class="ordered-list-items">
                                                    <p>
                                                        Click on the <strong>Search for tools, help, and more</strong>
                                                        text
                                                        box<br />
                                                        Type <strong>Open in Desktop App</strong>, it will show an
                                                        option
                                                        for it<br />
                                                        Click on <strong>Open in Desktop App</strong> option
                                                    </p>
                                                </li>
                                            </ol>
                                        </div>
                                        <p>By clicking <strong>Open in Desktop App</strong>, the Excel Sheet will be
                                            opened
                                            in the Desktop Application</p>
                                    </div>
                                </li>
                                <li>
                                    <p><span class="steps">Step 4: </span>After the Excel Sheet opens in Desktop
                                        application, you can proceed further with the changes</p>
                                </li>
                                <li>
                                    <p><span class="steps">Step 5: </span>Once the changes are done, please
                                        <strong>Save</strong> the sheet from the Desktop Application
                                    </p>
                                </li>
                                <li>
                                    <p><span class="steps">Step 6: </span>After successfully updating the sheet, you can
                                        verify the changes in the sheet opened in the Excel Web Application</p>
                                </li>
                                <li>
                                    <p><span class="steps">Step 7: </span>In the HJS Portal, a <strong>Refresh
                                            Report</strong> button has been provided beside the <strong>View in
                                            Excel</strong> button.
                                        Click on it to <strong>Refresh</strong> the Report and to fetch the updated data
                                    </p>
                                </li>
                                <li>
                                    <p><span class="steps">Step 8: </span>The Saved Report will be synced to the updated
                                        data</p>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div [ngbAccordionItem]="businessReportsHelpSections.FAQs" [id]="businessReportsHelpSections.FAQs"
                class="faqs">
                <div ngbAccordionHeader>
                    <button ngbAccordionButton>
                        <span class="arrow-icon">
                            <svg-icon name="downarrow-black" class="icon"></svg-icon>
                        </span>
                        <h4>FAQs</h4>
                    </button>
                </div>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <div class="steps-content">
                            <ol type="ordered" class="ol-for-faqs">
                                <li>
                                    <div class="faq">
                                        <p class="question">Actions to take if following error is displayed -</p>
                                        <div class="answer permission-denied-error-qa">
                                            <div class="permission-denied-error-img zoom">
                                                <img alt="Permission Denied Error"
                                                    src="assets/images/business-report-permission-denied.png" />
                                            </div>
                                            <p class="permission-denied-error-answer">
                                                <span class="answer-marker"></span>
                                                If you see the above error, it means <strong>you don’t have
                                                    permission</strong> to access
                                                this report. You can click on <strong>“View in Excel“</strong> option
                                                <strong>to request access for the report</strong> from Business Area.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>