<ng-container>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <svg-icon name="modal-close-icon" class="icon"></svg-icon>
  </button>
  <div class="modal-body">
    <span class="success-icon-thumb">
      <img src="../../../../assets/icons/invoice-sent-icon.svg" [alt]="subjectLine" />
    </span>
    <h2>{{ subjectLine }}</h2>
    <p *ngIf="resentEmailId; else messageWithNoEmailId">
      {{ message }} <b>{{ resentEmailId }}</b>
    </p>
    <ng-template #messageWithNoEmailId>
      <p>
        <b>{{ message }}</b>
      </p>
    </ng-template>
    <div class="successfully-foot-btn max-w-341">
      <button class="btn btn-block btn-md" data-dismiss="modal" (click)="activeModal.dismiss()">Ok</button>
    </div>
  </div>
</ng-container>
