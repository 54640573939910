import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Config } from '../constants/constants';

export function timeSheetTimeValidator(
  isOnlyForHHMM = false,
  regexWithColon = Config.timeSheetTimeValidation.regexWithColonHHmmSS,
  regexWithDot = Config.timeSheetTimeValidation.regexWithDot,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const timeString = control.value?.trim() ?? '';
    if (!timeString.match(regexWithColon) && !timeString.match(regexWithDot)) {
      return { isTimeInvalid: true, isTimeExceeded: false };
    }

    const checkForValidHours = (hours: number) => hours >= 0 && hours < 24;

    const checkForValidMinutesOrSeconds = (numbers: number) => numbers >= 0 && numbers <= 59;

    const checkSplittedTime = (timeString: string[]) => {
      if (isOnlyForHHMM) {
        return (
          timeString?.[0] &&
          checkForValidHours(parseInt(timeString[0])) &&
          timeString[1] &&
          checkForValidMinutesOrSeconds(parseInt(timeString[1]))
        );
      }
      return (
        timeString?.[0] &&
        checkForValidHours(parseInt(timeString[0])) &&
        timeString[1] &&
        checkForValidMinutesOrSeconds(parseInt(timeString[1])) &&
        timeString[2] &&
        checkForValidMinutesOrSeconds(parseInt(timeString[2]))
      );
    };

    if (timeString.includes(':')) {
      const splittedTimeString: string[] = timeString.split(':');
      if (checkSplittedTime(splittedTimeString)) {
        return null;
      }
      return { isTimeInvalid: false, isTimeExceeded: true };
    } else if (timeString.includes('.')) {
      const splittedTimeString: string[] = timeString.split('.');
      if (
        splittedTimeString?.[0] &&
        checkForValidHours(parseInt(splittedTimeString[0])) &&
        splittedTimeString[1] &&
        parseInt(splittedTimeString[1]) >= 0 &&
        parseInt(splittedTimeString[1]) <= 99
      ) {
        return null;
      }
      return { isTimeInvalid: false, isTimeExceeded: true };
    }

    return null;
  };
}
