import { Injectable } from '@angular/core';
import { ClientMenuItemLabels } from './menu-item-labels.enum';

const MENUITEMS: any[] = [
  {
    label: ClientMenuItemLabels.Instructions,
    route: '/client/dashboard/instruction',
    iconClasses: 'menu-icon brifecase-timer',
  },
  {
    label: ClientMenuItemLabels.Matters,
    route: '/client/dashboard/matter',
    iconClasses: 'menu-icon matter-icon',
  },
  {
    label: ClientMenuItemLabels.Invoices,
    route: '/client/dashboard/invoice',
    iconClasses: 'menu-icon client-invoice-icon',
  },
  {
    label: ClientMenuItemLabels.Users,
    route: '/client/dashboard/users',
    iconClasses: 'menu-icon users-icon',
    roles: [],
  },
  {
    label: ClientMenuItemLabels.Logout,
    route: '/logout',
    iconClasses: 'menu-icon logout-icon',
  },
];

@Injectable()
export class ClientMenuItems {
  getMenuitem() {
    return MENUITEMS;
  }
}
