import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeLogService } from '../../services/time-log.service';
import { TimesheetService } from '../../services/timesheet.service';

@Component({
  selector: 'app-long-running-timer-modal',
  templateUrl: './long-running-timer-modal.component.html',
  styleUrls: ['./long-running-timer-modal.component.scss'],
})
export class LongRunningTimerModalComponent implements OnInit {
  @Input() clientName: string;
  @Input() matterName: string;
  @Input() description: string;
  @Input() isTimerActivelyRunning: boolean = false;

  headerTitle: string = 'Timer Alert!';
  currentTimeString: string = '';

  private destroyRef = inject(DestroyRef);

  constructor(
    public activeModal: NgbActiveModal,
    private _timeLogService: TimeLogService,
    private _timesheetService: TimesheetService,
  ) {}

  ngOnInit(): void {
    if (this.isTimerActivelyRunning) {
      this.subscribeCurrentTimer();
    }
  }

  subscribeCurrentTimer() {
    this._timeLogService.currentTimeofMatterObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((d) => {
      this.currentTimeString = this._timesheetService.formatTime(d, true, true);
    });
  }
}
