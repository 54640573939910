import { Pipe, PipeTransform } from '@angular/core';
import { WeeklyTimesheetList } from '../interfaces/Admin/Timesheet/WeeklyTimesheetListResponse';

@Pipe({
  name: 'isTimerAvailable',
})
export class IsTimerAvailablePipe implements PipeTransform {
  transform(
    value: WeeklyTimesheetList,
    args: { currentUserId: number; isAdminOrPAH: boolean; isPAH: boolean },
  ): boolean {
    if (value.userId === args.currentUserId && (args.isPAH || !args.isAdminOrPAH)) {
      return !(value.isUserRemovedFromMatter && value.timeEntryTypeId === 1);
    }
    return false;
  }
}
